import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import GeneralInformation from './generalInformation'
import Security from './security/Security'
import Notification from './notification/Notification'
import Invoices from './invoices/Index'
import Payments from './payment/Payments'
import { AbilityContext } from 'src/contexts/abilityContext'
import ProtectedRoutes from 'src/components/ProtectedRoutes'
import MobileBreadcrumbHelper from './MobileBreadcrumbHelper'
import { useContext } from 'react'

export const ROOT = '/configuration'
export const SECURITY = '/configuration/securite'
export const LEGALS = '/configuration/legal'
export const NOTIFICATIONS = '/configuration/notifications'
export const PAYMENT_INFORMATIONS = '/configuration/informations-de-paiement'
export const INVOICES = '/configuration/factures'
export const GENERAL_INFORMATIONS = '/configuration/informations-generales'

interface SettingsRoutesProps {
  onSetSidebarState: (open: boolean) => void
}

const SettingsRoutes: React.FC<SettingsRoutesProps> = ({ onSetSidebarState }) => {
  const ability: any = useContext(AbilityContext)

  return (
    <Routes>
      <Route element={<ProtectedRoutes authorized={ability.can('customer_read', 'PaymentSource')} />}>
        <Route
          path='informations-de-paiement'
          element={
            <MobileBreadcrumbHelper Component={Payments} subtitle='payments' onSetSidebarState={onSetSidebarState} />
          }
        />
      </Route>

      <Route element={<ProtectedRoutes authorized={ability.can('customer_read', 'Invoice')} />}>
        <Route
          path='factures'
          element={
            <MobileBreadcrumbHelper Component={Invoices} subtitle='invoices' onSetSidebarState={onSetSidebarState} />
          }
        />
      </Route>

      <Route
        path='/'
        element={
          <MobileBreadcrumbHelper Component={Security} subtitle='Security' onSetSidebarState={onSetSidebarState} />
        }
      />
      <Route
        path='securite'
        element={
          <MobileBreadcrumbHelper Component={Security} subtitle='Security' onSetSidebarState={onSetSidebarState} />
        }
      />

      <Route element={<ProtectedRoutes authorized={ability.can('customer_read', 'Notification')} />}>
        <Route
          path='notifications'
          element={
            <MobileBreadcrumbHelper
              Component={Notification}
              subtitle='notification'
              onSetSidebarState={onSetSidebarState}
            />
          }
        />
      </Route>

      <Route
        path='informations-generales'
        element={
          <MobileBreadcrumbHelper
            Component={GeneralInformation}
            subtitle='generalInformations'
            onSetSidebarState={onSetSidebarState}
          />
        }
      />
    </Routes>
  )
}

export default SettingsRoutes
