import { useContext, useState } from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { DatePicker, Radio, RadioChangeEvent, Select } from 'antd'
const { RangePicker } = DatePicker
import moment from 'moment'

interface IProps {
  onChange: (filterData: any) => void
}

export interface FilterData {
  tagIds?: number[]
  mailTypes?: string[]
  dateOfReceiptFrom?: Date
  dateOfReceiptTo?: Date
  viewed?: boolean
}

const Filter = ({ onChange }: IProps) => {
  const { domiciliation }: any = useContext(DomiciliationContext)
  const userMailTags = domiciliation.userMailTags
  const [_filterData, setFilterData] = useState<FilterData>({})
  const [_startDate, setStartDate] = useState<string | undefined>(undefined)
  const [_endDate, setEndDate] = useState<string | undefined>(undefined)

  const handleTagChange = (values: number[]) => {
    setFilterData((prev: FilterData) => {
      prev.tagIds = values
      onChange(prev)
      return prev
    })
  }

  const handleTypeChange = (values: string[]) => {
    setFilterData((prev: FilterData) => {
      prev.mailTypes = values
      onChange(prev)
      return prev
    })
  }

  const handleViewedChange = (e: RadioChangeEvent) => {
    setFilterData((prev: FilterData) => {
      if (e.target.value === '') {
        prev.viewed = undefined
      } else {
        prev.viewed = e.target.value === 'read'
      }
      onChange(prev)
      return prev
    })
  }

  const datePickerSelect = (dates) => {
    if (!dates) {
      setFilterData((prev: FilterData) => {
        prev.dateOfReceiptFrom = undefined
        prev.dateOfReceiptTo = undefined
        onChange(prev)
        return prev
      })
      return
    }
    if (dates && dates[0] !== null) {
      const startDate = moment(dates[0].$d.toString()).format()
      setStartDate(startDate)
    }
    if (dates && dates[1] !== null) {
      const endDate = moment(dates[1].$d.toString()).format()
      setEndDate(endDate)

      setFilterData((prev: FilterData) => {
        prev.dateOfReceiptFrom = moment(dates[0].$d.toString()).toDate()
        prev.dateOfReceiptTo = moment(dates[1].$d.toString()).toDate()
        onChange(prev)
        return prev
      })
    }
  }

  return (
    <div className="py-2 grid grid-flow-row-dense gap-1 grid-cols-1 tablet:grid-cols-2 wide:grid-cols-3">
      <Select
        className='col-span-1'
        placeholder="Tags"
        allowClear
        showSearch={false}
        mode="multiple"
        onChange={handleTagChange}
        options={userMailTags.map((t) => ({ value: +t.id, label: t.name }))}
      />

      <Select
        className='col-span-1'
        placeholder="Tout"
        allowClear
        showSearch={false}
        mode="multiple"
        onChange={handleTypeChange}
        options={[
          { value: 'Courrier', label: 'Courrier' },
          { value: 'Colis', label: 'Colis' },
          { value: 'Avis de passage', label: 'Avis de passage' },
          { value: 'Lettre A/R', label: 'Lettre A/R' },
        ]}
      />

      <Radio.Group className='col-span-1 tablet:col-span-2 wide:col-span-1' defaultValue={''} onChange={handleViewedChange}>
        <Radio.Button value="">Tous</Radio.Button>
        <Radio.Button value="read">Lus</Radio.Button>
        <Radio.Button value="unread">Non-lus</Radio.Button>
      </Radio.Group>

      <RangePicker
        className='col-span-1 tablet:col-span-2 wide:col-span-3'
        format={'DD/MM/YYYY'}
        style={{ 'width': '100%' }}
        onChange={(dates) => datePickerSelect(dates)}
        placement={'topRight'}
      />
    </div>
  )
}

export default Filter
