import gql from 'graphql-tag'
import { ADDRESS_DETAIL_FRAGMENT } from './addressDetail'
import { COUNTRY_CONFIG_FRAGMENT } from './countryConfig'
import { CGV_ACCEPTANCE_FRAGMENT } from './cgvAcceptance'
import { FORWARDING_ADDRESS_FRAGMENT } from './forwardingAddress'
import { INVOICE_FRAGMENT } from './invoice'
import { INFORMATION_CHANGE_FRAGMENT } from './informationChange'
import { CONTACT_DETAIL_FRAGMENT } from './contactDetail'
import { LEGAL_FORMALITY_FRAGMENT } from './legalFormality'
import { PAYMENT_SOURCE_FRAGMENT } from './paymentSource'
import { PERSONAL_INFORMATION_FRAGMENT } from './personalInformation'
import { PURCHASE_FRAGMENT } from './purchase'
import { SOCIETY_FRAGMENT } from './society'
import { HOSTER_FRAGMENT } from './hoster'
import { DOCUMENT_HOST_FRAGMENT } from '@/graphql/Fragments/documentHost'
import { USER_CGV_ACCEPTANCE_FRAGMENT } from './userCgvAcceptance'
import { USER_NOTIFICATION_FRAGMENT } from './userNotification'

export { CONTACT_FRAGMENT } from './contact'
export {
  COUNTRY_CONFIG_FRAGMENT,
  CGV_ACCEPTANCE_FRAGMENT,
  FORWARDING_ADDRESS_FRAGMENT,
  INVOICE_FRAGMENT,
  INFORMATION_CHANGE_FRAGMENT,
  CONTACT_DETAIL_FRAGMENT,
  LEGAL_FORMALITY_FRAGMENT,
  PAYMENT_SOURCE_FRAGMENT,
  PERSONAL_INFORMATION_FRAGMENT,
  PURCHASE_FRAGMENT,
  SOCIETY_FRAGMENT,
  USER_CGV_ACCEPTANCE_FRAGMENT,
  USER_NOTIFICATION_FRAGMENT
}

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    name
    color
    createdAt
  }
`

export const USER_MAIL_FRAGMENT = gql`
  fragment UserMailFragment on UserMail {
    id
    allowedOptions
    dateOfReceipt
    dateOfRedirection
    destructionExpectedDate
    disposingAvailabilityDate
    lifecycleSteps {
      title
      processingDate
      until
    }
    mailType
    markAsScanned
    priceTtc
    processedAt
    processingDate
    processingDetailType
    processingType
    scanFileThumbUrl
    scanFileUrl
    sendName
    shareable
    status
    tag {
      ...TagFragment
    }
    trackingNumber
    viewed
    hasUserRequest
    weight
  }
  ${TAG_FRAGMENT}
`

export const PAYMENT_INTENT_FRAGMENT = gql`
  fragment PaymentIntentFragment on StripePaymentIntent {
    id
    token
    state
    paymentIntentSecret
  }
`

export const FAILED_PAYMENT_FRAGMENT = gql`
  fragment FailedPaymentFragment on FailedPayment {
    id
    total
    issueDate
    pdfUrl
    formattedName
    invoiceNumber
    state
    paymentIntentSecret
    paymentIntent {
      ...PaymentIntentFragment
    }
  }
  ${PAYMENT_INTENT_FRAGMENT}
`

export const PUBLIC_FAILED_PAYMENT_FRAGMENT = gql`
  fragment PublicFailedPaymentFragment on PublicFailedPayment {
    id
    total
    issueDate
    pdfUrl
    formattedName
    invoiceNumber
    state
  }
`

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    shortname
    fullAddress
    phoneNumber
    streetAddress
    postalCity
    postalCode
    lng
    lat
    pickupInstructionsToS
    kbis
    openingHours {
      id
      day
      openTime
      closeTime
    }
    closingDays {
      id
      date
    }
  }
`

export const HOTLINE_PHONE_NUMBER_FRAGMENT = gql`
  fragment HotlinePhoneNumberFragment on HotlinePhoneNumber {
    id
    phoneNumber
    used
  }
`

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on CurrentUser {
    id
    email
    firstname
    lastname
    zendeskChatToken
    loginCount
    permissions {
      model
      action
    }
  }
`

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
  }
`

export const USERS_FRAGMENT = gql`
  fragment UsersFragment on User {
    id
    email
    firstname
    lastname
    role {
      ...RoleFragment
    }
    emailMd5
  }
  ${ROLE_FRAGMENT}
`

export const ROOM_FRAGMENT = gql`
  fragment RoomFragment on Room {
    id
    name
    description
    capacity
    equipments
    prices {
      hour
      halfDay
      day
    }
    openingHours {
      id
      day
      openTime
      closeTime
    }
    addressDetail {
      ...AddressDetailFragment
    }
    streetViewLink
    photos
    bestPrice
    bestPriceDetail {
      hour
      halfDay
      day
    }
    availableHours
    availableSlices
    selectedStartAt
    selectedEndAt
    accessDetails
  }
  ${ADDRESS_DETAIL_FRAGMENT}
`

export const CONTACT_OF_SHARING_FRAGMENT = gql`
  fragment ContactOfSharingFragment on ContactOfSharing {
    id
    email
    firstname
    lastname
    category
    withProcuration
  }
`

export const HOTLINE_PLAN_FRAGMENT = gql`
  fragment HotlinePlanFragment on HotlinePlan {
    id
    name
    priceHt
  }
`

export const HOTLINE_FRAGMENT = gql`
  fragment HotlineFragment on Hotline {
    id
    state
    hotlinePhoneNumber {
      id
      phoneNumber
      used
    }
    hotlinePlan {
      ...HotlinePlanFragment
    }
    originalPhoneNumber
    companyActivity
    activityDescription
    absentReason
    absentReasonOther
    emergencyPhoneNumber
  }
  ${HOTLINE_PLAN_FRAGMENT}
`

export const NOTIFICATION_UNSUBSCRIPTION_FRAGMENT = gql`
  fragment NotificationUnsubscriptionFragment on NotificationUnsubscription {
    id
    newUserMail
    marketing
    support
  }
`

export const APPOINTMENT_FRAGMENT_MINI = gql`
  fragment AppointmentFragmentMini on Appointment {
    id
    startAt
    endAt
    price
    room {
      ...RoomFragment
    }
  }
  ${ROOM_FRAGMENT}
`

export const APPOINTMENT_FRAGMENT_MAXI = gql`
  fragment AppointmentFragmentMaxi on Appointment {
    id
    startAt
    endAt
    price
    room {
      ...RoomFragment
    }
    paymentIntent {
      ...PaymentIntentFragment
    }
  }
  ${ROOM_FRAGMENT}
  ${PAYMENT_INTENT_FRAGMENT}
`

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    id
    amount
    status
    createdAt
    paymentIntent {
      ...PaymentIntentFragment
    }
    purchases {
      ...PurchaseFragment
    }
  }
  ${PAYMENT_INTENT_FRAGMENT}
  ${PURCHASE_FRAGMENT}
`

export const FORWARDING_CREDITS_ORDER_FRAGMENT = gql`
  fragment ForwardingCreditsOrderFragment on ForwardingCreditsOrder {
    id
    amount
    owner {
      id
      email
    }
    createdAt
    creditedAt
    paymentIntent {
      ...PaymentIntentFragment
    }
  }
  ${PAYMENT_INTENT_FRAGMENT}
`

export const COMMUNICATION_FRAGMENT = gql`
  fragment CommunicationFragment on Communication {
    id
    communicationType
    intensity
    message
    labelOk
    link
    sectionsToRestrict
    sectionsToAllow
    sectionsToDisplay
    sectionLanding
  }
`

export const LEGAL_DOCUMENT_FRAGMENT = gql`
  fragment LegalDocumentFragment on LegalDocument {
    id
    category
    fileType
    targetId
    fileRecto
    fileVerso
    state
  }
`

export const DOCUMENT_INFORMATION_FRAGMENT = gql`
  fragment DocumentInformationFragment on DocumentInformation {
    fileType
    hasVerso
  }
`
export const KYC_LOGIC_MISSING_DOCUMENT_FRAGMENT = gql`
  fragment KycLogicMissingDocumentFragment on KycLogicMissingDocument {
    category
    initial
    state
    idNowSdkAvailable
    documents {
      ...DocumentInformationFragment
    }
    legalDocument {
      ...LegalDocumentFragment
    }
  }
`

export const KYC_LOGIC_RULE_FRAGMENT = gql`
  fragment KycLogicRulesFragment on KycLogicRule {
    id
    targetType
    targetId
    targetName
    moralShareholderName
    rules {
      ...KycLogicMissingDocumentFragment
    }
    contactDetail {
      ...ContactDetailFragment
    }
  }
  ${CONTACT_DETAIL_FRAGMENT}
  ${KYC_LOGIC_MISSING_DOCUMENT_FRAGMENT}
  ${DOCUMENT_INFORMATION_FRAGMENT}
  ${LEGAL_DOCUMENT_FRAGMENT}
`

export const DOMICILIATION_SUBSCRIPTION_FRAGMENT = gql`
  fragment DomiciliationSubscriptionFragment on DomiciliationSubscription {
    id
    paymentType
    amount
    title
  }
`

export const DOMICILIATION_FRAGMENT = gql`
  fragment DomiciliationFragment on Domiciliation {
    id
    affiliated
    affiliate {
      id
      name
    }
    availablePaymentMethods
    addressSubscription {
      ...DomiciliationSubscriptionFragment
    }
    userMailSubscription {
      ...DomiciliationSubscriptionFragment
    }
    moderator {
      calendlyLink
    }
    cancelationDate
    legalflowDone
    nextPaymentDate
    canExpressForward
    mailManagement
    initialInvoicePaidAt
    endOfCommitment
    startingDateWithReconduction
    paymentType
    currentlyPaying
    contract
    hasSigned
    hostedAnswer
    hoster {
      ...HosterFragment
    }
    conform
    mailForwardingTargetName
    initialPaymentPriceHtToDisplay
    shouldAskForRedAlertFreeNum
    endOfCommitment
    forwardingCredits
    mailForwardingFrequency
    finalized
    hotlineFreeMonths
    whitelistAllPaymentTypes
    postalProcurationFlow {
      link
      signatureLink
      status
    }
    contractDelivered
    contractSignatureUrl
    documentHostFile
    documentHost {
      ...DocumentHostFragment
    }
    hasSigned
    forwardingAddress {
      ...ForwardingAddressFragment
    }
    address {
      ...AddressFragment
    }
    userMailTags {
      ...TagFragment
    }
    contactOfSharings {
      ...ContactOfSharingFragment
    }
    hotline {
      ...HotlineFragment
    }
    notificationUnsubscription {
      ...NotificationUnsubscriptionFragment
    }
    notificationUnsubscription {
      ...NotificationUnsubscriptionFragment
    }
    nextAppointments {
      ...AppointmentFragmentMini
    }
    pastAppointments {
      ...AppointmentFragmentMini
    }
    communications {
      ...CommunicationFragment
    }
    kycLogicRules {
      ...KycLogicRulesFragment
    }
    society {
      ...SocietyFragment
    }
    showCatchLeadModal
    serviceInterests {
      bank
      accountant
    }
  }
  ${DOCUMENT_HOST_FRAGMENT}
  ${DOMICILIATION_SUBSCRIPTION_FRAGMENT}
  ${HOSTER_FRAGMENT}
  ${ADDRESS_DETAIL_FRAGMENT}
  ${FORWARDING_ADDRESS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${TAG_FRAGMENT}
  ${CONTACT_OF_SHARING_FRAGMENT}
  ${NOTIFICATION_UNSUBSCRIPTION_FRAGMENT}
  ${HOTLINE_FRAGMENT}
  ${APPOINTMENT_FRAGMENT_MINI}
  ${COMMUNICATION_FRAGMENT}
  ${LEGAL_DOCUMENT_FRAGMENT}
  ${KYC_LOGIC_RULE_FRAGMENT}
  ${SOCIETY_FRAGMENT}
`
