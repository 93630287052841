import * as React from 'react'

interface IProps {
    password: string
}

export default class PasswordStrength extends React.Component<IProps, unknown> {
  public render() {
    const score = this.score()

    return <div className={`password-strength score-${score} ${score === 0 ? 'empty' : ''}`}>
      {Array(5).fill(null).map((_, i) =>
        <div key={i} className={`block ${i < score ? 'highlight' : ''}`} />,
      )}
    </div>
  }

  private score() {
    const { password } = this.props
    return [
      /[a-z]/,
      /[0-9]/,
      /[A-Z]/,
      /[^a-zA-Z0-9]/,
      /.{8,}/,
    ].reduce((sum, regex) => sum += (password.match(regex) ? 1 : 0), 0)
  }
}
