import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { PlusOutlined } from '@ant-design/icons'
import { CONTACT_OF_SHARING_QUERY } from 'src/graphql/ContactOfSharing'
import { CommonLoader } from 'src/components/shared/Loader'
import Contact from './Contact'
import UpsertContactModal from './UpsertContactModal'
import { Button } from './style'

const ContactList = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(CONTACT_OF_SHARING_QUERY)
  const [openUpsertContactModal, setOpenUpsertContactModal] = useState(false)

  return (
    <>
      <UpsertContactModal
        openModal={openUpsertContactModal}
        setOpenModal={setOpenUpsertContactModal}
      />
      <div className="team-section-title">
        <h2>{t('team:repertoire')}</h2>
        <Button style={{ minWidth: '110px' }} href={void (0)} onClick={() => setOpenUpsertContactModal(true)}>
          {t('common:add')} <PlusOutlined />
        </Button>
      </div>
      <div className="team-row first">
        <div className="team-column team-contact-name">{t('common:name')}</div>
        <div className="team-column team-category">{t('team:role')}</div>
        <div className="team-column team-contact-email">{t('common:email')}</div>
        <div className="team-column team-action" />
      </div>
      {
        loading ? (
          <div className="team-row first">
            <CommonLoader />
          </div>
        ) : (
          data.contactOfSharings.map((contact: any) => {
            return (
              <Contact contact={contact} key={contact.id} />
            )
          })
        )
      }
    </>
  )
}

export default ContactList

