import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import HelloSign from 'hellosign-embedded'
import { useEffect } from 'react'

const CONTRACT_SIGN_QUERY = gql`
  query contractSignatureParameters {
    contractSignatureParameters {
      contractSignatureUrl
      affiliatedUrlDashboard
    }
  }
`

const ContractSignature = () => {
  const client = new HelloSign()
  const { data } = useQuery(CONTRACT_SIGN_QUERY)

  useEffect(() => {
    if (data) {
      const res = data.contractSignatureParameters

      client.open(res.contractSignatureUrl, {
        clientId: `${process.env.REACT_APP_HELLOSIGN_SIGN_API_KEY}`,
        skipDomainVerification: true,
        debug: true,
        redirectTo: res.affiliatedUrlDashboard,
      })

      client.on('sign', () => {
        window.location.replace(res.affiliatedUrlDashboard)
      })
    }
  }, [data])

  return <></>
}

export default ContractSignature
