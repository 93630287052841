import gql from 'graphql-tag'
import { USER_MAIL_FRAGMENT } from './Fragments'

export const USER_MAILS = gql`
  query userMails($pagination: PaginationInput!, $filter: UserMailsFilterInput!) {
    userMails(pagination: $pagination, filter: $filter) {
      userMails {
        ...UserMailFragment
      }
      pagination {
        page
        pageLength
        total
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAILS_QUERY = gql`
  query userMails(
    $page: Int
    $per: Int
    $orderColumn: UserMails
    $orderDirection: SortDirection
    $startingDateOfReceipt: ISO8601DateTime
    $endingDateOfReceipt: ISO8601DateTime
    $tag: ID
    $status: UserMailStatus
  ) {
    userMails(
      page: $page
      per: $per
      orderColumn: $orderColumn
      orderDirection: $orderDirection
      startingDateOfReceipt: $startingDateOfReceipt
      endingDateOfReceipt: $endingDateOfReceipt
      tag: $tag
      status: $status
    ) {
      ...UserMailFragment
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAILS_SUBSCRIPTION = gql`
  subscription {
    userMailRefetch {
      ...UserMailFragment
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_VIEW_MUTATION = gql`
  mutation UserMailView($userMailId: ID!) {
    userMailView(userMailId: $userMailId) {
      userMail {
        id
        viewed
      }
    }
  }
`

export const USER_MAIL_QUERY_ESTIMATION_QUERY = gql`
  query UserMailQueryEstimation($userMailId: ID!, $queryType: UserMailQueryCategory!) {
    userMailQueryEstimation(userMailId: $userMailId, queryType: $queryType) {
      userMailQuery {
        userMail {
          ...UserMailFragment
        }
        amountHt
      }
      error {
        id
        message
      }
      totalHt
      totalTtc
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_QUERY_CREATE_MUTATION = gql`
  mutation UserMailQueryCreate($userMailId: ID!, $queryType: UserMailQueryCategory!, $forwardingAddressId: ID) {
    userMailQueryCreate(userMailId: $userMailId, queryType: $queryType, forwardingAddressId: $forwardingAddressId) {
      error {
        id
        message
      }
      userMailQuery {
        userMail {
          ...UserMailFragment
        }
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`

export const USER_MAIL_TICKET_CREATE_MUTATION = gql`
  mutation UserMailTicketCreate($userMailId: ID!, $subject: UserMailTicketSubject!, $description: String) {
    userMailTicketCreate(userMailId: $userMailId, subject: $subject, description: $description) {
      id
    }
  }
`

export const USER_MAIL_SHARE_MUTATION = gql`
  mutation UserMailShare($userMailIds: [ID!]!, $email: String!) {
    userMailShare(userMailIds: $userMailIds, email: $email) {
      errors {
        id
        message
      }
      userMails {
        ...UserMailFragment
      }
    }
  }
  ${USER_MAIL_FRAGMENT}
`
export const USER_MAILS_DOWNLOAD_ZIP = gql`
  mutation UserMailsDownloadZip($startingDate: ISO8601DateTime!, $endingDate: ISO8601DateTime!) {
    userMailsDownloadZip(startingDate: $startingDate, endingDate: $endingDate) {
      success
    }
  }
`
