import { Upload } from 'antd'
import {
  DrawerContentWrapper,
  PlusIcon,
  ValidationErrorNotice,
  FileFormatNotice,
  FileDisplay,
  CustomModifyButton
} from '../ProcedureDrawer/styles'
import { Dispatch, SetStateAction, useState } from 'react'
import { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import plusSign from 'src/images/plusSign/plus-circle-blue.svg'

interface IdentityUploadProps {
  uploadedFile: RcFile | null
  setUploadedFile: Dispatch<SetStateAction<RcFile | null>>
  children?: React.ReactNode
  buttonText?: string
  fileFormat: boolean
}

const IdentityUpload = ({
  uploadedFile,
  setUploadedFile,
  children,
  buttonText,
  fileFormat
}: IdentityUploadProps) => {
  const { t } = useTranslation()
  const [validationError, setValidationError] = useState('')

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 6000
    if (!isLt2M) {
      setValidationError(t('common:drawer.tooBig'))
    } else {
      setValidationError('')
    }
    return isLt2M
  }

  const onChange = (e) => {
    switch (e.file.status) {
      case 'done':
        return setUploadedFile(e.file.originFileObj)
      default:
        return setUploadedFile(null)
    }
  }

  const onModification = (e) => {
    return setUploadedFile(e.file.originFileObj)
  }

  return !uploadedFile ? (
    <DrawerContentWrapper>
      {children}
      <Upload
        accept='.jpg, .jpeg, .png, .pdf'
        customRequest={({ onSuccess }) => dummyRequest({ onSuccess })}
        onChange={onChange}
        beforeUpload={beforeUpload}
        showUploadList={false}
        maxCount={1}
      >
        <Button bgColor='white' justifyContent='center'>
          <PlusIcon src={plusSign} alt='icone +' />
          {buttonText ? buttonText : t('common:document.add')}
        </Button>
      </Upload>
      {validationError && <ValidationErrorNotice>{validationError}</ValidationErrorNotice>}
      {fileFormat && <FileFormatNotice>{t('common:drawer.notice')}</FileFormatNotice>}
    </DrawerContentWrapper>
  ) : (
    <>
      <FileDisplay>
        {uploadedFile.name}
        <Upload
          accept='.jpg, .jpeg, .png, .pdf'
          customRequest={({ onSuccess }) => dummyRequest({ onSuccess })}
          onChange={onModification}
          beforeUpload={beforeUpload}
          showUploadList={false}
          maxCount={1}
        >
          <CustomModifyButton>{t('common:modify')}</CustomModifyButton>
        </Upload>
      </FileDisplay>
      {validationError && <ValidationErrorNotice>{t('common:drawer.tooBig')}</ValidationErrorNotice>}
    </>
  )
}

export default IdentityUpload
