import gql from 'graphql-tag'
import { DOMICILIATION_FRAGMENT, KYC_LOGIC_RULE_FRAGMENT } from './Fragments'

export const DOMICILIATION_QUERY = gql`
  {
    domiciliation {
      ...DomiciliationFragment
    }
  }
  ${DOMICILIATION_FRAGMENT}
`

export const KYC_LOGIC_RULES_QUERY = gql`
  {
    domiciliation {
      kycLogicRules {
        ...KycLogicRulesFragment
      }
    }
  }
  ${KYC_LOGIC_RULE_FRAGMENT}
`

export const DOMICILIATION_STEPS_STATUSES = gql `
  {
    domiciliationStepsStatuses {
      step
      status
    }
  }
`

export const DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION = gql`
  mutation DomiciliationForwardingAddressUpdate(
    $mailForwardingTargetName: String,
    $streetAddress: String!,
    $postalCode: String,
    $city: String!,
    $country: String!,
    $complement: String
  ) {
    domiciliationForwardingAddressUpdate(
      mailForwardingTargetName: $mailForwardingTargetName,
      streetAddress: $streetAddress,
      postalCode: $postalCode,
      city: $city,
      country: $country,
      complement: $complement
    ) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`

export const DOMICILIATION_COMMUNICATION_VIEW_MUTATION = gql`
  mutation DomiciliationCommunicationView($id: ID!) {
    domiciliationCommunicationView(id: $id) {
      id
    }
  }
`

export const LEAD_CREATE_MUTATION = gql`
  mutation LeadCreate($email: String!, $phoneNumber: String!) {
    leadCreate(email: $email, phoneNumber: $phoneNumber) {
      id
    }
  }
`

export const DOMICILIATION_UPDATE_MUTATION = gql`
  mutation DomiciliationUpdate($freeScan: Boolean, $freeScanNotified: Boolean) {
    domiciliationUpdate(freeScan: $freeScan, freeScanNotified: $freeScanNotified) {
      domiciliation {
        id
      }
    }
  }
`
