import { useContext, useState } from 'react'
import Routing from '@/CustomerArea/Routing'
import { useTranslation } from 'react-i18next'
import { AbilityContext } from 'src/contexts/abilityContext'
import { faQuestionCircle } from '@sedomicilier/pro-light-svg-icons'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { NotificationContext } from 'src/contexts/notificationContext'
import navigationSections from 'src/components/layouts/SideMenu/NavigationSections'
import { useLocation, useMatch } from 'react-router'
import Notification from '../Notifications'
import MobileNavItem from './MobileNavItem'
import styled from 'styled-components'

const MobileMenu = () => {
  const { t } = useTranslation()

  const ability: any = useContext(AbilityContext)
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { kyc }: any = useContext(NotificationContext)

  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => {
    if (collapsed) {
      document.body.className += ' menumodal'
    } else {
      document.body.className = document.body.className.replace(' menumodal', '')
    }
    setCollapsed(!collapsed)
  }

  const sideMenuSections = navigationSections({ domiciliation, Routing, kyc, ability, t })

  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const match = useMatch(`${basePath}/*`)

  const mailCount = kyc?.userMails ?? 0

  return (
    <>
      <div className='tablet:hidden'>
        <button className="relative group" onClick={toggleNavbar}>
          {
            mailCount > 0 && collapsed &&
            <span className='absolute -ml-3'>
              <Notification number={mailCount} />
            </span>
          }
          <div
            className="relative flex overflow-hidden items-center justify-center
          rounded-full w-[50px] h-[50px] transform transition-all bg-slate-700
          ring-0 ring-gray-300 ring-opacity-30 duration-200 shadow-md ">
            <div
              className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all
            duration-300 origin-center overflow-hidden">
              <div
                className={'bg-neutral-0 h-[2px] w-7 transform transition-all duration-300 origin-left ' +
               (!collapsed ? 'translate-x-10' : '')}></div>
              <div
                className={'bg-neutral-0 h-[2px] w-7 rounded transform transition-all duration-300 delay-75 ' +
               (!collapsed ? 'translate-x-10' : '')}></div>
              <div
                className={'bg-neutral-0 h-[2px] w-7 transform transition-all duration-300 origin-left  delay-150 ' +
               (!collapsed ? 'translate-x-10' : '')}></div>
              {
                !collapsed && <MenuBurger
                  className="absolute items-center justify-between w-12 flex opacity-0" style={{ left: '-2px' }}>
                  <div className="absolute bg-neutral-0 h-[2px] w-5 rotate-45" style={{ left: '-14px' }}></div>
                  <div className="absolute bg-neutral-0 h-[2px] w-5 -rotate-45"></div>
                </MenuBurger>
              }
            </div>
          </div>
        </button>
      </div>

      {!collapsed && <Collapse className="fixed bg-neutral-0 w-full tablet:hidden">
        <MobileNavUl className="overflow-y-auto">
          {sideMenuSections.map((section) => {
            if (section.logo || !section.display) {
              return null
            }
            return (
              <MobileNavLi
                className={(section.route === match?.pathnameBase ? 'active' : '') + ' m-0 text-neutral-0'}
                key={section.dataSpecId}>
                <MobileNavItem
                  className='text-white'
                  to={section.route}
                  authorized={section.authorized}
                  icon={section.icon}
                  data-spec-id={section.dataSpecId}
                  setCollapsed={setCollapsed}
                  notification={section.notification}
                  label={section.sectionTitle} />
              </MobileNavLi>
            )
          })}
          <MobileNavLi className="bg-neutral-0 text-black">
            <MobileNavItem
              className="text-dark"
              setCollapsed={setCollapsed}
              to={Routing.FAQ}
              authorized={true}
              icon={faQuestionCircle}
              label={t('nav:faq')}
              target='_blank' />
          </MobileNavLi>
        </MobileNavUl>
      </Collapse>
      }
    </>
  )
}

const MenuBurger = styled.div`
  transition: opacity 300ms ease-in-out 300ms;
  opacity: 1;
`

const Collapse = styled.div`
  min-height: 100%;
  top: 70px;
  left: 0;
  z-index: 999;
`

const MobileNavUl = styled.ul`
  background-color: #000F46;
  height: calc(100vh - 94px);
`

const MobileNavLi = styled.li`
  &.active {
    background: #fff3;
  }
  &:hover {
    background: #fff3;
  }
`


export default MobileMenu
