// @ts-nocheck
import React from 'react'
import { Query } from '@apollo/client/react/components'
import { Translation } from 'react-i18next'
import IInvoice from 'src/interfaces/Invoice'
import * as InfiniteScroll from 'react-infinite-scroll-component'
import {
  INVOICES_QUERY,
  INVOICES_SUBSCRIPTION,
  FAILED_PAYMENTS_SUBSCRIPTION,
} from 'src/graphql/Invoices'
import { IOrder } from './Interfaces'
import List from './List'
import { CommonLoader } from '@/components/shared/Loader'
import ImageNoInvoice from '@/images/no-invoice.svg'
import IFailedPayment from 'src/interfaces/FailedPayment'
import { isDemo } from '../../demo/Utils'
import RegularizeFailedPayments from './RegularizeFailedPayments'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const PER_PAGE = 20

interface IState {
  order?: IOrder
  page: number
  hasMore: boolean
}

class Index extends React.Component<unknown, IState> {
  public static contextType = DomiciliationContext
  constructor(props: unknown) {
    super(props)
    this.onChangeOrder = this.onChangeOrder.bind(this)

    this.state = {
      page: 1,
      hasMore: true,
    }
  }

  public render() {
    const { refetch: refetchDomicilation } = this.context

    return (
      <Translation ns={['common', 'invoices']}>
        {(t) => (
          <Query
            query={INVOICES_QUERY}
            variables={{
              page: 1,
              per: PER_PAGE,
              order: this.state.order?.direction,
            }}
          >
            {({ loading, error, data, fetchMore, subscribeToMore, refetch }: any) => {
              if (!isDemo()) {
                subscribeToMore({
                  document: INVOICES_SUBSCRIPTION,
                  updateQuery: (
                      previousResult,
                      { subscriptionData }: { subscriptionData: any },
                  ) => {
                    if (!subscriptionData.data.invoiceRefetch) {
                      return previousResult
                    } else {
                      const invoice: IInvoice =
                        subscriptionData.data.invoiceRefetch
                      const result: IInvoice[] = []
                      let toUnshift = true
                      previousResult.invoices.forEach((i: IInvoice) => {
                        if (i.id === invoice.id) {
                          result.push(invoice)
                          toUnshift = false
                        } else {
                          result.push(i)
                        }
                      })

                      if (toUnshift) {
                        result.unshift(invoice)
                      }
                      return { invoices: result }
                    }
                  },
                })

                subscribeToMore({
                  document: FAILED_PAYMENTS_SUBSCRIPTION,
                  updateQuery: (
                      previousResult,
                      { subscriptionData }: { subscriptionData: any },
                  ) => {
                    if (!subscriptionData.data.unpaidInvoiceRefetch) {
                      return previousResult
                    } else {
                      const unpaidInvoice: IFailedPayment =
                        subscriptionData.data.unpaidInvoiceRefetch
                      const result: IFailedPayment[] = []
                      let toUnshift = true
                      previousResult.unpaidInvoices.forEach(
                          (i: IFailedPayment) => {
                            if (i.id === unpaidInvoice.id) {
                              result.push(unpaidInvoice)
                              toUnshift = false
                            } else {
                              result.push(i)
                            }
                          },
                      )

                      if (toUnshift) {
                        result.unshift(unpaidInvoice)
                      }
                      return { unpaidInvoices: result }
                    }
                  },
                })
              }

              if (loading || (data && Object.keys(data).length === 0)) {
                return <CommonLoader />
              }
              if (error) {
                return (
                  <div>
                    {t('common:error')}! ${error.message}
                  </div>
                )
              }
              if (
                data?.invoices?.length === 0 &&
                data?.unpaidInvoices?.length === 0
              ) {
                return (
                  <div className="settings-block invoices-section">
                    <div className="no-invoice">
                      <img src={ImageNoInvoice} />
                      <div className="msg">{t('invoices:no_invoices')}</div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div
                    className={`settings-block invoices-section ${loading && 'loading'
                    }`}
                  >
                    <h2 className="subtitle-invoices">
                      {t('invoices:invoices')}
                    </h2>

                    {data.unpaidInvoices?.length > 0 &&
                      <RegularizeFailedPayments
                        unpaidInvoices={data.unpaidInvoices}
                        refresh={this.refresh(refetch, refetchDomicilation)}
                      />
                    }

                    <InfiniteScroll
                      dataLength={data.invoices.length}
                      next={this.onFetchMore.bind(this, fetchMore)}
                      hasMore={this.state.hasMore}
                      loader={null}
                      scrollableTarget="overflowContent"
                    >
                      <List
                        unpaidInvoices={data.unpaidInvoices}
                        invoices={data.invoices}
                        onChangeOrder={this.onChangeOrder}
                        order={this.state.order}
                        refresh={this.refresh(refetch, refetchDomicilation)}
                      />
                    </InfiniteScroll>
                  </div>
                )
              }
            }}
          </Query>
        )}
      </Translation>
    )
  }

  private refresh(refetch, refetchDomicilation) {
    return () => {
      refetch()
      refetchDomicilation()
    }
  }

  private onChangeOrder(order: IOrder) {
    this.setState({ order, page: 1, hasMore: true })
  }

  private onFetchMore(fetchMore: (vars: any) => void) {
    fetchMore({
      variables: {
        page: this.state.page + 1,
      },
      updateQuery: (
          previousResult: { invoices: IInvoice[] },
          { fetchMoreResult }: { fetchMoreResult: { invoices: IInvoice[] } },
      ) => {
        this.setState((prevState: IState) => {
          prevState.hasMore = fetchMoreResult.invoices.length > 0
          prevState.page++
          return prevState
        })

        if (fetchMoreResult.invoices.length === 0) {
          return previousResult
        } else {
          const result = {
            ...previousResult,
            invoices: [] as IInvoice[],
          }

          previousResult.invoices.forEach((um) => {
            if (!fetchMoreResult.invoices.find((umMoreResult) => umMoreResult.id === um.id)) {
              result.invoices.push(um)
            }
          })
          result.invoices = result.invoices.concat(fetchMoreResult.invoices)

          return result
        }
      },
    })
  }
}
export default Index
