import gql from 'graphql-tag'
import { HOTLINE_PHONE_NUMBER_FRAGMENT } from './Fragments'


export const HOTLINE_PHONE_NUMBER_QUERY = gql`
    {
        hotlinePhoneNumbers {
            ...HotlinePhoneNumberFragment
        }
    }
    ${HOTLINE_PHONE_NUMBER_FRAGMENT}
`

export const HOTLINE_PHONE_NUMBERS_SUBSCRIPTION = gql`
    subscription {
        hotlinePhoneNumberRefetch {
            ...HotlinePhoneNumberFragment
        }
    }
    ${HOTLINE_PHONE_NUMBER_FRAGMENT}
`
