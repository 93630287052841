import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from './Form'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { Can } from 'src/contexts/abilityContext'
import { EmailModal } from 'src/components/profile/shared'
import useAlert from 'src/hooks/useAlert'

const Notification = () => {
  const { t } = useTranslation()
  const { setAlert } = useAlert()
  const { domiciliation }: any = React.useContext(DomiciliationContext)
  const { contact }: any = useContext(ContactContext)
  const [showModalUpdateEmail, setShowModalUpdateEmail] = useState<boolean>(false)

  return (
    <div className="settings-block notifications-section">
      <h2 className="subtitle-sidebar">
        Notifications
        <div className="explain-container">
          <p>{t('notifications:hint')}</p>
        </div>
      </h2>

      <div className="notification-element">
        <div className="bloc-notification-large">
          <p>E-mail</p>
          <p className="info-sub">{contact.email}</p>
        </div>
        <Can I="customer_manage" a="Domiciliation">
          <div className="bloc-notification-right">
            <a
              className="default-link"
              onClick={() => setShowModalUpdateEmail(true)}
            >
              {t('common:modify')}
            </a>
          </div>
        </Can>
      </div>

      <Form initialValues={domiciliation.notificationUnsubscription} />

      {
        showModalUpdateEmail &&
        <EmailModal
          onCloseModal={() => setShowModalUpdateEmail(false)}
          initialValues={contact}
          setAlert={setAlert}
        />
      }
    </div>
  )
}

export default Notification
