import { useContext } from 'react'
import { UserMailContext } from '../../contexts/UserMailContext'
import NoUserMailSelected from './NoUserMailSelected'
import UserMailDetails from './UserMailDetails'
import styled from 'styled-components'

const RightPanel = () => {
  const { selectedMail }: any = useContext(UserMailContext)

  return <RightPanelWrapper>{!selectedMail ? <NoUserMailSelected /> : <UserMailDetails />}</RightPanelWrapper>
}

export const RightPanelWrapper = styled.div`
  position: sticky;
  top: 110px;
`
export default RightPanel
