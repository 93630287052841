import { gql } from '@apollo/client'
import ISearchSociety from 'src/interfaces/SearchSociety'

export interface SearchSocietyDataType {
  searchSociety: ISearchSociety[]
}

export const SEARCH_SOCIETY = gql`
  query searchSociety($query: String!) {
    searchSociety(query: $query) {
      name
      address
      streetAddress
      postalCode
      city
      country
      countryCode
      siren
      legalStatusId
      legalStatusName
      leaderFirstname
      leaderLastname
    }
  }
`
