import React from 'react'
import { ISearch } from './Interfaces'
import { useQuery } from '@apollo/client'
import { ROOM_MEETINGS_QUERY } from '@/graphql/RoomMeeting'
import IRoom from '@/interfaces/Room'
import { Room } from './Helpers'
import { useTranslation } from 'react-i18next'
import { CommonLoader } from '@/components/shared/Loader'

interface IProps {
  search: ISearch
  handleSelect: (room: IRoom) => void
  allFilled: boolean
}

const List = (props: IProps) => {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(ROOM_MEETINGS_QUERY, {
    skip: !props.allFilled,
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      per: 1000,
      ...props.search,
      startingAt: props.search.startingAt?.format(),
      endingAt: props.search.endingAt?.format(),
    },
  })

  if (!props.allFilled) {
    return <div className="msg">{t('common:precis_search')}</div>
  } else if (loading) {
    return <div className="msg"><CommonLoader /></div>
  } else if (error) {
    return <div className="msg">
      {error.graphQLErrors.map(({ message }: {message: string}) => (
        <div key={message}>{message}</div>
      ))}
    </div>
  } else if (data.error) {
    return <div className="msg"><div>{data.error.message}</div></div>
  }

  if (data.roomMeetings === undefined || data.roomMeetings.length === 0) {
    return <div className="msg">{t('common:noresult')}</div>
  }

  return (
    <div>
      {data.roomMeetings.map((room: IRoom) => (
        <div key={room.id} className="list-rooms">
          <Room room={room} handleSelect={props.handleSelect} showPrice={true}/>
        </div>
      ))}
    </div>
  )
}

export default List
