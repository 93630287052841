// @ts-nocheck
import React from 'react'
import { UserMailQueryType } from '../Enum'
import { USER_MAIL_QUERY_CREATE_MUTATION } from '@/graphql/UserMail'
import { USER_MAIL_FRAGMENT } from '@/graphql/Fragments'
import { Mutation } from '@apollo/client/react/components'
import IUserMailQuery from '@/interfaces/UserMailQuery'
import IUserMail from '@/interfaces/UserMail'
import IContact from '@/interfaces/Contact'
import { IUserMailQueryEstimation } from '../Interfaces'
import { withTranslation, WithTranslation } from 'react-i18next'
import { isMobile } from '@/components/shared/utilities/isMobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
} from '@sedomicilier/pro-light-svg-icons'
import { Envelope } from '../Helpers'
import IDomiciliation from 'src/interfaces/Domiciliation'
import IGenericError from '@/interfaces/GenericError'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { Modal } from 'antd'

interface IProps extends WithTranslation {
  contact: IContact
  userMailQueryEstimation: IUserMailQueryEstimation
  type: UserMailQueryType
  userMail: IUserMail
  onCloseModal: () => void
  setAlert: any
}

class Content extends React.Component<IProps, unknown> {
  public static contextType = DomiciliationContext

  constructor(props: IProps) {
    super(props)

    this.update = this.update.bind(this)
    this.onCompleted = this.onCompleted.bind(this)
    this.getOptionTargetAddress = this.getOptionTargetAddress.bind(this)
  }

  public render() {
    const { t } = this.props
    const {
      domiciliation,
      loading: loadingDomiciliation,
      error: errorDomiciliation,
      refetch
    }: any = this.context

    if (this.props.userMailQueryEstimation) {
      const userMailQuery =
        this.props.userMailQueryEstimation.userMailQuery
      const expectedBalance = domiciliation.forwardingCredits - 1
      const error = this.props.userMailQueryEstimation.error

      // This modal is blocked if we have colis and something else, let's say it's good enough to consider having only
      // parcel in userMails if at least one is present
      const includeParcel = this.props.userMail && this.props.userMail.mailType === 'Colis'
      const useForwardingCredit = this.props.type === 'forwarding' && !includeParcel

      return (
        loadingDomiciliation || errorDomiciliation ? null :
          <Modal
            data-id="re-expedition"
            open={true}
            onCancel={this.props.onCloseModal}
            className={`modal-usermail-query modal-form modal-dialog-centered ${isMobile ? '' : 'modal-large'
            }`}
            title={
              t('userMails:userMailQuery.content.title', {
                type: this.props.t(`userMails:userMailQuery.type.${this.props.type}`),
                id: userMailQuery.userMail.id,
              },
              )
            }
          >
            <div>
              <div>
                <div className="forwarding-address">
                  <p className="adresse-popup adr-ligne1 gray-text">
                    {t('userMails:userMailQuery.content.address', {
                      type: t(
                          `userMails:userMailQuery.type.${this.props.type}`,
                      ),
                      address: this.getOptionTargetAddress(
                          this.props.type,
                          domiciliation,
                          this.props.contact,
                      ),
                    })}
                  </p>
                </div>

                {error && (
                  <div className="error">
                    {t('userMails:userMailQuery.error', {
                      type: this.props.t(`userMails:userMailQuery.errorType.${this.props.type}`),
                    })}
                  </div>
                )}

                <h2 className="title-popup cart-title">Votre panier</h2>
                <div className="forwarding-address">
                  <div className="mail-card">
                    <Envelope userMail={userMailQuery.userMail} />
                    <div>n&deg; {userMailQuery.userMail.id}</div>
                  </div>
                </div>

                {useForwardingCredit &&
                  <>
                    <h2 className="title-popup reexpedition-title">Votre solde de réexpéditions</h2>
                    <div className="forwarding-address">
                      <p className="adresse-popup adr-ligne1 gray-text">
                        {t('userMails:userMailQuery.content.currentBalance')}
                        {' : '}
                        <b className="black-text">{domiciliation.forwardingCredits}</b>
                      </p>
                    </div>
                    <div className="forwarding-address">
                      <p className="adresse-popup adr-ligne1 gray-text">
                        {t('userMails:userMailQuery.content.expectedBalance')}
                        {' : '}
                        <b className="black-text">{expectedBalance}</b>
                      </p>
                    </div>
                  </>
                }
              </div>

              {/* right sidebar */}
              <div>
                <div className="vertical-popup">
                  <table className="table-popup">
                    <tbody>
                      <tr>
                        <td>{userMailQuery.userMail.mailType} {userMailQuery.userMail.id}</td>
                      </tr>
                      {
                        error &&
                        <tr>
                          <td>
                            {userMailQuery.userMail.id}
                            <span
                              id={`alreadyShipped_${userMailQuery.userMail.id}`}
                              className="already-queried"
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>

                  <div className="resume-popup">
                    <div className="dropdown-divider-popup" />
                    <label className="left-popup padding-15">Total</label>

                    {
                      !useForwardingCredit &&
                      <label className="right-popup padding-15">
                        {t('common:currency', {
                          value:
                            this.props.userMailQueryEstimation.totalTtc,
                        })}{' '}
                        {this.props.userMailQueryEstimation.totalTtc !== 0 && t('common:inclTaxes')}
                      </label>
                    }
                    {
                      useForwardingCredit &&
                      <label className="right-popup padding-15">
                        1 {t('common:forwarding')}
                      </label>
                    }
                    <div className="clearfix" />

                    <Mutation
                      mutation={USER_MAIL_QUERY_CREATE_MUTATION}
                      variables={{
                        userMailId: userMailQuery.userMail.id,
                        queryType: this.props.type,
                      }}
                      update={this.update}
                      onCompleted={(data: any) => {
                        this.onCompleted(data, refetch)
                      }}
                    >
                      {
                        /* tslint:disable:no-shadowed-variable */ (
                            create,
                            { loading, error }: any,
                        ) => (
                          <>
                            {error && (
                              <div>
                                {t('common:error')}! ${error.message}
                              </div>
                            )}
                            <button
                              className="btn section-button btn-block primary-button"
                              disabled={!!loading || (expectedBalance < 0 && useForwardingCredit)}
                              onClick={create as any}
                            >
                              {t('common:validate')}
                            </button>
                          </>
                        )
                      }
                    </Mutation>
                  </div>
                </div>
              </div>
            </div>

          </Modal>
      )
    } else {
      return null
    }
  }

  private getOptionTargetAddress(
      optionType: string,
      domiciliation: IDomiciliation,
      contact: IContact,
  ) {
    if (optionType === 'provisioning') {
      return domiciliation.address.fullAddress.trim()
    } else if (optionType === 'scanning') {
      return contact.email
    } else if (optionType === 'forwarding') {
      return domiciliation.forwardingAddress.fullAddress.trim()
    } else {
      return null
    }
  }

  private update(cache: any, { data }: { data: any }) {
    if (data?.userMailQueryCreate) {
      cache.writeFragment({
        fragment: USER_MAIL_FRAGMENT,
        fragmentName: 'UserMailFragment',
        data: data.userMailQueryCreate.userMailQuery.userMail,
      })
    }
  }
  private onCompleted(data: {
    error: IGenericError;
    userMailQueryCreate: {
      userMailQueries: IUserMailQuery[];
      errors: IGenericError[];
    };
  }, refetchDomiciliation: any) {
    if (data.error) {
      this.props.setAlert({ type: 'error', text: data.error.message, size: 'small' })
    } else if (
      data.userMailQueryCreate &&
      data.userMailQueryCreate.errors &&
      data.userMailQueryCreate.errors.length
    ) {
      {
        data.userMailQueryCreate.errors.map((error) => (
          this.props.setAlert({ type: 'error', text: `${error.id} : ${error.message}`, size: 'small' })
        ))
      }
    } else {
      refetchDomiciliation().then(() => {
        this.props.onCloseModal()
        this.props.setAlert({
          type: 'success',
          text: this.props.t('userMails:userMailQuery.success',
              {
                title:
                this.props.t('userMails:userMailQuery.content.title', {
                  type: this.props.t(`userMails:userMailQuery.type.${this.props.type}`),
                  id: this.props.userMailQueryEstimation.userMailQuery.userMail.id,
                }).toLowerCase(),
              },
          ),
          size: 'small'
        })
      })
    }
  }
}

export default withTranslation(['userMails', 'common'])(Content)
