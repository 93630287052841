import gql from 'graphql-tag'
import { CONTACT_OF_SHARING_FRAGMENT } from './Fragments'

export const CONTACT_OF_SHARING_QUERY = gql`
  {
    contactOfSharings {
      ...ContactOfSharingFragment
    }
  }

  ${CONTACT_OF_SHARING_FRAGMENT}
`

export const CONTACT_OF_SHARING_CREATE_MUTATION = gql`
  mutation ContactOfSharingCreate(
    $email: String!,
    $firstname: String,
    $lastname: String,
    $category: String,
    $withProcuration: Boolean
  ){
    contactOfSharingCreate(
      email: $email,
      firstname: $firstname,
      lastname: $lastname,
      category: $category,
      withProcuration: $withProcuration
    ){
      contactOfSharing {
        ...ContactOfSharingFragment
      }
    }
  }
  ${CONTACT_OF_SHARING_FRAGMENT}
`

export const CONTACT_OF_SHARING_UPDATE_MUTATION = gql`
  mutation ContactOfSharingUpdate(
    $id: ID!,
    $email: String!,
    $firstname: String,
    $lastname: String,
    $category: String,
    $withProcuration: Boolean
  ){
    contactOfSharingUpdate(
      id: $id,
      email: $email,
      firstname: $firstname,
      lastname: $lastname,
      category: $category,
      withProcuration: $withProcuration
    ){
      contactOfSharing {
        ...ContactOfSharingFragment
      }
    }
  }
  ${CONTACT_OF_SHARING_FRAGMENT}
`

export const CONTACT_OF_SHARING_DESTROY_MUTATION = gql`
  mutation ContactOfSharingDestroy($id: ID!) {
    contactOfSharingDestroy(id: $id) {
      contactOfSharing {
        ...ContactOfSharingFragment
      }
    }
  }
  ${CONTACT_OF_SHARING_FRAGMENT}
`
