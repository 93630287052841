import * as React from 'react'
import { useTranslation } from 'react-i18next'
import FormPassword from './FormPassword'
import useAlert from 'src/hooks/useAlert'
import { Modal } from 'antd'

interface IProps {
  onCloseModal: () => void;
}

const ChangePasswordModal = (props: IProps) => {
  const { t } = useTranslation()
  const { setAlert } = useAlert()

  return (
    <Modal
      data-id="update-password"
      open={true}
      onCancel={props.onCloseModal}
      footer={null}
      title={t('security:update_of_the_password')}
    >

      <FormPassword {...props} setAlert={setAlert} />
    </Modal>
  )
}

export default ChangePasswordModal
