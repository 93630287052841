import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { DOMICILIATION_QUERY } from 'src/graphql/Domiciliation'
import { CommonLoader } from 'src/components/shared/Loader'
import * as Cookies from 'js-cookie'
import { DomiciliationContextProps } from '@/interfaces/Domiciliation'

export const DomiciliationContext = createContext({} as DomiciliationContextProps)
export const DomiciliationContextProvider = DomiciliationContext.Provider
export const DomiciliationContextConsumer = DomiciliationContext.Consumer

interface IProps {
  children: React.ReactNode
}

const DomiciliationProvider = (props: IProps) => {
  const { data, loading, error, refetch } = useQuery(DOMICILIATION_QUERY)

  // used by Bugsnag reporter
  if (data) {
    Cookies.set('domiciliation_id', data?.domiciliation?.id)
  }

  if (loading) {
    return <CommonLoader size={48} />
  }

  return (
    <DomiciliationContextProvider value={{ domiciliation: data?.domiciliation, loading, error, refetch }}>
      {props.children}
    </DomiciliationContextProvider>
  )
}

export default DomiciliationProvider
