import DropdownContact from '@/components/layouts/DropdownContact'
import AdministratorAccess from '@/components/layouts/Topbar/AdministratorAccess'
import constants from '@/constants'
import seDomLogo from '@/images/sedomicilier_logo.svg'
import { previousPage } from '@/reducers/workflow'
import { BackButton, useWindowDimensions } from '@sedomicilier/material-sd'
import { useDispatch } from 'react-redux'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const mobile = width! <= parseInt(constants.breakpoints['mobile-breakpoint-i'], 10)

  return (
    <div className='tablet:px-5 mobile:px-3 py-2 border-b border-neutral-200 flex items-center justify-between mb-5'>
      {mobile ? (
        <BackButton size='small' onClick={() => dispatch(previousPage())} />
      ) : (
        <img src={seDomLogo} alt='brand logo' />
      )}
      <div className='flex items-center gap-2'>
        <AdministratorAccess />
        <DropdownContact />
      </div>
    </div>
  )
}

export default Header
