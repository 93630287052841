import gql from 'graphql-tag'

export const INFORMATION_CHANGE_FRAGMENT = gql`
  fragment InformationChangeFragment on InformationChange {
    oldValue
    newValue
    sentTo
    informationableType
  }
`

