import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import { Colors } from '@/components/UIKit'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import {
  InfosList, InfosListElement, InfosListElementHeader,
  InfosListElementValue, Panel, PanelTitle, RightLink
} from './styles'
import constants from '@/components/UIKit/constants'
import dayjs from 'dayjs'

const Information = () => {
  const { t } = useTranslation('domiciliationPage')
  const navigate = useNavigate()
  const { domiciliation }: any = useContext(DomiciliationContext)

  return (
    <DomiciliationPageWrapper>
      {
        domiciliation.addressSubscription &&
        <Panel>
          <PanelTitle>
            {t('title')}
          </PanelTitle>
          <InfosList>
            <InfosListElement>
              <InfosListElementHeader>{t('domiciliationTitle')}</InfosListElementHeader>
              <InfosListElementValue>{domiciliation.address.fullAddress}</InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>{t('domiciliationPriceTitle')}</InfosListElementHeader>
              <InfosListElementValue>
                {domiciliation.addressSubscription.amount} € HT&nbsp;
                {t(`priceFrequencyLiteral.${domiciliation.addressSubscription.paymentType}`)}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>{t('domiciliationInitialInvoiceTitle')}</InfosListElementHeader>
              <InfosListElementValue>
                {dayjs(domiciliation.initialInvoicePaidAt).format('DD/MM/YYYY')}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>{t('domiciliationCommitmentTitle')}</InfosListElementHeader>
              <InfosListElementValue>
                {dayjs(domiciliation.startingDateWithReconduction).format('DD/MM/YYYY')}
                {
                  domiciliation.endOfCommitment &&
                  ' ' +
                  t('domiciliationCommitmentEndText', {
                    date: dayjs(domiciliation.endOfCommitment).format('DD MMMM YYYY')
                  })
                }
              </InfosListElementValue>
            </InfosListElement>
          </InfosList>
        </Panel>
      }

      {
        domiciliation.userMailSubscription &&
        <Panel>
          <PanelTitle>{t('userMailTitle')}</PanelTitle>
          <InfosList>
            <InfosListElement>
              <InfosListElementHeader>{t('option')}</InfosListElementHeader>
              <InfosListElementValue>
                {t(`${domiciliation.userMailSubscription.title}`)}
              </InfosListElementValue>
            </InfosListElement>
            <InfosListElement>
              <InfosListElementHeader>{t('optionPrice')}</InfosListElementHeader>
              <InfosListElementValue>
                {
                  domiciliation.userMailSubscription.amount !== 0 &&
                  <>
                    {domiciliation.userMailSubscription.amount} € HT&nbsp;
                    {t(`priceFrequencyShort.${domiciliation.userMailSubscription.paymentType}`)}
                  </>
                }

                {
                  domiciliation.userMailSubscription.amount === 0 &&
                  <>
                    {t('priceFrequencyShort.free')}
                  </>
                }
                <RightLink href={'https://sedomicilier.fr/tarifs#courrier'} target='_blank'>
                  {t('checkPrices')}
                </RightLink>
              </InfosListElementValue>
            </InfosListElement>
          </InfosList>
        </Panel>
      }


      <Panel>
        <PanelTitle>{t('cgvTitle')}</PanelTitle>
        <InfosList>
          <InfosListElement>
            <InfosListElementHeader className='cguBlock'>
              {t('cgvText')}
              <a href={Routing.LEGALS_TERMS} target='_blank' rel="noreferrer">
                {t('cgvLinkText')}
              </a>
            </InfosListElementHeader>
          </InfosListElement>
        </InfosList>
      </Panel>

      {!domiciliation.cancelationDate && (
        <DomiciliationButton>
          <Button
            bgColor='red'
            justifyContent='center'
            onClick={() => navigate(Routing.SUBSCRIPTION_TERMINATION)}
          >
            {t('terminate')}
          </Button>
        </DomiciliationButton>
      )}
    </DomiciliationPageWrapper>
  )
}

export const DomiciliationPageWrapper = styled.div`
  padding: 0 45px;
  position: relative;
`
export const DomiciliationPageTitle = styled.h2`
  color: ${Colors.black};
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 22px;
`
export const DomiciliationPageCard = styled.div`
  padding: 16px;
  width: 420px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${Colors.lightBlueBackground};
  margin-bottom: 48px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const DomiciliationButton = styled.div`
  width: 210px;
  position: absolute;
  top: -70px;
  right: 45px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
    position: initial;
  }
`

export default Information
