// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { notification, Form, UploadFile, Button } from 'antd'
import DocumentUploader from './DocumentUploader'

import { DOMICILIATION_QUERY } from 'src/graphql/Domiciliation'
import { FORMALITY_CAPITAL_DEPOSIT_UPLOAD } from 'src/graphql/LegalDocument'

import IGenericError from 'src/interfaces/GenericError'
import ILegalDocument from 'src/interfaces/LegalDocument'
import ILegalFormality from 'src/interfaces/LegalFormality'

import { UploadWrapper, H3 } from './styles'

const LegalDepositUpload = ({legalFormality} : { legalFormality: ILegalFormality }) => {
  const legalDocument: ILegalDocument | undefined = legalFormality.capitalDepositDocument
  const { t } = useTranslation()

  const [rectoFileList, setRectoFileList] = useState<UploadFile[]>([] as UploadFile[])
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [form] = Form.useForm()

  const [createOrUpdateLegalDocument] = useMutation(FORMALITY_CAPITAL_DEPOSIT_UPLOAD)

  useEffect(() => {
    legalDocument &&
      legalDocument.fileRecto &&
      !(legalDocument.state === 'in_error') &&
      addDocumentFileListItem(legalDocument.fileRecto, 'recto')
    setSubmitting(false)
  }, [legalDocument])

  const addDocumentFileListItem = (url: string, uid: string) => {
    let filename = url.substring(url.lastIndexOf('/')+1)
    filename = filename.split('?')[0]
    const fileItem: UploadFile = {
      uid: uid,
      name: filename,
      status: 'done',
      url: url,
    }

    const newList = [...rectoFileList]
    const existFile = rectoFileList.filter((file: UploadFile) => {
      return file.uid === uid
    })[0]

    if (existFile) {
      const index = rectoFileList.indexOf(existFile)
      newList.splice(index, 1)
    }
    newList.push(fileItem)

    setRectoFileList(newList)
  }

  const handleFinish = () => {
    setSubmitting(true)
    const vars = {
      id: legalFormality.id,
      legalDocumentId: legalDocument?.id,
      fileType: legalDocument.fileType,
      fileRecto: rectoFileList[0].originFileObj,
    }
    createOrUpdateLegalDocument({
      variables: vars,
      refetchQueries: [{ query: DOMICILIATION_QUERY }],
      awaitRefetchQueries: true,
    }).then(({ data }: { data: { error?: IGenericError } }) => {
      if (data.error) {
        notification.error({
          message: t('common:error'),
          description: data.error.message,
          duration: 5,
        })
      } else {
        notification.success({
          message: t('common:success'),
          description: t('profile:uploadModal.success'),
          duration: 5,
        })
      }
    }).catch(() => {
      notification.error({
        message: t('common:error'),
        description: '',
        duration: 5,
      })
    }).finally(() => {
      setSubmitting(false)
    })
  }

  if (legalDocument) {
    if (legalDocument.fileRecto && legalDocument.state === 'pending') {
      return (<H3 style={{ fontSize: '14px', fontWeight: '500px' }}>
        {t('legal_formality:card.capitalDeposit.title.pending')}
      </H3>)
    }
    if (legalDocument.state === 'validated') {
      return (<H3 style={{ fontSize: '14px', fontWeight: '500px' }}>
        {t('legal_formality:card.capitalDeposit.title.validated')}
      </H3>)
    }
  }

  return (
    <Translation ns={['legal_formality', 'common']}>
      {(t) => (
        <>
          {legalDocument &&
            legalDocument.state === 'in_error' &&
            <p style={{color: '#CD100C'}}>
              {t('legal_formality:card.capitalDeposit.title.error')}
            </p>
          }
          <UploadWrapper>
            <H3 style={{ fontSize: '14px', fontWeight: '500px', textAlign: 'center' }}>
              {t('legal_formality:card.capitalDeposit.title.upload')}
            </H3>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={{
                fileRecto: rectoFileList,
              }}
              autoComplete="off"
            >
              <Form.Item name={'fileRecto'}>
                <DocumentUploader
                  fileList={rectoFileList}
                  setFileList={setRectoFileList}
                  multiple={true}
                  showUploads={true}
                  handleBeforeChange={() => {
                    return false
                  }}
                />
              </Form.Item>

              {rectoFileList.length > 0 &&
                <Button
                  className={'submit'}
                  disabled={submitting}
                  type='primary'
                  onClick={() => handleFinish()}
                  size='large'
                >
                  Envoyer le document
                </Button>
              }
            </Form>
          </UploadWrapper>
        </>
      )}
    </Translation>
  )
}

export default LegalDepositUpload
