import { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import colors from 'src/components/UIKit/colors'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const CivilStatus = ({ leaderContact }) => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)

  const getFullName = (lastname, firstname) => {
    let dynamicFullName = ''
    if (lastname) {
      dynamicFullName = `${lastname}`
    }
    if (firstname) {
      if (lastname) {
        dynamicFullName += ` ${firstname}`
      } else {
        dynamicFullName += `${firstname}`
      }
    }
    if (!firstname && !lastname) {
      dynamicFullName = t('common:missing')
    }
    return dynamicFullName
  }

  const getHostPlaceLabel = () => {
    if (!domiciliation.hostedAnswer) {
      return t('common:missing')
    }

    if (domiciliation.hoster) {
      return `Hébergé par ${
        getFullName(
            domiciliation.hoster.contactDetail.lastname,
            domiciliation.hoster.contactDetail.firstname
        )
      }`
    } else {
      return 'Au domicile personnel'
    }
  }

  return (
    <CivilStatusWrapper>
      <CivilStatusLine>
        <CivilStatusInquiry>{t('procedure:leader.legalRepresentative')}</CivilStatusInquiry>
        <CivilStatusInformation
          missing={getFullName(leaderContact.lastname, leaderContact.firstname) === t('common:missing')}
        >
          {getFullName(leaderContact.lastname, leaderContact.firstname)}
        </CivilStatusInformation>
      </CivilStatusLine>

      <CivilStatusLine>
        <CivilStatusInquiry>{t('procedure:leader.lodging')}</CivilStatusInquiry>
        <CivilStatusInformation
          missing={!domiciliation.hostedAnswer}
        >
          {getHostPlaceLabel()}
        </CivilStatusInformation>
      </CivilStatusLine>
    </CivilStatusWrapper>
  )
}

export const CivilStatusWrapper = styled.div``
export const CivilStatusLine = styled.div`
  display: flex;
  gap: 16px;

  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter';
  line-height: 150%;
`
export const CivilStatusInquiry = styled.div`
  color: ${colors.grey};
`
export const CivilStatusInformation = styled.div`
  color: ${(props) => (props.missing ? `${colors.orange}` : `${colors.black}`)};
`

export default CivilStatus
