import gql from 'graphql-tag'
import { ROOM_FRAGMENT, APPOINTMENT_FRAGMENT_MAXI, CGV_ACCEPTANCE_FRAGMENT } from './Fragments'

export const ROOM_CITIES = gql`
    query RoomCity {
        roomCities
    }
`


export const ROOM_MEETINGS_QUERY = gql`
    query RoomMeetings(
        $per: Int,
        $page: Int,
        $city: String,
        $capacity: Int
        $startingAt: ISO8601DateTime
        $endingAt: ISO8601DateTime
    ){
        roomMeetings(
            per: $per,
            page: $page,
            city: $city,
            capacity: $capacity
            startingAt: $startingAt
            endingAt: $endingAt
        ) {
            ...RoomFragment
            currentCgvAcceptance {
                ...CgvAcceptanceFragment
            }
        }
    }
    ${ROOM_FRAGMENT}
    ${CGV_ACCEPTANCE_FRAGMENT}
`

export const ROOM_BOOKING_CREATE = gql`
    mutation RoomBookingCreate($roomId: ID!, $startingAt: ISO8601DateTime!, $endingAt: ISO8601DateTime!) {
        roomBookingCreate(roomId: $roomId, startingAt: $startingAt, endingAt: $endingAt) {
            appointment {
                ...AppointmentFragmentMaxi
            }
        }
    }
    ${APPOINTMENT_FRAGMENT_MAXI}
`

export const ROOM_BOOKING_CONFIRM = gql`
    mutation RoomBookingConfirm($appointmentId: ID!, $cgvUuid: String) {
        roomBookingConfirm(appointmentId: $appointmentId, cgvUuid: $cgvUuid) {
          success
        }
    }
`

export const ROOM_BOOKING_ABORT = gql`
    mutation RoomBookingAbort($appointmentId: ID!) {
        roomBookingAbort(appointmentId: $appointmentId) {
          success
        }
    }
`
