import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tile } from './styles'
import IProduct from 'src/interfaces/Product'
import { Highlighted, AddProduct } from 'src/components/UIKit'
import ProductIcon from 'src/components/shared/ProductIcon'

interface IProps {
  legalFormality: IProduct
  setLegalFormalityTokenModal: (value: string) => void
  tileWidth: number
}

const Item = (props: IProps) => {
  const { t } = useTranslation()
  const { legalFormality, setLegalFormalityTokenModal, tileWidth } = props

  const myFormality = legalFormality.identificationToken
  const price = (legalFormality.price + legalFormality.legalFees) * 0.01 + '€'

  return (
    <Tile tileWidth={tileWidth}>
      <div className="tile-left">
        <ProductIcon identificationToken={legalFormality.identificationToken} />
        <div>
          <h4>{t(`products:${myFormality}.title`)}</h4>
          <a className="informations" href="javascript:;" onClick={() => {
            setLegalFormalityTokenModal(legalFormality.identificationToken)
          }}>
            {t(`products:${myFormality}.learn_more`)}
          </a>
        </div>
      </div>
      <div className="tile-right">
        <Highlighted>{price}</Highlighted>
        <AddProduct product={legalFormality} />
      </div>
    </Tile>
  )
}

export default Item
