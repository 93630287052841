import React from 'react'
import ChartIcon from '@/images/chart.svg'
import { Progress, Spin } from 'antd'

interface IProps {
  referralInformations: any
}

const InformationDetails = (props: IProps) => {
  const { referralInformations } = props

  const progressPercent = (refereeCount: number | undefined) => {
    if (!refereeCount) {
      return (0)
    } else if (refereeCount > 5) {
      return (100)
    } else {
      return (refereeCount * 20)
    }
  }

  return (
    <div className="referrer_overview">
      <h2 className='mb-2'>
        <img className='inline' src={ChartIcon} />
        Vue d&apos;ensemble
      </h2>
      <div className="blocs">
        <div className="bloc">
          <div className="bloc-title">
            Profil actuel
          </div>
          <div className="bloc-content">
            Parrainage {referralInformations ? (referralInformations.referrerCurrentReduction / 100) : <Spin /> }
          </div>
        </div>
        <div className="bloc bloc-double">
          <div className="bloc-title">
            Parrainage 100
          </div>
          <div className="bloc-content">
            {
              referralInformations ?
                (referralInformations.confirmedCount > 5 ? 5 : referralInformations.confirmedCount) :
              <Spin />
            }/5
            <Progress
              percent={progressPercent(referralInformations && referralInformations.confirmedCount) }
              showInfo={false}
              trailColor={'#FFE4ED'}
              strokeColor={'#FB82A9'}
            />
          </div>
        </div>
        <div className="bloc">
          <div className="bloc-title">
            Récompense totale
          </div>
          <div className="bloc-content">
            {referralInformations ? (referralInformations.totalReductionAcquired / 100) : <Spin /> }€
          </div>
        </div>
        <div className="bloc">
          <div className="bloc-title">
            Récompense disponible
          </div>
          <div className="bloc-content">
            {referralInformations ? (referralInformations.balance / 100) : <Spin /> }€
          </div>
        </div>
        <div className="bloc">
          <div className="bloc-title">
            Parrainages réussis
          </div>
          <div className="bloc-content">
            {referralInformations ? referralInformations.confirmedCount : <Spin /> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default InformationDetails
