import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicButton, CustomIcon, LegalflowSection, NoticeInformation } from '@sedomicilier/material-sd'
import ExtraServices from '@/app/LegalFlow/Partner/extraServices'
import { useMutation } from '@apollo/client'
import { UPDATE_SERVICE_INTEREST } from '@/graphql/mutations/updateServiceInterest'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'
import { nextPage } from '@/reducers/workflow'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { ContactContext } from '@/contexts/contactContext'

const Partner: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const [updateServiceInterest] = useMutation(UPDATE_SERVICE_INTEREST)
  const [loading, setLoading] = useState<boolean>(false)

  const initialValues = {
    bank: !!domiciliation.serviceInterests?.bank,
    accountant: !!domiciliation.serviceInterests?.accountant
  }

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      updateServiceInterest({
        variables: {
          bankInterest: values.bank,
          accountantInterest: values.accountant
        }
      }).then(() => dispatch(nextPage()))
    }
  }
  return (
    <LegalflowSection
      title={t('partner:title', {
        firstname: contact.personalInformation.leaderContactDetail.firstname
      })}
    >
      <Form onFinish={onFinish} initialValues={initialValues}>
        <ExtraServices />
        <NoticeInformation
          bgColor='grey'
          icon={<CustomIcon icon={<span className={'ico-star-full text-l text-interaction'} />} />}
          className='body-2 my-4'
        >
          {t('partner:noticeInformation')}
        </NoticeInformation>
        <BasicButton
          testid='partner-next-button'
          label={t('common:continue')}
          loading={loading}
          bgColor='blue'
          className='body-1 font-semibold w-full justify-center'
        />
      </Form>
    </LegalflowSection>
  )
}

export default Partner
