import { ContactContext } from 'src/contexts/contactContext'
import session from 'src/session'
import CountryContext from 'src/contexts/countryContext'
import { Button, Divider, Dropdown, Space, notification } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuProps } from 'antd/lib'
import { useMutation, useQuery } from '@apollo/client'
import { CONTACTS_QUERY, CONTACT_SELECT_MUTATION } from '@/graphql/Contact'
import { CheckOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@sedomicilier/pro-solid-svg-icons'

const DropdownContact = () => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)
  const { countryCode } = useContext(CountryContext)
  const { data, loading, error } = useQuery(CONTACTS_QUERY)
  const [selectContact] = useMutation(CONTACT_SELECT_MUTATION)

  const handleDisconnect = () => {
    session.clearSession()
    const baseUrl = process.env['REACT_APP_HOME_LINK_' + countryCode]
    window.location.href = `${baseUrl}/sessions/deconnexion`
  }

  const switchContact = async (contactId: number) => {
    try {
      const { data } = await selectContact({ variables: { contactId: contactId } })
      if (data.error) {
        notification.error({
          message: t('common:error'),
          description: data.error.message,
          duration: 5
        })
      } else if (data.contactSelect?.token) {
        session.refreshSession(data.contactSelect.token)
        window.location.reload()
      }
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('common:error'),
        duration: 5
      })
    }
  }

  const items = (
    data?.contacts
        ?.map((listedContact: any) => {
          return {
            label: `${listedContact?.society.name ?? 'Mon compte'} ${contact.domiciliationStatus &&
            t(`nav:accounts.${listedContact.domiciliationStatus}`)}`,
            key: listedContact?.society.id ?? 0,
            icon: listedContact.id === contact.id ? <CheckOutlined /> : '',
            onClick: () => {
              switchContact(listedContact.id)
            }
          }
        }) as MenuProps['items']
  )
  const menuProps = {
    items
  }

  if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  } else {
    return (
      <Dropdown
        menu={menuProps}
        className='dropdown-contact'
        overlayStyle={{ background: 'white', boxShadow: '0 5px 20px 0 #0000001a', borderRadius: '8px' }}
        dropdownRender={(menu) => (
          <div>
            {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none', background: 'none' } })}
            <Divider style={{ margin: 0 }} />
            <Space style={{ padding: 8 }}>
              <Button className='primary-button' type="primary" onClick={handleDisconnect}>Déconnexion</Button>
            </Space>
          </div>
        )}
      >
        <Button style={{ background: '#C7DFFA', color: '#1576E1' }} loading={loading}>
          <Space>
            {loading ? 'Mon compte' : contact.society.name}
            <FontAwesomeIcon icon={faAngleDown} size="sm" className="arrow-down" />
          </Space>
        </Button>
      </Dropdown>
    )
  }
}

export default DropdownContact
