import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Input, Button } from 'antd'
import { useMutation } from '@apollo/client'
import { USER_INVITE_MUTATION } from 'src/graphql/User'
import { openValidationPopup, openErrorPopup } from 'src/components/UIKit'
import RoleRadioList from './RoleRadioList'
import { AbilityContext } from 'src/contexts/abilityContext'

interface IProps {
  openModal: boolean
  setOpenModal: any
  refetch: any
}

const InvitationModal = (props: IProps) => {
  const { t } = useTranslation()
  const [validationError, setValidationError] = useState<boolean>(false)

  const [
    userInvite,
    { loading: userInviteLoading },
  ] = useMutation(USER_INVITE_MUTATION)

  const ability: any = useContext(AbilityContext)
  const disabled = ability.cannot('customer_manage', 'User')

  const formik = useFormik({
    initialValues: {
      email: '',
      roleId: undefined,
    },
    validateOnChange: validationError,
    validateOnBlur: validationError,
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t('common:detailed_errors.invalid', { field: t('common:emailAddress') })).
          required(t('common:detailed_errors.required-f', { field: t('common:emailAddress') })),
      roleId: Yup.number().required(t('common:detailed_errors.required', { field: t('common:role') })),
    }),
    onSubmit: (values, { resetForm }) => {
      userInvite({
        variables: { ...values },
      })
          .then(() => {
            resetForm()
            openValidationPopup(
                t('team:invitation_modal.popup_success_title'),
                t('team:invitation_modal.popup_success_description', { email: values.email }),
            )
            props.refetch()
            props.setOpenModal(false)
          })
          .catch((e) => openErrorPopup(t('team:invitation_modal.popup_error_title'), e.message))
    },
  })

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setValidationError(true)
    }
  }, [formik.errors])

  return (
    <Modal
      centered={true}
      open={props.openModal}
      onCancel={() => props.setOpenModal(false)}
      wrapClassName="team-modal"
      title={t('team:invitation_modal.title')}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formik.handleSubmit()}
          disabled={disabled || userInviteLoading}
          loading={userInviteLoading}
          style={{ background: '#1576E1', borderRadius: '4px' }}
        >
          {t('team:invitation_modal.button')}
        </Button>,
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="team-label">{t('team:invitation_modal.email_input.label')}</div>
        <Input
          id="email"
          name="email"
          placeholder={t('team:invitation_modal.email_input.placeholder')}
          onChange={formik.handleChange}
          value={formik.values.email}
          disabled={disabled}
        />
        {formik.errors.email && (
          <div className="error-input">{formik.errors.email}</div>
        )}
        <RoleRadioWrapper>
          <RoleRadioList formik={formik} disabled={disabled} />
          {formik.errors.roleId && (
            <div className="error-input">{formik.errors.roleId}</div>
          )}
        </RoleRadioWrapper>
      </form>
    </Modal>
  )
}
const RoleRadioWrapper = styled.div`
  margin-top: 30px;
`

export default InvitationModal

