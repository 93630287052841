import React from 'react'
import { useTranslation } from 'react-i18next'
import IUserMail from 'src/interfaces/UserMail'
import styled from 'styled-components'
import moment from 'moment'
import lightning from 'src/images/lightning-reexpedition.svg'
import NpaiModal from './NpaiModal'

interface IMailInformationStatusProps {
  mail: IUserMail
}

const MailInformationStatus: React.FC<IMailInformationStatusProps> = ({ mail }) => {
  const { t } = useTranslation()
  const getStatus = () => {
    if (mail.mailType === 'Courrier') {
      return `${t('userMails:status.' + mail.processingType)}`
    }
    return `${mail.mailType} ${t('userMails:status.' + mail.processingType).toLowerCase()}`
  }

  return (
    <MailInformationStatusWrapper>
      <MainStatusWrapper>
        <MainStatusTitle>{getStatus()}</MainStatusTitle>
        {mail.tag && <MainStatusTag bgColor={mail.tag?.color}>{mail.tag?.name}</MainStatusTag>}
      </MainStatusWrapper>
      <MailInformation>
        <MailId>N°{mail.id}</MailId> &#8226; {t('userMails:statusDetails.' + mail.processingDetailType)}{' '}
        {moment(mail.processingDate).locale('fr').format('D MMMM')}
        {['npai', 'npai_standby'].includes(mail.processingType) && <NpaiModal />}
        {mail.hasUserRequest && <img src={lightning} alt="courrier réexpedition express" />}
      </MailInformation>
    </MailInformationStatusWrapper>
  )
}

export const MailInformationStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const MainStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const MainStatusTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #171a1c;
`
export const MainStatusTag = styled.span`
  color: #ffffff;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 11px;
  line-height: 16px;
`
export const MailInformation = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #7b7e80;
`
export const MailId = styled.span`
  font-weight: 600;
  color: #6c767f;
`

export default MailInformationStatus
