import styled from 'styled-components'
import colors from 'src/components/UIKit/colors'

interface ButtonProps {
  children: React.ReactNode
  width?: string
  height?: string
  justifyContent?: string
  bgColor?: 'orange' | 'white' | 'black' | 'blue' | 'red'
  textColor?: 'blue' | 'black' | 'white'
  type?: 'button' | 'a'
  onClick?: () => void
  href?: string
  className?: string
  buttonStyle?: boolean
  target?: string
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = (props) => {
  const { bgColor, type, onClick, href } = props

  const bgColorSetter = () => {
    const bgColorToSet = ''
    const bgHoverColor = ''
    const bg = { bgColorToSet, bgHoverColor }
    switch (bgColor) {
      case 'orange':
        bg.bgColorToSet = colors.orange
        bg.bgHoverColor = colors.orangeHover
        break
      case 'black':
        bg.bgColorToSet = colors.black
        bg.bgHoverColor = colors.blackHover
        break
      case 'blue':
        bg.bgColorToSet = colors.blue
        bg.bgHoverColor = colors.darkBlue
        break
      case 'red':
        bg.bgColorToSet = colors.red
        bg.bgHoverColor = colors.redHover
        break
      default:
        bg.bgColorToSet = colors.white
        bg.bgHoverColor = colors.whiteHover
    }
    return bg
  }

  if (type === 'a') {
    return (
      <Hyperlink
        {...props}
        onClick={onClick}
        href={href}
        className={props.className}
        bgColorSetter={bgColorSetter()}
        disabled={props.disabled}
      >
        {props.children}
      </Hyperlink>
    )
  } else {
    return (
      <ButtonWithBorder {...props} bgColorSetter={bgColorSetter()} onClick={onClick} className={props.className}>
        {props.children}
      </ButtonWithBorder>
    )
  }
}

export const ButtonWithBorder = styled.button`
  display: flex;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: center;

  height: ${(props) => (props.height ? props.height : '40px')};
  width: ${(props) => (props.width ? props.width : '100%')};

  border: ${(props) => (props.bgColor === 'white' ? `1px solid ${colors.blue}` : 'none')};
  border-radius: 8px;
  background-color: ${(props) => props.bgColorSetter?.bgColorToSet};
  padding: 11px 18px;

  color: ${(props) => (props.bgColor === 'white' ? `${colors.blue}` : `${colors.white}`)};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.bgColorSetter?.bgHoverColor};
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
`
export const Hyperlink = styled.a`
  color: ${(props) => (props.textColor === 'blue' ? `${colors.blue}` : `${colors.black}`)} !important;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }

  ${(props) =>
    props.buttonStyle &&
    `
    display: flex;
    justify-content: ${props.justifyContent ? props.justifyContent : 'space-between'};
    align-items: center;

    height: ${props.height ? props.height : '40px'};
    width: ${props.width ? props.width : '100%'};

    border: ${props.bgColor === 'white' ? `1px solid ${colors.blue}` : 'none'};
    border-radius: 8px;
    text-decoration: none !important;
    background-color: ${props.bgColorSetter?.bgColorToSet};
    padding: 11px 18px;

    color: ${props.bgColor === 'white' ? `${colors.blue}` : `${colors.white}`} !important;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background-color: ${props.bgColorSetter?.bgHoverColor} !important;
    }
  `}
  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed !important;
      opacity: 0.5;
    `}
`

export default Button
