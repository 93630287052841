import React from 'react'
import { NavLink } from 'react-router-dom'

interface IProps {
  to: string
  className?: string
  authorized: boolean
  children?: React.ReactNode
  onClick?: any
}

const ProtectedLink = (props: IProps) => {
  const { authorized, children, ...navLinkProps } = props

  if (authorized) {
    return <NavLink {...navLinkProps}>{children}</NavLink>
  } else {
    return <div className="disabled-nav-link">{children}</div>
  }
}

export default ProtectedLink
