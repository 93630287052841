import { Drawer, Form, Input } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import profile from 'src/images/profile/profile-blue.svg'
import colors from 'src/components/UIKit/colors'
import LeaderResidence from './leaderResidence'
import { useState, useContext } from 'react'
import CountrySelect from 'src/components/countries/countrySelect'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { useMutation } from '@apollo/client'
import { DOMICILIATION_HOSTER_UPDATE } from 'src/graphql/Hoster'
import { ContactContext } from 'src/contexts/contactContext'

const LeaderInformationDrawer = ({ onClose, open, contactName }) => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { contact }: any = useContext(ContactContext)

  const [form] = Form.useForm()
  const [domiciliationHosterUpdate] = useMutation(DOMICILIATION_HOSTER_UPDATE)

  const [selectedResidence, setSelectedResidence] = useState(domiciliation.hoster ? 'hosted' : 'personalHome')

  const onFinish = (values) => {
    domiciliationHosterUpdate({
      variables: {
        hosted: hosted,
        firstname: values.firstname,
        lastname: values.lastname,
        nationality: values.nationality,
        country: values.country
      }
    }).then(async (res) => {
      await res
      onClose()
    })
  }

  const hosted = selectedResidence === 'hosted'
  let initialValues: any = undefined

  if (domiciliation.hoster) {
    initialValues = {
      nationality: domiciliation.hoster.contactDetail.nationality,
      firstname: domiciliation.hoster.contactDetail.firstname,
      lastname: domiciliation.hoster.contactDetail.lastname,
      country: domiciliation.hoster.addressDetail.country
    }
  } else {
    initialValues = {
      country: contact.personalInformation.leaderAddressDetail.country
    }
  }

  return (
    domiciliation &&
    initialValues && (
      <Drawer
        title={t('common:informations')}
        placement='right'
        onClose={onClose}
        open={open}
        zIndex={1000}
        width={400}
      >
        <LeaderName>
          <ProfileIcon src={profile} alt='profile icone' />
          {contactName.lastname} {contactName.firstname}
        </LeaderName>
        <LeaderResidence selectedResidence={selectedResidence} setSelectedResidence={setSelectedResidence} />
        <Form layout='vertical' requiredMark={false} onFinish={onFinish} form={form} initialValues={initialValues}>
          {hosted && (
            <>
              <Form.Item
                name='lastname'
                label={t('procedure:hostInformation.lastname')}
                rules={[
                  {
                    required: true,
                    message: t('procedure:rules.hostInformation.lastname')
                  }
                ]}
              >
                <Input placeholder='Saisissez le nom' />
              </Form.Item>

              <Form.Item
                name='firstname'
                label={t('procedure:hostInformation.firstname')}
                rules={[
                  {
                    required: true,
                    message: t('procedure:rules.hostInformation.firstname')
                  }
                ]}
              >
                <Input placeholder='Saisissez le prénom' />
              </Form.Item>

              <Form.Item
                name='nationality'
                label={t('procedure:hostInformation.nationality')}
                rules={[
                  {
                    required: true,
                    message: t('procedure:rules.hostInformation.nationality')
                  }
                ]}
              >
                <CountrySelect
                  defaultValue={initialValues.nationality}
                  onSelect={(value) => form.setFieldsValue({ nationality: value })}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name='country'
            label={`${hosted ? 'Pays de résidence de l\'hébergeur' : 'Pays de résidence du dirigeant'}`}
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner votre pays de résidence'
              }
            ]}
          >
            <CountrySelect
              defaultValue={initialValues.country}
              onSelect={(value) => form.setFieldsValue({ country: value })}
            />
          </Form.Item>

          <Form.Item>
            <Button bgColor='blue' justifyContent='center'>
              Valider les informations
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    )
  )
}

export const LeaderName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  color: ${colors.grey};
  margin-bottom: 32px;
`
export const ProfileIcon = styled.img`
  margin: 0;
`

export default LeaderInformationDrawer
