import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import IUserMail from 'src/interfaces/UserMail'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@sedomicilier/pro-solid-svg-icons'
import MailTypeIcon from '../MailTypeIcon'
import MailInformationStatus from './MailInformationStatus'
import { UserMailContext } from '../../contexts/UserMailContext'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { useMutation } from '@apollo/client'
import { USER_MAIL_VIEW_MUTATION } from 'src/graphql/UserMail'
import { NotificationContext } from 'src/contexts/notificationContext'
import MailCardDownload from './MailCardDownload'

interface IMailCardProps {
  mail: IUserMail
}

const MailCard: React.FC<IMailCardProps> = ({ mail }) => {
  const { refetch: refetchNotifications }: any = useContext(NotificationContext)

  const { setSelectedMail, selectedMail }: any = useContext(UserMailContext)
  const { width } = useWindowDimensions()
  const [mailViewed] = useMutation(USER_MAIL_VIEW_MUTATION, {
    variables: {
      userMailId: mail.id,
    },
  })
  const mailClick = () => {
    setSelectedMail(mail)
    if (!viewed) {
      mailViewed()
      setTimeout(() => {
        refetchNotifications()
      }, 1000)
      setViewed(true)
    }
  }

  const [viewed, setViewed] = useState(mail.viewed)

  return (
    <MailCardWrapper onClick={mailClick} viewed={viewed || mail.viewed} selected={selectedMail?.id === mail.id}>
      <MailTypeIcon mailType={mail.mailType} />
      <MailState>
        <MailInformationStatus mail={mail} />
      </MailState>
      {width >= 900 && (
        <>
          <div className='mr-3'>
            <MailCardDownload mail={mail} />
          </div>
          <MailReceiptDate>{moment(mail.dateOfReceipt).format('DD/MM/YY')}</MailReceiptDate>
        </>
      )}
      <FontAwesomeIcon icon={faChevronRight} />
    </MailCardWrapper>
  )
}

export const MailCardWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.viewed ? '#fff' : '#E7F2FF')};
  margin-bottom: 8px;
  border: 1px solid #F1F1F1;
  ${(props) =>
    props.selected &&
    `
    border: 1px solid #1576E1;
  `}
  &:hover {
    cursor: pointer;
    border: 1px solid #1576E1;
  }
  p {
    margin-bottom: 0;
  }
`
export const MailState = styled.div`
  flex: 1;
`
export const MailInformationState = styled.div`
  color: #171a1c;
  font-size: 14px;
  font-weight: 600;
`
export const MailReceiptDate = styled.div`
  margin-right: 16px;
  font-size: 10px;
  color: #7b7e80;
`

export default MailCard
