import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import { ROOM_BOOKING_CREATE, ROOM_BOOKING_CONFIRM, ROOM_BOOKING_ABORT } from '@/graphql/RoomMeeting'
import { DOMICILIATION_QUERY } from '@/graphql/Domiciliation'
import { CURRENT_PAYMENT_SOURCE } from '@/graphql/PaymentSource'
import { CommonLoader, SubmitLoader } from 'src/components/shared/Loader'
import IRoom from '@/interfaces/Room'
import { useNavigate } from 'react-router-dom'
import { WithTranslation, useTranslation } from 'react-i18next'
import Routing from '@/CustomerArea/Routing'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import useAlert from 'src/hooks/useAlert'
import { Checkbox } from 'antd'
import type { CheckboxProps } from 'antd'

interface IProps extends WithTranslation {
  alert: any
  allFilled: boolean
  fullScreen: boolean
  selected?: IRoom
}

const ConfirmBook = (props: IProps) => {
  const { t } = useTranslation()
  const { refetch }: any = useContext(DomiciliationContext)
  const navigate = useNavigate()
  const { setAlert } = useAlert()

  const [processing, setProcessing] = useState(false)
  const { loading: currentPaymentSourceLoading, data: currentPaymentSourceData } = useQuery(CURRENT_PAYMENT_SOURCE)
  const [cgvAccepted, setCgvAccepted] = useState(false)
  const roomCgv = props.selected?.currentCgvAcceptance

  const stripe: any = useStripe()
  const [roomBooking, {
    data: roomBookingData,
  }] = useMutation(ROOM_BOOKING_CREATE)

  const [roomBookingConfirm] = useMutation(ROOM_BOOKING_CONFIRM)
  const [roomBookingAbort] = useMutation(ROOM_BOOKING_ABORT)

  useEffect(() => {
    if (roomBookingData) {
      if (roomBookingData.error) {
        displayError(roomBookingData.error.message)
      } else {
        const piSecret = roomBookingData.roomBookingCreate.appointment.paymentIntent.paymentIntentSecret
        confirmPaymentIntent(piSecret).then((result: any) => {
          result.error ? failure(result.error.message) : success()
        })
      }
    }
  }, [roomBookingData])

  const callRoomBooking = () => {
    setProcessing(true)

    roomBooking({ variables: {
      roomId: props.selected?.id,
      startingAt: props.selected?.selectedStartAt,
      endingAt: props.selected?.selectedEndAt,
    }, refetchQueries: ['RoomCity', 'RoomMeetings', { query: DOMICILIATION_QUERY }] }).catch(() => {
      setProcessing(false)
    })
  }

  const confirmPaymentIntent = async (paymentIntentSecret: string) => {
    const paymentMode = currentPaymentSourceData!.currentPaymentSource!.sourceType
    const confirmationRequest = paymentMode === 'card' ?
      stripe.confirmCardPayment(paymentIntentSecret, confirmPaymentParameters()) :
      stripe.confirmSepaDebitPayment(paymentIntentSecret, confirmPaymentParameters())

    return confirmationRequest
  }

  const confirmPaymentParameters = () => {
    if (currentPaymentSourceData.currentPaymentSource.token.split('_')[0] === 'src') {
      return { source: currentPaymentSourceData.currentPaymentSource.token }
    } else if (currentPaymentSourceData.currentPaymentSource.token.split('_')[0] === 'card') {
      return { payment_method: currentPaymentSourceData.currentPaymentSource.token }
    } else {
      return {}
    }
  }

  const success = () => {
    roomBookingConfirm({
      variables: {
        appointmentId: roomBookingData.roomBookingCreate.appointment.id,
        cgvUuid: roomCgv?.uuid
      } }).then(() => {
      refetch()
      navigate(Routing.SERVICES_ROOM_MEETINGS_INDEX)
      setAlert({ type: 'success', text: t('services:bookings:confirm'), size: 'large'})
    })
  }

  const displayError = (errorMessage: string) => {
    setAlert({ type: 'error', text: `Erreur lors de la réservation: ${errorMessage}`, size: 'large'})
  }

  const failure = (errorMessage: string) => {
    roomBookingAbort({
      variables: { appointmentId: roomBookingData.roomBookingCreate.appointment.id,
      } }).then(() => {
      displayError(errorMessage)
    })
  }

  const triggerCgvAccepted: CheckboxProps['onChange'] = (e) => {
    setCgvAccepted(e.target.checked)
  }

  if (!props.selected) {
    return (null)
  }
  if (currentPaymentSourceLoading) {
    return (<CommonLoader />)
  }

  return (
    <>
      { roomCgv?.url && (
        <div style={{ marginBottom: '30px' }}>
          <Checkbox
            checked={cgvAccepted}
            onChange={triggerCgvAccepted}
          >
            <span>
              {t('services:bookings:cgv_acceptance:checkbox_text')}
              <a
                href={roomCgv?.url}
                target={'_blank'} rel="noreferrer"
              >
                {t('services:bookings:cgv_acceptance:link_label')}
              </a>
            </span>
          </Checkbox>
        </div>
      )}

      <button className="submit section-button" disabled={processing || (roomCgv?.url && !cgvAccepted)}
        onClick={callRoomBooking}>
        <SubmitLoader isSubmitting={ processing } />
        <span>{t('services:bookings:confirm_meeting_room')}</span>
      </button>
    </>
  )
}

export default ConfirmBook
