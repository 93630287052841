import WorkflowNavigation from './layout/workflowNavigation'

const Layout = ({
  children
}: {
  children: React.ReactNode
}) => {
  return (
    <WorkflowNavigation>
      {children}
    </WorkflowNavigation>
  )
}

export default Layout
