import styled from 'styled-components'
import Stylesheet from './stylesheet'
import Colors from './colors'
import Constants from './constants'

export const Page = styled(Stylesheet)`
  padding: ${(props) => props.padding ? props.padding : '0 45px' };
  background: ${(props) => props.background ? props.background : Colors.white };

   @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
     padding: ${(props) => props.mobilePadding ? props.mobilePadding : '0 15px' };
   }
`

export const Centred = styled.div`
  height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
