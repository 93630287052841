import React from 'react'
import styled from 'styled-components'

interface IProps {
  emailMd5: string
  name: string
  size: number
}

const Avatar = (props: IProps) => {
  const getColorAndBackground = (md5: string) => {
    const matches = md5.match(/.{2}/g)!

    const [red, green, blue] = matches.map((hex) => parseInt(hex, 16))

    // Formula from https://www.w3.org/TR/AERT/#color-contrast
    const luminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255

    const userColor = luminance > 0.6 ? '#222' : '#fff'

    return {
      background: `rgb(${[red, green, blue]})`,
      color: userColor,
    }
  }

  const getInitials = (name: string) => {
    name = name.trim()

    if (name.length <= 3) {
      return (name)
    }

    return name
        .split(/\s+/)
        .map((w) => [...w][0])
        .slice(0, 3)
        .join('')
  }

  const url = `https://www.gravatar.com/avatar/${props.emailMd5}?s=${String(
      Math.max(props.size, 250),
  )}&d=blank`

  const initials = getInitials(props.name)
  const { background, color } = getColorAndBackground(props.emailMd5)

  return (
    <Wrapper color={color} background={background} size={props.size}>
      <FallbackImage aria-hidden='true' size={props.size} initials={initials}>
        {initials}
      </FallbackImage>
      <GravatarImage src={String(url)} alt={'avatar'} size={props.size} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
`

const FallbackImage = styled.div`
  font-size: ${(props) => props.size / (1.4 * Math.max([...props.initials].length, 2))};
  position: absolute;
  font-family: sans-serif;
  user-select: none;
`

const GravatarImage = styled.img`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  top: 0;
  left: 0;
  border-radius: 50%;
`

export default Avatar
