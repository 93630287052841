import React, { useContext } from 'react'
import { Button, Form, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Routing from '@/CustomerArea/Routing'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import TerminationSubscriptionKind from './kind'

interface IProps {
  loading: boolean
  setValues: any
}

const TerminationSubscription = (props: IProps) => {
  const { loading, setValues } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { domiciliation }: any = useContext(DomiciliationContext)
  const conform = domiciliation.conform

  const [form] = Form.useForm<{ kind: string; reason: string; finalized: boolean; address: string; }>()
  const kindValue = Form.useWatch('kind', form)
  const reasonValue = Form.useWatch('reason', form)

  const kindsValues = () : string[] => {
    if (domiciliation.hasSigned) {
      return ['transfert', 'stop', 'unsatisfied']
    }

    return ['stop']
  }

  const onFinish = (formValues: any) => {
    setValues({ ...formValues, ...{ conform }})

    if (formValues.kind === 'transfert' || formValues.kind === 'unsatisfied') {
      navigate(Routing.SUBSCRIPTION_TERMINATION_ENDING_DATE)
    } else if (formValues.kind === 'stop') {
      if (formValues.reason === 'external_project_dropped') {
        navigate(Routing.SUBSCRIPTION_TERMINATION_CONFIRMATION)
      } else if (formValues.reason === 'external_enterprise_closed') {
        navigate(Routing.SUBSCRIPTION_TERMINATION_ENDING_DATE)
      }
    }
  }

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        label={t('cancelationProcedure:flow.form.fields.kind.label')}
        rules={[{ required: true, message: t('cancelationProcedure:flow.form.fields.kind.errors.required_field') }]}
        name="kind"
        data-test="subscription-resiliate-kind-field"
      >
        <Select
          id="kind"
        >
          {
            kindsValues().map( (value) => {
              return (
                <Select.Option value={value} key={`kind-${value}`}>
                  {t(`cancelationProcedure:flow.form.fields.kind.options.${value}`, '')}
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      {
        kindValue && (
          <TerminationSubscriptionKind
            conform={conform}
            kind={kindValue}
            hasSigned={domiciliation.hasSigned}
            form={form}
          />
        )
      }

      <Form.Item className="form-item-submit flex-right">
        <Button type="primary" htmlType="submit" disabled={loading}>
          {
            reasonValue === 'external_project_dropped' &&
            <>{t('cancelationProcedure:flow.form.submit.confirm')}</>
          }
          {
            reasonValue !== 'external_project_dropped' &&
            <>{t('cancelationProcedure:flow.form.submit.continue')}</>
          }
        </Button>
      </Form.Item>
    </Form>
  )
}

export default TerminationSubscription
