export interface ModuleType {
  name: string
  path: string
  nextPage: (option?: string) => ModuleType | undefined
}

const Accounting: ModuleType = {
  name: 'accounting',
  path: '/legal-flow/accounting',
  nextPage: (option) => (option === 'hidedomideal' ? Summary : Partner)
}

const Capital: ModuleType = {
  name: 'capital',
  path: '/legal-flow/capital',
  nextPage: () => Accounting
}

const CompanyActivity: ModuleType = {
  name: 'company_activity',
  path: '/legal-flow/company-activity',
  nextPage: (option) => (option === 'micro' ? Accounting : Capital)
}

const ForwardingAddress: ModuleType = {
  name: 'forwarding_address',
  path: '/legal-flow/forwarding-address',
  nextPage: () => LegalRepresentative
}

const LegalRepresentative: ModuleType = {
  name: 'legal_representative',
  path: '/legal-flow/legal-representative',
  nextPage: () => CompanyActivity
}

const Onboarding: ModuleType = {
  name: 'onboarding',
  path: '/legal-flow/onboarding',
  nextPage: () => ForwardingAddress
}

const Partner: ModuleType = {
  name: 'partner',
  path: '/legal-flow/partner',
  nextPage: () => Summary
}

const Summary: ModuleType = {
  name: 'summary',
  path: '/legal-flow/summary',
  nextPage: () => undefined
}

export const entryModule = ForwardingAddress

export const modules: ModuleType[] = [
  Accounting,
  Capital,
  CompanyActivity,
  ForwardingAddress,
  LegalRepresentative,
  Onboarding,
  Partner,
  Summary
]

export const steps = [ForwardingAddress, LegalRepresentative, CompanyActivity, Capital, Accounting, Partner, Summary]

export const stepsHideDomideal = [ForwardingAddress, LegalRepresentative, CompanyActivity, Capital, Accounting, Summary]
