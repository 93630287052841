export const generateFlow = (
    flow: string[],
    currentPath: string,
    nextPath: string
): string[] => {
  if (!flow.includes(nextPath)) {
    const followingPathIndex = flow.indexOf(currentPath) + 1
    const followingPath = flow.at(followingPathIndex)

    if (followingPath !== nextPath) flow = flow.slice(0, followingPathIndex)
    flow.push(nextPath)
  }

  return flow
}
