import home from 'src/images/home/home-white.svg'
import domiciliationIcon from 'src/images/pin/pin-white.svg'
import documentsIcon from 'src/images/documents/documents-white.svg'
import procedureIcon from 'src/images/procedures/suitcase-white.svg'
import mailIcon from 'src/images/mails/mail-white.svg'
import servicesIcon from 'src/images/apps/apps-white.svg'
import settingsIcon from 'src/images/settings/settings-white.svg'
import teamIcon from 'src/images/teams/team-white.svg'
import ImageBlancLogo from 'src/images/logo-white.svg'
import ReferrerIcon from 'src/images/referrer-icon.svg'

const navigationSections = ({
  domiciliation,
  kyc,
  Routing,
  ability,
  t
}) => {
  const sections = [{
    display: true,
    route: Routing.ROOT,
    authorized: ability.can('customer_manage', 'Domiciliation'),
    dataSpecId: 'sideMenuLink-logo-root',
    icon: ImageBlancLogo,
    logo: true,
    affiliated: !domiciliation
  },
  {
    display: ability.can('customer_manage', 'Domiciliation') || domiciliation,
    route: Routing.ROOT,
    authorized: ability.can('customer_manage', 'Domiciliation'),
    dataSpecId: 'sideMenuLink-root',
    icon: home,
    sectionTitle: t('nav:dashboard'),
  },

  // Ma domiciliation
  {
    display: domiciliation,
    route: Routing.PROFILE,
    authorized: ability.can('customer_read', 'LegalDocument'),
    dataSpecId: 'sideMenuLink-profile',
    icon: domiciliationIcon,
    sectionTitle: t('nav:myDomiciliation'),
  },

  // DOCUMENTS
  {
    display: domiciliation,
    route: Routing.PROFILE_ATTESTATIONS,
    authorized: ability.can('customer_read', 'LegalDocument') && ability.can('customer_read', 'Domiciliation'),
    dataSpecId: 'sideMenuLink-profile-attestations',
    icon: documentsIcon,
    sectionTitle: t('nav:documents')
  },

  // DEMARCHES
  {
    display: domiciliation,
    route: Routing.PROFILE_LEGAL_FILES,
    authorized: ability.can('customer_read', 'LegalDocument') && ability.can('customer_read', 'Domiciliation'),
    dataSpecId: 'sideMenuLink-profile-legal_files',
    icon: procedureIcon,
    sectionTitle: t('nav:procedure'),
    notification: kyc?.legalDocuments,
  },

  // Courriers
  {
    display: ability.can('customer_read', 'UserMail') || domiciliation,
    route: Routing.USER_MAILS_INDEX,
    authorized: ability.can('customer_read', 'UserMail'),
    dataSpecId: 'sideMenuLink-userMail',
    icon: mailIcon,
    sectionTitle: t('nav:userMails'),
    notification: kyc?.userMails > 0 ? kyc?.userMails : undefined,
  },

  // Services
  {
    display: ability.can('customer_manage', 'Contact') || domiciliation,
    route: Routing.SERVICES_INDEX,
    authorized: ability.can('customer_manage', 'Contact'),
    dataSpecId: 'sideMenuLink-service',
    icon: servicesIcon,
    sectionTitle: t('nav:services'),
  },

  // Paramètres
  {
    display: true,
    route: Routing.SETTINGS,
    authorized: true,
    dataSpecId: 'sideMenuLink-settings',
    icon: settingsIcon,
    sectionTitle: t('nav:settings'),
  },

  // Mon Equipe
  {
    display: (ability.can('customer_read', 'User') && ability.can('customer_read', 'Domiciliation')) || domiciliation,
    route: Routing.TEAM,
    authorized: ability.can('customer_read', 'User') && ability.can('customer_read', 'Domiciliation'),
    dataSpecId: 'sideMenuLink-team',
    icon: teamIcon,
    sectionTitle: t('nav:team'),
  },

  // Parrainage
  {
    display: ability.can('customer_read', 'Referral') || domiciliation,
    route: Routing.REFERRER,
    authorized: ability.can('customer_read', 'Referral'),
    dataSpecId: 'sideMenuLink-referrer',
    icon: ReferrerIcon,
    sectionTitle: t('nav:referrer'),
  }
  ]
  return sections
}

export default navigationSections
