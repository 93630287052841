import { useEffect, useState } from 'react'
import styled from 'styled-components'
import IUserMail from 'src/interfaces/UserMail'
import MailCard from '../shared/MailCard'
import Pushes from './Pushes'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'antd'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'
import NoUserMails, { NoLastMailContent } from '../../NoUserMails'
import Filter, { FilterData } from './Filter'
import { USER_MAILS } from '@/graphql/UserMail'

const ListingMails = () => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)
  const [userMailsData, setUserMailsData] = useState<any>({ userMails: [], pagination: {} })
  const [filterData, setFilterData] = useState<FilterData>({})

  const {
    data: dataUserMails,
    loading: loadingUserMails,
    error: errorUserMails
  } = useQuery(USER_MAILS, {
    variables: {
      pagination: {
        page: currentPage,
        pageLength: 10
      },
      filter: filterData ?? {}
    }
  })

  useEffect(() => {
    if (dataUserMails?.userMails) {
      setUserMailsData(dataUserMails.userMails)
    }
  }, [dataUserMails])

  const filterChanged = async (dataUserMails: any) => {
    setFilterData({ ...dataUserMails })
    setCurrentPage(1)
  }

  return (
    <LastMailsWrapper>
      <Pushes />
      <Filter onChange={filterChanged} />

      {loadingUserMails && (
        <div className='p-4'>
          <CommonLoader />
        </div>
      )}
      {errorUserMails && <span>{`${t('common:error')}! ${errorUserMails.message}`}</span>}
      {!loadingUserMails && userMailsData.userMails && (
        <>
          {userMailsData.userMails.length === 0 && (
            <NoLastMailContent>{t('userMails:global.noUserMails')}</NoLastMailContent>
          )}

          {userMailsData.userMails.length > 0 && (
            <>
              {userMailsData.userMails.map((mail: IUserMail) => {
                return <MailCard mail={mail} key={mail.id} />
              })}
              <Pagination
                current={currentPage}
                total={userMailsData.pagination.total}
                onChange={(e) => setCurrentPage(e)}
                showSizeChanger={false}
                hideOnSinglePage={true}
              />
            </>
          )}
        </>
      )}
      {!loadingUserMails && !userMailsData.userMails && <NoUserMails />}
    </LastMailsWrapper>
  )
}

export const LastMailsWrapper = styled.div`
  padding-top: 48px;
  .ant-pagination {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    & > li {
      margin: 0;
      margin-right: 10px;
      border: none;
      color: #171a1c;
      font-size: 16px;
      & > a:hover {
        color: #1576e1;
      }
      .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
    .ant-pagination-item-active {
      color: #1576e1;
    }
  }
`
export const LastMailsTitle = styled.h3`
  color: #171a1c;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`

export default ListingMails
