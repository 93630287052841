const breakpoints = {
  'mobile-breakpoint-i': '700px',
  'mobile-breakpoint-o': '701px',
  'large-breakpoint-i': '1023px',
  'large-breakpoint-o': '1024px',
  'xlarge-breakpoint-i': '1199px',
  'xlarge-breakpoint-o': '1200px',
}

export default {
  breakpoints,
}
