import { useContext } from 'react'
import { Apollo } from './Apollo'
import { Routes, Route } from 'react-router-dom'
import PrivateApps from './app/PrivateApps'
import Healthcheck from './app/Public/Healthcheck'
import ContactUnlogged from './app/Public/Invoices/PublicRegularizeFailedPayments/ContactUnlogged'
import CountryContext from 'src/contexts/countryContext'

const PublicRouting = () => {
  const { countryCode }: any = useContext(CountryContext)
  const HEALTHCHECK = '/healthcheck'
  const REGULARIZE = '/regularisation-facture/:token'

  return (
    <Apollo countryCode={countryCode}>
      <Routes>
        <Route path={HEALTHCHECK} element={<Healthcheck />} />
        <Route path={REGULARIZE} element={<ContactUnlogged />} />
        <Route path="/*" element={<PrivateApps />} />
      </Routes>
    </Apollo>
  )
}

export default PublicRouting
