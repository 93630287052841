import { useState, useContext } from 'react'
import ActivityArea from '@/app/LegalFlow/CompanyActivity/activityArea'
import CompanyNames from '@/app/LegalFlow/CompanyActivity/companyNames'
import { ContactContext } from '@/contexts/contactContext'
import { SOCIETY_UPDATE_MUTATION } from '@/graphql/mutations/upsertSociety'
import { ContactContextProps } from '@/interfaces/Contact'
import { nextPage } from '@/reducers/workflow'
import { useMutation } from '@apollo/client'
import { BasicButton, CustomIcon, LegalflowSection, NoticeInformation } from '@sedomicilier/material-sd'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const CompanyActivity: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const { contact } = useContext<ContactContextProps>(ContactContext)

  const society = contact.society
  const isMicro = society.legalStatus.isMicro

  const [upsertSociety] = useMutation(SOCIETY_UPDATE_MUTATION)

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      const { activity, legalCompanyName, brandName } = values

      upsertSociety({
        variables: {
          name: legalCompanyName,
          brandName: brandName,
          apeCode: activity
        }
      }).then(() => {
        if (isMicro) dispatch(nextPage('micro'))
        else dispatch(nextPage())
      })
    }
  }

  const initialValues = {
    legalCompanyName: society.name,
    brandName: society.brandName,
    activityCategory: society.apeCategory,
    activity: society.apeCode
  }

  return (
    <LegalflowSection title={t('company:title')}>
      <Form
        layout='vertical'
        requiredMark={false}
        className='[&_input]:h-5 [&>div]:mb-2'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {!isMicro && (
          <>
            <CompanyNames />
            <NoticeInformation
              bgColor='grey'
              icon={<CustomIcon icon={<span className={'ico-star-full text-m text-interaction'} />} />}
              className='body-2'
            >
              {t('company:noticeInformation')}
            </NoticeInformation>
          </>
        )}

        <ActivityArea society={society} />
        <BasicButton
          testid='company-activity-next-button'
          loading={loading}
          bgColor='blue'
          label={t('common:continue')}
          className='w-full justify-center body-1 font-semibold'
        />
      </Form>
    </LegalflowSection>
  )
}

export default CompanyActivity
