import { useLocation, useMatch } from 'react-router-dom'
import ProtectedLink from 'src/components/ProtectedLink'
import colors from 'src/components/UIKit/colors'
import styled from 'styled-components'
import Notification from '../Notifications'

interface SideMenuSectionProps {
  route: string
  authorized: boolean
  dataSpecId: string
  icon: HTMLImageElement
  display: boolean
  sectionTitle?: string
  notification?: number
  logo?: boolean
  affiliated?: boolean
}

const SideMenuSection = ({
  route,
  authorized,
  dataSpecId,
  icon,
  sectionTitle,
  display,
  notification,
  logo,
  affiliated
}: SideMenuSectionProps) => {
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const match = useMatch(`${basePath}/*`)

  const matcher = (path) => {
    if (path === match?.pathnameBase) {
      return 'active'
    }
    return ''
  }

  if (display) {
    return (
      <SideMenuSectionWrapper notification={notification} logo={logo} affiliated={affiliated}>
        <ProtectedLink to={route}
          className={`flex ${matcher(route)}`} authorized={authorized} data-spec-id={dataSpecId}>
          <SectionIcon className="flex-initial" src={icon} logo={logo} />
          <SectionTitle className="flex-1">{sectionTitle}</SectionTitle>
          <Notification number={notification} />
        </ProtectedLink>
      </SideMenuSectionWrapper>
    )
  } else {
    return null
  }
}

export const SideMenuSectionWrapper = styled.div`
  ${(props) =>
    props.logo ? `
      margin: 22px 0 40px 20px;
      width: 26px;
    ` : `
    width: 100%;
    height: 35px;
    font-family: 'Inter';
    font-size: 12px;
    color: ${colors.white};
    margin-bottom: 4px;
    border-left: 2px solid transparent;

    a,
    .disabled-nav-link {
      text-decoration: none;
      font-size: 12px;
      color: #dddddd;
      display: flex;
      text-align: left;
      height: 100%;
      padding-top: 8px;

      ${props.notification &&
    `
        justify-content: space-between;
        padding-right: 16px;
        `
    }
    }

    a:hover {
      color: #dddddd;
      text-decoration: none;
      height: 100%;
    }

    .disabled-nav-link {
      opacity: 0.5;
      cursor: default;
    }

    .active {
      background-color: rgba(255, 255, 255, 0.2);
      img {
        opacity: 1;
      }
    }

    &:hover {
      border-left: 2px solid white;
    }

  `}
`
export const SectionTitle = styled.span`
  font-size: 14px;
`
export const SectionIcon = styled.img`
  ${(props) => props.logo ? `
    width: 26px;
  ` : `
    opacity: 0.5;
    vertical-align: top;

    .without-opacity-reduction {
      opacity: 1;
    }
    width: 18px;
    height: 18px;
    margin-left: 18px;
    margin-right: 15px;
  `}
`

export default SideMenuSection
