import styled from 'styled-components'
import constants from '@/components/UIKit/constants'

export const Panel = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #DDD);
  background: var(--Neutral-0, #FFF);
  margin-bottom: 20px;
`

export const PanelTitle = styled.h6`
  color: var(--Neutral-1, #171A1C);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0px;
  vertical-align: middle;
  line-height: 40px;
  width: 100%;
`

export const InfosList = styled.ul`
  padding: 0px;
  margin: 0px;
  width: 100%;
`

export const InfosListElement = styled.li`
  border-bottom: 1px solid #DDD;
  padding: 10px 0 18px 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`

export const InfosListElementHeader = styled.p`
  color: var(--Neutral-500, #737375);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 12px;
  &.cguBlock {
    line-height: 24px;
  }
`

export const InfosListElementValue = styled.p`
  color: var(--Neutral-1, #171A1C);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
`

export const RightLink = styled.a`
  color: var(--Primary-500, #1576E1);
  float: right;
  font-weight: 100;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
  }
`
