import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ImageLeftArrow from '../../images/left-arrow.svg'

interface IProps {
  onSetSidebarState: (open: boolean) => void;
  title: string;
  subtitle: string;
}

const MobileBreadcrumb = (props: IProps) => {
  const { t } = useTranslation()
  const { onSetSidebarState, title, subtitle } = props

  return (
    <div className="header-child-setting">
      <label className="return-back setting-link-back">
        <img src={ImageLeftArrow} className="icon-arrow-left inline" />
        <a
          href={void(0)}
          onClick={
            onSetSidebarState && onSetSidebarState.bind(undefined, true)
          }
        >
          {t('common:back')}
        </a>
        <h1 className="title-head title-service no-center">
          {t(`mobileBreadcrumb:${title}.title`)}{' '}
          <span className="sub-title">
            {' '}
            / {t(`mobileBreadcrumb:${title}.subtitles.${subtitle.toLowerCase()}`)}
          </span>
        </h1>
      </label>
    </div>
  )
}

export default MobileBreadcrumb
