import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeUserNameModal from './ChangeUserNameModal'
import ChangePasswordModal from './ChangePasswordModal'
import { CurrentUserContext } from 'src/contexts/currentUserContext'
import { Outlet } from 'react-router-dom'

const Security = () => {
  const { t } = useTranslation()
  const currentUser = React.useContext(CurrentUserContext)

  const [showModalUpdateUserName, setShowModalUpdateUserName] = useState<boolean>(false)
  const [showModalUpdatePassword, setShowModalUpdatePassword] = useState<boolean>(false)

  const onCloseModal = (field: string) => {
    if (field === 'showModalUpdateUserName') {
      setShowModalUpdateUserName(false)
    }
    if (field === 'showModalUpdatePassword') {
      setShowModalUpdatePassword(false)
    }
  }
  const handleEdit = (field: string) => {
    if (field === 'showModalUpdateUserName') {
      setShowModalUpdateUserName(true)
    }
    if (field === 'showModalUpdatePassword') {
      setShowModalUpdatePassword(true)
    }
  }

  return (
    <div className="settings-block">
      <Outlet />
      {showModalUpdateUserName && (
        <ChangeUserNameModal onCloseModal={() => onCloseModal('showModalUpdateUserName')} user={currentUser} />
      )}
      {showModalUpdatePassword && <ChangePasswordModal onCloseModal={() => onCloseModal('showModalUpdatePassword')} />}
      <div className="content-child">
        <h2 className="subtitle-sidebar">{t('security:title')}</h2>
        <div className="top-margin">
          <p className="left">{t('security:name')}</p>
          <p
            className="right no-margin notranslate"
            translate="no"
            data-test='security-update-name'
          >
            {`${currentUser.firstname || ''} ${currentUser.lastname || ''}`}
            <a
              href={void 0}
              className="primary-link margin-left mobile-right"
              onClick={() => handleEdit('showModalUpdateUserName')}
            >
              {currentUser.firstname || currentUser.lastName ? 'Modifier' : 'Définir'}
            </a>
          </p>
          <div className="clearfix" />
        </div>
        <div className="top-margin">
          <p className="left">{t('security:password')}</p>
          <p className="right no-margin">
            • • • • • • • •
            <a
              href={void 0}
              className="primary-link margin-left mobile-right"
              onClick={() => handleEdit('showModalUpdatePassword')}
            >
              Modifier
            </a>
          </p>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  )
}

export default Security
