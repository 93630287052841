import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'src/store'

export interface SocietyInformationsState {
  name?: string
  siren?: string
  legalStatusName?: string
  legalStatusId?: string
  address?: string
  leaderFirstname?: string
  leaderLastname?: string
  saved?: boolean
}

const initialState: SocietyInformationsState = {
  saved: false
}

export const societyInformationsSlice = createSlice({
  name: 'societyInformations',
  initialState,
  reducers: {
    updateInformations: (state, action: PayloadAction<SocietyInformationsState>) => {
      return { ...state, ...action.payload }
    },
    saved: (state) => {
      state.saved = true
    }
  }
})

export const { updateInformations, saved } = societyInformationsSlice.actions
export const selectSocietyInformations = (state: RootState) => state.societyInformations

export default societyInformationsSlice.reducer
