import { Dispatch, SetStateAction } from 'react'

interface OwnerTypeProps {
  status: string
  setStatus: Dispatch<SetStateAction<string>>
}

const OwnerType: React.FC<OwnerTypeProps> = ({ status, setStatus }) => {
  const ownerTypes = [
    {
      id: 'physical',
      label: 'Personne physique'
    },
    {
      id: 'moral',
      label: 'Personne morale'
    }
  ]

  return (
    <div className='flex gap-2 items-center body-2 font-semibold'>
      {ownerTypes.map((type) => {
        const selected = type.id === status

        const bord = selected ? 'bg-primary-50 text-interaction' : 'bg-neutral-50 text-neutral-500'

        return (
          <div
            key={type.id}
            data-testid={`${type.id}-owner`}
            className={`px-2 py-[10px] rounded-[6px] cursor-pointer ${bord}`}
            onClick={() => setStatus(type.id)}
          >
            {type.label}
          </div>
        )
      })}
    </div>
  )
}

export default OwnerType
