// @ts-nocheck
import React, { useState } from 'react'
import { withFormik, FormikProps, Form, Field } from 'formik'
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ISearch } from './Interfaces'
import FormikEffect from '@/components/shared/FormikEffect'
import FormikDatePicker from '@/components/shared/FormikDatePicker'
import { isMobile } from '@/components/shared/utilities/isMobile'
import CustomSelect from '@/components/shared/utilities/CustomSelect'
import Routing from '@/CustomerArea/Routing'
import * as Yup from 'yup'

interface IPropsLayout {
  fullScreen: boolean
  children: React.ReactNode
  showFilters: boolean
  toggleShowFilters: any
  gotoIndex: () => void
}

const Layout = (props: IPropsLayout) => {
  const { t } = useTranslation()

  if (props.fullScreen) {
    return (
      <>
        <h1 className="title center nowidth under-space size-mobile">
          {t('services:bookings:room')}
        </h1>
        <p className="slogan center">{t('services:bookings:slogan')}</p>
        <div className="service-inner-form margin-auto">
          <div className="hint">{t('services:bookings:hint')}</div>
          {props.children}
        </div>
      </>
    )
  } else {
    return (
      <>
        <input
          type="checkbox"
          id="cliquable-filtre"
          checked={props.showFilters}
          onChange={props.toggleShowFilters}
        />
        <nav className="nav__menu_filtre">
          <div className="full-height">
            <div className="form-container align-self-center">
              <div className="hidden-desktop">
                <h1 className="title center nowidth under-space">
                  {t('services:bookings:room')}
                </h1>
                <p className="slogan center">{t('services:bookings:slogan')}</p>
              </div>
              {props.children}
            </div>
          </div>
        </nav>
      </>
    )
  }
}

interface IFormValues {
  city: string;
  capacity: string;
  startingDate: string;
  startingHour: string;
  endingHour: string;
}

interface IProps {
  cities: string[];
  fullScreen: boolean;
  setSearch: (search: ISearch) => void;
  handleSearch: () => void;
  search: ISearch;
}

const Search: React.FC<IProps & FormikProps<IFormValues>> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { touched, errors, cities } = props

  const [showFilters, setShowFilters] = useState(false)

  const citiesOptions = cities.map((city) => ({
    id: city,
    display: city
  }))

  let capacityOptions: Array<{ id: string; display: string }> = []
  capacityOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((capacity) => ({
    id: capacity,
    display: capacity
  }))
  capacityOptions.push({ id: '10', display: `10 ${t('common:and')} + ` })

  const hourOptionsBegin: Array<{ id: string; display: string }> = []
  const hourOptionsEnd: Array<{ id: string; display: string }> = []

  Array(9)
      .fill(null)
      .map((_, i) => i + 9)
      .forEach((hour) =>
        hourOptionsBegin.push({ id: `${hour}:00`, display: `${hour}h00` }, { id: `${hour}:30`, display: `${hour}h30` })
      )
  Array(9)
      .fill(null)
      .map((_, i) => i + 10)
      .forEach((hour) =>
        hourOptionsEnd.push({ id: `${hour}:00`, display: `${hour}h00` }, { id: `${hour}:30`, display: `${hour}h30` })
      )
  hourOptionsBegin.push({ id: '19:00', display: '18h00' })
  hourOptionsEnd.push({ id: '19:00', display: '19h00' })

  const gotoIndex = () => {
    navigate(Routing.SERVICES_INDEX)
  }

  const toggleShowFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowFilters(e.target.checked)
  }

  const handleSearchFunction = (cb?: () => void) => {
    Object.keys(props.values).forEach((name: string) => props.setFieldTouched(name, true, true))

    props.validateForm().then(() => {
      if (Object.keys(props.errors).length === 0) {
        if (cb) {
          cb()
        }
        props.handleSearch()
      }
    })
  }

  const hideFilters = () => {
    handleSearchFunction(() => {
      setShowFilters(false)
    })
  }

  const onChange = (from: IFormValues, to: IFormValues) => {
    const searchVar: ISearch = {
      city: to.city === '' ? undefined : to.city,
      capacity: to.capacity === '' ? undefined : Number(to.capacity)
    }

    if (to.startingDate && to.startingHour && to.endingHour) {
      searchVar.startingAt = moment(`${to.startingDate} ${to.startingHour}`, 'YYYY-MM-DD H:mm')
      searchVar.endingAt = moment(`${to.startingDate} ${to.endingHour}`, 'YYYY-MM-DD H:mm')
    }

    props.setSearch(searchVar)
  }

  return (
    <Layout
      {...props}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      gotoIndex={gotoIndex}
    >
      <h4>Filtres</h4>
      <Form>
        <FormikEffect values={props.values} onChange={onChange} />
        <div className="left-content form-booking">
          <div>
            <div>
              <div className="cadre-filtre-input">
                <p className="question-form capitalize">{t('common:where')}</p>
                <CustomSelect
                  name="city"
                  options={citiesOptions}
                  value={props.values.city}
                  classNames={`${touched.city && errors.city ? 'in-error' : ''}`}
                  placeholder="Choississez une ville"
                  {...props}
                />
                {touched.city && errors.city && <div className="error-input">{errors.city}</div>}
              </div>
            </div>
            <div>
              <div className="cadre-filtre-input ">
                <p className="question-form">{t('services:bookings:number_persons')}</p>
                <CustomSelect
                  name="capacity"
                  options={capacityOptions}
                  value={props.values.capacity.toString()}
                  classNames={`${touched.capacity && errors.capacity ? 'in-error' : ''}`}
                  includeBlank={isMobile}
                  placeholder="Combien serez-vous ?"
                  {...props}
                />
                {touched.capacity && errors.capacity && <div className="error-input">{errors.capacity}</div>}
              </div>
            </div>
            <div>
              <div
                className={`cadre-filtre-input dateinput ${
                  touched.startingDate && errors.startingDate ? 'in-error' : ''
                }`}
              >
                <p className="question-form">{t('services:bookings:start_date')}</p>
                <Field
                  name="startingDate"
                  component={FormikDatePicker}
                  displayFormat="DD/MM/YYYY"
                  placeholder="Date de la réservation"
                />
                {touched.startingDate && errors.startingDate && (
                  <div className="error-input">{errors.startingDate}</div>
                )}
              </div>
            </div>
            <div>
              <div className="cadre-filtre-input hours-select">
                <p className="question-form">{t('services:bookings:start_time')}</p>
                <CustomSelect
                  name="startingHour"
                  options={hourOptionsBegin}
                  value={props.values.startingHour}
                  classNames={`${touched.startingHour && errors.startingHour ? 'in-error' : ''}`}
                  includeBlank={isMobile}
                  placeholder="Heure d'arrivée"
                  {...props}
                />
                {touched.startingHour && errors.startingHour && (
                  <div className="error-input">{errors.startingHour}</div>
                )}
              </div>
            </div>
            <div>
              <div className="cadre-filtre-input hours-select">
                <p className="question-form">{t('services:bookings:end_time')}</p>
                <CustomSelect
                  name="endingHour"
                  options={hourOptionsEnd}
                  value={props.values.endingHour}
                  classNames={`${touched.endingHour && errors.endingHour ? 'in-error' : ''}`}
                  includeBlank={isMobile}
                  placeholder="Heure de départ"
                  {...props}
                />
                {touched.endingHour && errors.endingHour && <div className="error-input">{errors.endingHour}</div>}
              </div>
            </div>
          </div>
          {isMobile && !props.fullScreen && (
            <button
              type="button"
              onClick={hideFilters}
              className="section-button primary-button width100-special-btn-3 mobile"
              disabled={!props.isValid}
            >
              {t('common:search')}
            </button>
          )}
          {props.fullScreen && (
            <button
              type="button"
              className="btn primary-btn primary-button width100-special-btn-3"
              onClick={() => handleSearchFunction(undefined)}
              disabled={!props.isValid}
            >
              {t('common:search')}
            </button>
          )}
        </div>
      </Form>
    </Layout>
  )
}

export default withTranslation(['services', 'common'])(
    withFormik<IProps & WithTranslation, IFormValues>({
      mapPropsToValues: (props) => {
        return {
          city: props.search.city || '',
          capacity: props.search.capacity ? props.search.capacity.toString() : '',
          startingDate: (props.search.startingAt && props.search.startingAt.format('YYYY-MM-DD')) || '',
          startingHour: (props.search.startingAt && props.search.startingAt.format('H:mm')) || '',
          endingHour: (props.search.endingAt && props.search.endingAt.format('H:mm')) || ''
        }
      },
      validationSchema: (props: IProps & WithTranslation) =>
        Yup.object().shape({
          city: Yup.string().required(props.t('services:bookings:validations:city')),
          capacity: Yup.string().required(props.t('services:bookings:validations:capacity')),
          startingDate: Yup.string().required(props.t('services:bookings:validations:startingDate')).nullable(true),
          startingHour: Yup.string().required(props.t('services:bookings:validations:startingHour')),
          endingHour: Yup.string().required(props.t('services:bookings:validations:endingHour'))
        }),
      handleSubmit: () => {
      /* tslint:disable: no-empty */
      }
    })(Search)
)
