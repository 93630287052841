import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import IconCart from '../../images/icon-cart.svg'
import IconCartWhite from '../../images/icon-cart-white.svg'
import { Colors } from 'src/components/UIKit'
import { CurrentCartContext } from 'src/contexts/currentCartContext'
import constants from 'src/constants'

interface IProps {
  iconOnly?: boolean
}

const CartLink = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { iconOnly } = props
  const [count, setCount] = useState<number>(0)

  const currentCart = useContext(CurrentCartContext)

  const gotoSummary = () => navigate(Routing.CART)

  useEffect(() => {
    if (currentCart?.purchases) {
      setCount(currentCart.purchases.length)
    }
  }, [currentCart])

  return (
    <Wrapper onClick={gotoSummary} data-test='navbar-cart'>
      <ImgDesktop className="inline" src={IconCart} />
      <ImgMobile className="inline" src={IconCartWhite} />
      {count > 0 && <Badge double={count >= 10}>{count}</Badge>}
      {!iconOnly && <Title>{t('common:cart')}</Title>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  align-self: center;
  min-height: 20px;
`

const Badge = styled.span`
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  font-size: 8px;
  background: #000000;
  line-height: 11px;
  color: #ffffff;
  padding: 2px ${(props) => (props.double ? '2' : '4.4')}px;
  vertical-align: top;
  margin-left: -10px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    background-color: ${Colors.blue};
  }
`

const Title = styled.span`
  vertical-align: middle;
  margin: 0px 0px 0px 5px;
  font-family: 'Open Sans';

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    display: none !important;
    color: #fff;
  }
`

const ImgDesktop = styled.img`
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    display: none !important;
  }
`

const ImgMobile = styled.img`
  display: none !important;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    display: inline-block !important;
  }
`
export default CartLink
