import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import Routing from '@/CustomerArea/Routing'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'

interface IProps {
  createProcedure: any
  loading: boolean
  values: any
}

const TerminationSubscriptionConfirmation = (props: IProps) => {
  const { t } = useTranslation()
  const { loading, createProcedure, values } = props
  const navigate = useNavigate()

  const returnToIndex = () => () => {
    navigate(Routing.PROFILE)
  }

  const onFinish = () => () => {
    createProcedure()
  }
  const date = moment(values?.cancelationExpectedDate || new Date()).format('DD/MM/YYYY')

  return (
    <>
      <div className='upsell'>
        <ol>
          {[1, 2, 3, 4].map((i) => (
            <li key={'warning_' + i}>{t(`cancelationProcedure:confirmation.warnings.` + i)}</li>
          ))}
        </ol>
      </div>

      <div className='help warning'>
        <h4>{t(`cancelationProcedure:confirmation.mainWarning`, { date })}</h4>
      </div>

      <div className='btns'>
        <Button onClick={returnToIndex()} disabled={loading} type='default'>
          {t(`cancelationProcedure:confirmation.buttons.cancel`)}
        </Button>

        <Button onClick={onFinish()} disabled={loading} type='primary'>
          {t(`cancelationProcedure:confirmation.buttons.confirm`)}
        </Button>
      </div>
    </>
  )
}

export default TerminationSubscriptionConfirmation
