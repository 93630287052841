// @ts-nocheck
import React, { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { IRetryPaymentProps } from './Interfaces'
import { SubmitLoader } from '@/components/shared/Loader'
import { confirmUnpaidInvoice } from '@/components/shared/Stripe'
import { MutationFunction } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { PAYMENT_INTENT_PROCESSING } from 'src/graphql/PaymentIntent'
import { DOMICILIATION_QUERY } from 'src/graphql/Domiciliation'
import IFailedPayment from 'src/interfaces/FailedPayment'

const ConfirmPaymentIntent = (props: IRetryPaymentProps & {client: any}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stripeError, setStripeError] = useState()
  const stripe: any = useStripe()

  const confirmPayment = async (client: any, unpaidInvoice: IFailedPayment, mutate: MutationFunction) => {
    try {
      setIsSubmitting(true)
      await confirmUnpaidInvoice(stripe, client, unpaidInvoice, mutate)
      props.onSuccess()
    } catch (e) {
      setIsSubmitting(false)
      setStripeError(e)
      props.alertFailedPayment()
      props.onCloseModal()
      return
    }
  }

  return (
    <Mutation mutation={PAYMENT_INTENT_PROCESSING} refetchQueries={[{ query: DOMICILIATION_QUERY }]}>
      {(mutate) => (
        <div className="confirmPaymentIntentSection">
          <button
            type="submit"
            className="submit section-button"
            onClick={() => confirmPayment(props.client, props.unpaidInvoice, mutate)}
            disabled={isSubmitting}>

            <SubmitLoader isSubmitting={isSubmitting} />
            <span>Confirmer</span>

          </button>
          {stripeError !== undefined && <div className="form-errors">{stripeError}</div>}
        </div>
      )}
    </Mutation>
  )
}

export default withApollo(ConfirmPaymentIntent)
