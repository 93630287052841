import { useState } from 'react'
import UserMails from './components/UserMails'
import UserMailProvider from './components/contexts/UserMailContext'
import IUserMail from 'src/interfaces/UserMail'

const Index = () => {
  const [selectedMail, setSelectedMail] = useState<IUserMail | undefined>(undefined)
  return (
    <UserMailProvider
      value={{
        selectedMail,
        setSelectedMail,
      }}
    >
      <UserMails />
    </UserMailProvider>
  )
}

export default Index
