import React, { useContext, useEffect, useState } from 'react'
import ProcedureDrawer from './ProcedureDrawer'
import { NoticeStrongText, NoticeText, Notices } from './Identity/styles'
import NoticeInformation from '@/components/noticeInformation'
import { useTranslation } from 'react-i18next'
import AddressProofDrawer from './AddressProofDrawer/addressProofDrawer'
import IdentityProofDrawer from './IdentityProofDrawer'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import idCardIcon from 'src/images/legal-documents/drawer/IdentityCardFrench-recto.svg'
import passportIcon from 'src/images/legal-documents/drawer/PassportFrench-recto.svg'
import BeneficialOwnerDrawer from './BeneficialOwnerInfo/BeneficialOwnerDrawer'

export interface Choice {
  name: string
  icon: HTMLImageElement
  title: string
  fileType: string
}

const IdentityDrawers = (props) => {
  const { t, i18n } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const {
    drawers,
    targetType,
    drawerCategory,
    drawerAllowDocumentTypes,
    onSendDocument,
    states,
    idx,
    onShareholderSubmit,
    denominationChoices,
    contactDetail,
    moralShareholderName
  } = props

  const hosted = domiciliation?.hoster

  const { setSelectedFile, selectedFile, setFileType, setFileVerso } = states
  const {
    drawerOpen,
    setDrawerOpen,
    identityDrawerOpen,
    setIdentityDrawerOpen,
    addressOpen,
    setAddressOpen,
    shareholderDrawerOpen,
    setShareholderDrawerOpen
  } = drawers

  const choices: Choice[] = [
    {
      name: 'idCard',
      icon: idCardIcon,
      title: t('procedure:identity.nationalCard'),
      fileType: 'legalDocument_kyc_identityCard'
    },
    {
      name: 'passport',
      icon: passportIcon,
      title: t('procedure:identity.passport'),
      fileType: 'legalDocument_kyc_passport'
    }
  ]

  const residencePermit = {
    name: 'residencePermit',
    icon: idCardIcon,
    title: t('procedure:identity.residencePermit'),
    fileType: 'legalDocument_kyc_residencePermit'
  }

  const [selected, setSelected] = useState<Choice>(choices[0])
  const [showFirstStep, setShowFirstStep] = useState(true)

  const onClose = (setOpen) => {
    setSelectedFile(null)
    setFileVerso(null)
    setOpen(false)
  }

  const tradStepCertificate = (drawerCategory: string) => {
    if (domiciliation === undefined || drawerCategory !== 'certificate') {
      return t(`procedure:drawer.title.${drawerCategory}`)
    }

    if (domiciliation.society.legalStatus.isMicro) {
      return t(`procedure:drawer.title.certificate-micro`)
    } else if (domiciliation.society.legalStatus === 'Association') {
      return t(`procedure:drawer.title.certificate-association`)
    } else {
      return t(`procedure:drawer.title.certificate`)
    }
  }

  useEffect(() => {
    if (drawerCategory === 'residence_permit') {
      setShowFirstStep(false)
      setSelected(residencePermit)
      setFileType(residencePermit.fileType)
    } else {
      setShowFirstStep(true)
      setSelected(choices[0])
    }
  }, [drawerCategory])

  return (
    <React.Fragment>
      {drawerOpen && (
        <ProcedureDrawer
          onClose={() => onClose(setDrawerOpen)}
          open={drawerOpen}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          drawerTitle={t(`procedure:drawer.drawerTitle.${targetType}`)}
          drawerCategory={drawerCategory}
          drawerAllowDocumentTypes={drawerAllowDocumentTypes}
          title={tradStepCertificate(drawerCategory)}
          onSendDocument={onSendDocument}
          setFileType={setFileType}
        >
          <Notices>
            {i18n.exists(`procedure:noticeInformationDrawer.${drawerCategory}.regular`) && (
              <NoticeInformation bgColor='blue'>
                <NoticeText>{t(`procedure:noticeInformationDrawer.${drawerCategory}.regular`)}</NoticeText>
                <NoticeStrongText>{t(`procedure:noticeInformationDrawer.${drawerCategory}.strong`)}</NoticeStrongText>
              </NoticeInformation>
            )}
            {i18n.exists(`procedure:noticeWarningDrawer.${drawerCategory}.regular`) && (
              <NoticeInformation bgColor='orange'>
                <NoticeText>{t(`procedure:noticeWarningDrawer.${drawerCategory}.regular`)}</NoticeText>
                <NoticeStrongText>{t(`procedure:noticeWarningDrawer.${drawerCategory}.strong`)}</NoticeStrongText>
              </NoticeInformation>
            )}
          </Notices>
        </ProcedureDrawer>
      )}

      {addressOpen && (
        <AddressProofDrawer
          onClose={() => onClose(setAddressOpen)}
          open={addressOpen}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          drawerTitle={
            hosted ?
              t('procedure:hostInformation.drawer.addressProof.hostedDrawerTitle') :
              t('procedure:hostInformation.drawer.addressProof.drawerTitle')
          }
          drawerCategory={drawerCategory}
          drawerAllowDocumentTypes={drawerAllowDocumentTypes}
          onSendDocument={onSendDocument}
          setFileType={setFileType}
        />
      )}

      {identityDrawerOpen && (
        <IdentityProofDrawer
          selected={selected}
          setSelected={setSelected}
          showFirstStep={showFirstStep}
          choices={choices}
          onSendDocument={onSendDocument}
          onClose={() => onClose(setIdentityDrawerOpen)}
          open={identityDrawerOpen}
          states={states}
          drawerTitle={hosted ? t('procedure:identity.drawer.hostedTitle') : t('procedure:identity.drawer.title')}
          drawerCategory={drawerCategory}
          drawerAllowDocumentTypes={drawerAllowDocumentTypes}
        />
      )}

      {shareholderDrawerOpen && (
        <BeneficialOwnerDrawer
          onClose={() => setShareholderDrawerOpen(false)}
          open={shareholderDrawerOpen}
          index={idx}
          states={states}
          onSubmit={onShareholderSubmit}
          info={states.info}
          setInfo={states.setInfo}
          setSelectedDenomination={states.setSelectedDenomination}
          selectedDenomination={states.selectedDenomination}
          denominationChoices={denominationChoices}
          contactDetail={contactDetail}
          moralShareholderName={moralShareholderName}
        />
      )}
    </React.Fragment>
  )
}

export default IdentityDrawers
