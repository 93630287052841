import * as React from 'react'
import { Grid, Rings, ThreeDots } from 'react-loader-spinner'

export const SubmitLoader = ({ isSubmitting }: {isSubmitting: boolean}) => {
  if (!isSubmitting) {
    return <></>
  }

  return <div className="submit-loader">
    <Rings width={11} height={11} color="#282c2e" visible={ isSubmitting } />
  </div>
}

export const CommonLoader = ({ size }: { size?: number }) => (
  <div className="common-loader">
    <Grid
      width={size || 8}
      height={size || 8}
      color="#1576E1"
    />
  </div>
)

export const UploadLoader = ({ loading }: { loading: boolean }) => (
  <ThreeDots
    wrapperClass="spinner"
    width={20}
    height={20}
    color={'#1576E1'}
    visible={loading}
  />
)
