import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import lock from 'src/images/locks/lock-grey.svg'
import { Colors } from '@/components/UIKit'

interface StepStateProps {
  state: 'locked' | 'todo' | 'validated' | 'completed'
}

const StepState = ({ state }: StepStateProps) => {
  const { t } = useTranslation()
  const validated = state === 'validated' ? true : false

  return state === 'locked' ? (
    <StepStateLocked src={lock} alt='lock' />
  ) : (
    <StepStateWrapper validated={validated}>{t(`common:stepState.${state}`)}</StepStateWrapper>
  )
}

export const StepStateLocked = styled.img``
export const StepStateWrapper = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
  ${(props) =>
    props.validated ?
      `
      color: #169254;
      background-color: #CFE5DA;
    ` :
      `
      color: ${Colors.orange};
      background-color: ${Colors.lightOrange};
  `}
  border-radius: 1280px;
`
export default StepState
