/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useEffect, useState } from 'react'

const ALERT_TIME = 5000

const initialState = {
  text: '',
  type: '',
  size: 'large'
}

interface SetAlertProps {
  type: 'success' | 'error'
  text: string
  size: 'large' | 'small'
  description: string | ''
}

export const AlertContext = createContext<any>({
  ...initialState,
  setAlert: () => {},
})

interface AlertProviderProps {
  children: React.ReactNode
}

const AlertProvider = ({ children }: AlertProviderProps) => {
  const [text, setText] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [type, setType] = useState<'success' | 'error' | ''>('')
  const [size, setSize] = useState<'small' | 'large'>('small')
  const [animationShow, setAnimationShow] = useState<string>('slide')
  const [timeoutIDState, setTimeoutIDState] = useState<NodeJS.Timeout | undefined>(undefined)
  const [inTimeoutID, setInTimeoutID] = useState<NodeJS.Timeout | undefined>(undefined)
  const [closeIconClicked, setCloseIconClicked] = useState(false)

  const setAlert = ({text, type, size, description}: SetAlertProps) => {
    setText(text)
    setDescription(description)
    setType(type)
    setSize(size || 'large')
  }

  useEffect(() => {
    if (size === 'large') {
      const globalTimeoutID = setTimeout(() => {
        setAnimationShow('hide')
        const inTimeoutID = setTimeout(() => {
          setText('')
          setType('')
          setDescription('')
          setAnimationShow('slide')
        }, 1000)
        setInTimeoutID(inTimeoutID)
      }, ALERT_TIME)
      setTimeoutIDState(globalTimeoutID)
    }
  }, [size, type, text])


  useEffect(() => {
    if (closeIconClicked) {
      clearTimeout(inTimeoutID)
      clearTimeout(timeoutIDState)
      setCloseIconClicked(false)
    }
  }, [closeIconClicked])

  return (
    <AlertContext.Provider value={{
      text,
      description,
      type,
      size,
      setText,
      setDescription,
      setType,
      setAlert,
      setCloseIconClicked,
      animationShow
    }}>
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
