import gql from 'graphql-tag'
import { INVOICE_FRAGMENT, FAILED_PAYMENT_FRAGMENT, PUBLIC_FAILED_PAYMENT_FRAGMENT } from './Fragments'

export const INVOICES_QUERY = gql`
    query invoices(
        $page: Int,
        $per: Int,
        $order: SortDirection
    ){
      invoices(
          page: $page,
          per: $per,
          order: $order
      ) {
          ...InvoiceFragment
      }
      unpaidInvoices {
          ...FailedPaymentFragment
      }
    }
    ${INVOICE_FRAGMENT}
    ${FAILED_PAYMENT_FRAGMENT}
`

export const UNLOGGED_CONTACT = gql`
  query unloggedContact($securityToken: String!){
    unloggedContact(securityToken: $securityToken) {
      id
      stripeConnectedAccount
      publicFailedPayments {
        ...PublicFailedPaymentFragment
      }
      regularizationInformations {
        paymentIntent {
          stripeId
          stripeClientSecret
        }
        total
      }
    }
  }
  ${PUBLIC_FAILED_PAYMENT_FRAGMENT}
`

export const INVOICES_SUBSCRIPTION = gql`
    subscription {
      invoiceRefetch {
        ...InvoiceFragment
      }
    }
    ${INVOICE_FRAGMENT}
`

export const FAILED_PAYMENTS_SUBSCRIPTION = gql`
    subscription {
      unpaidInvoiceRefetch {
        ...FailedPaymentFragment
      }
    }
    ${FAILED_PAYMENT_FRAGMENT}
`

export const UNPAID_INVOICE_PAID_MUTATION = gql`
    mutation UnpaidInvoicePaid($id: ID!) {
        unpaidInvoicePaid(id: $id) {
          success
        }
    }
`

export const REGULARIZE_INVOICES_QUERY = gql`
    query regularizeInvoices{
        failedInvoices {
            ...FailedPaymentFragment
        },
        paymentIntent {
          stripeId,
          stripeClientSecret
        },
        total
    }
    ${FAILED_PAYMENT_FRAGMENT}
`
