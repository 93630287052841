import React, { useState, useContext } from 'react'
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useQuery } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { PAYMENT_SOURCE_CREATE_MUTATION } from '@/graphql/PaymentSource'
import { IProps } from './Interfaces'
import { STRIPE_SETUP_INTENT_SECRET } from 'src/graphql/StripeSetupIntentSecret'
import { STRIPE_INFORMATIONS } from 'src/graphql/StripeInformations'
import { useTranslation } from 'react-i18next'
import InformationCircleO from '@/images/information-circle-o.svg'
import { CommonLoader } from '@/components/shared/Loader'
import CountriesSelect from '@/components/shared/utilities/CountriesSelect'
import { ContactContext } from 'src/contexts/contactContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const createOptions = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'FR',
  style: {
    base: {
      'fontSize': '14px',
      'color': '#282c2e',
      'fontFamily': 'inherit',
      '::placeholder': {
        color: 'rgba(123, 126, 128, 0.57)',
      },
    },
    invalid: {
      color: 'red',
    },
  },
}

const SepaSection = (props: IProps) => {
  const { t } = useTranslation()
  const { contact }: any = useContext(ContactContext)
  const { domiciliation }: any = useContext(DomiciliationContext)

  const [ownerName, setOwnerName] = useState('')
  const [ownerAddress, setOwnerAddress] = useState<string | undefined>()
  const [countryCode, setCountryCode] = useState<string | undefined>()
  const [stripeError, setStripeError] = useState<string | undefined>()
  const [submitting, setSubmitting] = useState(false)

  const stripe: any = useStripe()
  const elements: any = useElements()

  const { data: setupIntentData } = useQuery(STRIPE_SETUP_INTENT_SECRET)
  const { data: stripeInformationsData, loading: loadingStripeInformations } = useQuery(STRIPE_INFORMATIONS)

  const ownerAddressIsRequired =
    stripeInformationsData?.stripeInformations?.sepaCountryWithOwnerAddressNeeded?.includes(countryCode)

  const handleSubmit = (paymentSourceCreate: any, event: any) => {
    setSubmitting(true)
    event.preventDefault()

    const ibanElement = elements.getElement(IbanElement)
    stripe.confirmSepaDebitSetup(setupIntentData!.setupIntentSecret, {
      payment_method: {
        sepa_debit: ibanElement,
        billing_details: {
          name: ownerName,
          email: contact.email,
          address: {
            country: countryCode,
            line1: ownerAddress,
          },
        },
      },
    }).then((result: any) => {
      if (result.error) {
        setSubmitting(false)
        setStripeError(result.error.message)
      } else {
        setStripeError(undefined)

        paymentSourceCreate(
            {
              variables: { token: result.setupIntent.payment_method },
            },
        ).then(
            (mutationResult: any) => {
              setSubmitting(false)
              if (mutationResult.data.error) {
                setStripeError(mutationResult.data.error.message)
              } else {
                setStripeError(undefined)
                props.onCloseModal()
              }
            },
        )
      }
    })
  }

  const handleOwnerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnerName(e.target.value)
  }

  const handleOwnerAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnerAddress(e.target.value)
  }

  if (domiciliation.paymentType === 'monthly' && !domiciliation.whitelistAllPaymentTypes) {
    return (
      <div className="sepaSection">
        <div className="text-center sepa-error-message">
          <div className={'info-shape large-shape warning-shape'}>
            <div className="first-cercle">
              <img src={InformationCircleO} className="inside-circle" />
            </div>
          </div>
          <div className="sepa-error-message-text">
            <span
              className="sub-gray-text"
              dangerouslySetInnerHTML={{
                __html: t('payments:sepa_unavailable'),
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  if (loadingStripeInformations) {
    return (
      <CommonLoader />
    )
  }

  return (
    <Mutation mutation={PAYMENT_SOURCE_CREATE_MUTATION}>
      {(paymentSourceCreate) => (
        <div className="sepaSection">
          <div>
            <input
              className="owner-account"
              type="text"
              onChange={handleOwnerNameChange}
              placeholder={t('payments:owner_account')}
            />
            <CountriesSelect
              onChange={(value: any) => setCountryCode(value.value)}
            />
            {ownerAddressIsRequired &&
              <input
                className="owner-account"
                type="text"
                onChange={handleOwnerAddressChange}
                placeholder={t('payments:owner_address')}
              />
            }
            <div className='sepa-wrapper'>
              <IbanElement options={createOptions} />
              {stripeError !== undefined && <div className="form-errors">{stripeError}</div>}
            </div>
          </div>
          <div>
            <button type='button' onClick={props.onCloseModal} className="section-button white-button mr-4">
              {t('common:cancel')}
            </button>
            <button type='button'
              onClick={(e) => handleSubmit(paymentSourceCreate, e)}
              disabled={submitting || !countryCode || (ownerAddressIsRequired && !ownerAddress)}
              className="section-button primary-button"
            >
              {t('common:record')}
            </button>
          </div>
          <div className="legals">{t('payments:legals')}</div>
        </div>
      )}
    </Mutation>
  )
}

export default SepaSection
