import gql from 'graphql-tag'
import { CURRENT_USER_FRAGMENT, USERS_FRAGMENT } from './Fragments'

export const CURRENT_USER_QUERY = gql`
{
  currentUser {
    ...CurrentUserFragment
  }
}

${CURRENT_USER_FRAGMENT}
`

export const DOMICILIATION_USERS_QUERY = gql`
{
  domiciliationUsers {
    ...UsersFragment
  }
}

${USERS_FRAGMENT}
`

export const USER_PASSWORD_UPDATE_MUTATION = gql`
    mutation userPasswordUpdate($oldPassword: String!, $newPassword: String!) {
        userPasswordUpdate(oldPassword: $oldPassword, newPassword: $newPassword) {
            user{
                ...CurrentUserFragment
            }
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

export const USER_UPDATE_NAME_MUTATION = gql`
    mutation userUpdateName($firstname: String!, $lastname: String!) {
        userUpdateName(firstname: $firstname, lastname: $lastname) {
            user{
                ...CurrentUserFragment
            }
        }
    }
    ${CURRENT_USER_FRAGMENT}
`

export const USER_INVITE_MUTATION = gql`
    mutation userInvite($email: String!, $roleId: ID!) {
        userInvite(email: $email, roleId: $roleId) {
            success
        }
    }
`

export const USER_UPDATE_ROLE_MUTATION = gql`
    mutation userUpdateRole($userId: ID!, $roleId: ID!) {
        userUpdateRole(userId: $userId, roleId: $roleId) {
            success
        }
    }
`

export const USER_REMOVE_MUTATION = gql`
    mutation userRemoveFromDomiciliation($userId: ID!) {
        userRemoveFromDomiciliation(userId: $userId) {
            success
        }
    }
`
