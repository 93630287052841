import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IProps } from './Interfaces'
import { Modal } from 'antd'
import Form from './Form'

const PaymentModal = (props: IProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      data-id="payment-source-create"
      open={true}
      onCancel={props.onCloseModal}
      footer={null}
      title={t('payments:payment_method')}
    >
      <Form {...props} />
    </Modal>
  )
}

export default PaymentModal
