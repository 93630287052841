import gql from 'graphql-tag'
import { DOMICILIATION_FRAGMENT } from './Fragments'

export const DOMICILIATION_HOSTER_UPDATE = gql`
  mutation domiciliationHosterUpdate(
    $hosted: Boolean!,
    $firstname: String,
    $lastname: String,
    $nationality: String,
    $country: String
  ) {
    domiciliationHosterUpdate(
      hosted: $hosted,
      firstname: $firstname,
      lastname: $lastname,
      nationality: $nationality,
      country: $country
    ) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
