import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import IAppointment from 'src/interfaces/Appointment'
import Routing from '@/CustomerArea/Routing'
import { animateScroll as scroll } from 'react-scroll'
import AppointmentDisplayed from './components/AppointmentDisplayed'

interface IProps {
  nextAppointments?: IAppointment[]
  pastAppointments?: IAppointment[]
}

interface ITopRightMenuProps {
  addNew: () => void
}

const TopRightMenu = (props: ITopRightMenuProps) => {
  const { t } = useTranslation()

  return (
    <div className="primary-button p-1 !text-neutral-100 my-2 block m-auto section-button text-center" onClick={props.addNew} style={{width: '170px'}}>
      {t('services:bookings.new')}
    </div>
  )
}

const Index: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [appointment, setAppointment] = useState<IAppointment | undefined>(undefined)

  const multipleAppointments = () => {
    let count = 0
    if (props.nextAppointments !== undefined) {
      count += props.nextAppointments.length
    }

    if (props.pastAppointments !== undefined) {
      count += props.pastAppointments.length
    }
    return count > 1 ? true : false
  }

  const AddNew = () => {
    navigate(Routing.SERVICES_ROOM_MEETINGS_BOOK)
  }

  const setCurrentAppointment = (appointmentArg: IAppointment) => {
    setAppointment(appointmentArg)
    scroll.scrollToTop({
      duration: 1000,
      delay: 50,
      smooth: 'easeInOutQuint'
    })
  }

  useEffect(() => {
    if (props.nextAppointments !== undefined && props.nextAppointments.length > 0) {
      setAppointment(props.nextAppointments[props.nextAppointments.length - 1])
    }
  }, [props.nextAppointments])

  if (props.nextAppointments !== undefined || props.pastAppointments !== undefined) {
    return (
      <div className="content-child appointments-index">
        <div className="justify-content-end">
          <TopRightMenu addNew={AddNew} />
        </div>
        <div className="desktop:grid grid-cols-4">
          {appointment && (
            <AppointmentDisplayed appointment={appointment}/>
          )}

          {multipleAppointments() && (
            <div className='col-span-3 p-4'>
              {props.nextAppointments !== undefined && props.nextAppointments.length > 1 && (
                <div className="next-appointments">
                  <h4 className="text-xl font-bold pb-2">{t('services:bookings:next_books')}</h4>
                  {props.nextAppointments.map((reservedRoom: IAppointment) => (
                    <div
                      key={reservedRoom?.id}
                      className={`px-2 py-4 border border-neutral-200 mb-2 cursor-pointer rounded flex flex-wrap ${appointment?.id === reservedRoom.id ? 'bg-primary-50' : ''}`}
                      onClick={() => setCurrentAppointment(reservedRoom)}
                    >
                      <div className="flex-1">
                        <p className="font-bold">{reservedRoom.room.name}</p>
                        <p>{reservedRoom.room.addressDetail.fullAddress}</p>
                      </div>

                      <div className="flex-1">
                        <p>
                          {moment(reservedRoom.startAt).utc().local().format('dddd D MMMM')}
                        </p>
                        <p>
                          <span>
                            {t('common:from')} {moment(reservedRoom.startAt).utc().format('LT')} {t('common:to')}{' '}
                            {moment(reservedRoom.endAt).utc().format('LT')}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {props.pastAppointments !== undefined && props.pastAppointments.length > 1 && (
                <div className="past-appointments">
                  <h4>{t('services:bookings:past_books')}</h4>
                  {props.pastAppointments.map((pastAppointment: IAppointment) => (
                    <div
                      key={pastAppointment.id}
                      className={`appointment-list-element ${
                        appointment && appointment.id === pastAppointment.id ? 'selected' : ''
                      }`}
                      onClick={() => setCurrentAppointment(pastAppointment)}
                    >
                      <div className="left-part">
                        <p className="room-title">{pastAppointment.room.name}</p>
                        <p className="room-address">{pastAppointment.room.addressDetail.fullAddress}</p>
                      </div>

                      <div className="right-part">
                        <p className="appointment-date">
                          {moment(pastAppointment.startAt).utc().local().format('dddd D MMMM')}
                        </p>
                        <p className="appointment-price">
                          <span className="shift">
                            {t('common:from')}
                            {moment(pastAppointment.startAt).utc().format('LT')} {t('common:to')}{' '}
                            {moment(pastAppointment.endAt).utc().format('LT')}
                          </span>
                        </p>
                      </div>
                      <div className="clearfix" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return <div>Aucune réservation réalisée</div>
  }
}

export default Index
