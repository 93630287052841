import gql from 'graphql-tag'
import { CART_FRAGMENT } from './Fragments'

export const CURRENT_CART_QUERY = gql`
{
  currentCart {
    ...CartFragment
  }
}

${CART_FRAGMENT}
`

export const CART_CREATE_AND_PAY = gql`
    mutation cartCreateAndPay($productId: ID!) {
        cartCreateAndPay(productId: $productId) {
            cart {
                ...CartFragment
            }
        }
    }
    ${CART_FRAGMENT}
`

export const CART_ADD_PURCHASES = gql`
    mutation cartAddPurchases($productIds: [ID!]!) {
        cartAddPurchases(productIds: $productIds) {
            cart {
                ...CartFragment
            }
        }
    }
    ${CART_FRAGMENT}
`

export const CART_REMOVE_PURCHASES = gql`
    mutation cartRemovePurchases($purchaseIds: [ID!]!) {
        cartRemovePurchases(purchaseIds: $purchaseIds) {
            cart {
                ...CartFragment
            }
        }
    }
    ${CART_FRAGMENT}
`

export const CART_PAYMENT_VALIDATING = gql`
    mutation cartPaymentValidating {
        cartPaymentValidating {
          cart {
                ...CartFragment
            }
        }
    }
    ${CART_FRAGMENT}
`

export const CART_PAYMENT_CONFIRM = gql`
  mutation cartPaymentConfirm {
    cartPaymentConfirm {
      cart {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`

export const CART_PAYMENT_ABORT = gql`
  mutation cartPaymentAbort {
    cartPaymentAbort {
      cart {
        ...CartFragment
      }
    }
  }
  ${CART_FRAGMENT}
`
