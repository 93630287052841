import { useContext, useEffect} from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'
import styled from 'styled-components'
import ProcedureStep from './ProcedureStep'
import NoticeInformation from '@/components/noticeInformation'
import LeaderInformations from './HostedStatus/leaderInformations'
import IContact from 'src/interfaces/Contact'
import { useTranslation } from 'react-i18next'
import Identity from './Identity'
import { DOMICILIATION_STEPS_STATUSES, KYC_LOGIC_RULES_QUERY } from '@/graphql/Domiciliation'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import useActionCable from '@/hooks/useActionCable'
import useChannel from '@/hooks/useChannel'
import { CommonLoader } from '@/components/shared/Loader'
import constants from '@/components/UIKit/constants'
import { Colors } from '@/components/UIKit'

const Procedures = () => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { contact }: { contact: IContact } = useContext(ContactContext)
  const [searchParams] = useSearchParams()
  const { loading, data } = useQuery(DOMICILIATION_STEPS_STATUSES)

  const host = new URL(process.env['REACT_APP_HOME_LINK_' + contact.countryConfig.countryCode] || '').host
  const protocol = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'ws' : 'wss'
  const { actionCable } = useActionCable(`${protocol}://${host}/cable`)
  const { subscribe, unsubscribe } = useChannel(actionCable)

  const stepStatusFor = (stepNumber) => {
    const status = data?.domiciliationStepsStatuses.find((item) => item.step === stepNumber).status
    return status
  }

  const kycInitialLogicRules = domiciliation.kycLogicRules.filter(
      (ruleIdentity: any) => ruleIdentity.rules.some((rule: any) => rule.initial === true)
  )

  const kycPostSignatureLogicRules = domiciliation.kycLogicRules.filter(
      (ruleIdentity: any) => ruleIdentity.rules.some((rule: any) => rule.initial === false)
  )

  const shareholders = kycInitialLogicRules.filter((item) => item.targetType === 'Shareholder')
  const otherThanShareholders = kycInitialLogicRules.filter((item) => item.targetType !== 'Shareholder')

  const contactName = {
    lastname: contact.personalInformation.leaderContactDetail.lastname,
    firstname: contact.personalInformation.leaderContactDetail.firstname
  }

  const { refetch } = useQuery(KYC_LOGIC_RULES_QUERY)

  useEffect(() => {
    subscribe({ channel: 'KycLogicRulesChannel' }, {
      received: (data) => {
        if (data.event === 'kyc_logic_rules_updated') {
          refetch()
        }
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const isOpen = (step: string) => {
    if (stepStatusFor(step) === 'todo') {
      return true
    } else {
      return false
    }
  }

  return loading ? (
    <CommonLoader />
  ) : (
    <ProceduresWrapper>
      <ProcedureStep
        title='Dossier de domiciliation'
        step={1}
        state={stepStatusFor('1')}
        opened={searchParams.get('continuekyc') === 'true' || isOpen('1')}
      >
        <NoticeInformation bgColor='orange'>{t('procedure:noticeInformation')}</NoticeInformation>
        <LeaderInformations contactName={contactName} />

        {otherThanShareholders.map((kycRule: any, index) => (
          <Identity
            targetName={kycRule.targetName}
            targetType={kycRule.targetType}
            targetId={kycRule.targetId}
            rules={kycRule.rules}
            contactDetail={kycRule.contactDetail}
            key={index}
            idx={index}
            moralShareholderName={kycRule.moralShareholderName}
          />
        ))}
        {shareholders.map((kycRule: any, index) => (
          <Identity
            targetName={kycRule.targetName}
            targetType={kycRule.targetType}
            targetId={kycRule.targetId}
            rules={kycRule.rules}
            contactDetail={kycRule.contactDetail}
            idx={index}
            key={index}
            moralShareholderName={kycRule.moralShareholderName}
          />
        ))}
      </ProcedureStep>

      <ProcedureStep
        title={'Signature des documents'}
        step={2}
        state={stepStatusFor('2')}
        locked={stepStatusFor('2') === 'locked'}
      >
        <SignText>
          Votre contrat de domiciliation et procuration postale sont disponibles pour signature
          <ButtonRightWrapper>
            <Button type='a' bgColor='orange' buttonStyle href={domiciliation.contractSignatureUrl} target='_blank'>
              {t('common:signMyContract')}
            </Button>
          </ButtonRightWrapper>
        </SignText>
      </ProcedureStep>

      <ProcedureStep
        title={'Mise en conformité'}
        step={3}
        state={stepStatusFor('3')}
        locked={stepStatusFor('3') === 'locked'}
      >
        <NoticeInformation bgColor='orange'>
          <>{t('procedure:conformityNotice')}</>
        </NoticeInformation>

        {kycPostSignatureLogicRules.map((kycRule: any, index) => (
          <Identity
            targetName={kycRule.targetName}
            targetType={kycRule.targetType}
            targetId={kycRule.targetId}
            rules={kycRule.rules}
            contactDetail={kycRule.contactDetail}
            key={index}
            idx={index}
            moralShareholderName={kycRule.moralShareholderName}
          />
        ))}
      </ProcedureStep>
    </ProceduresWrapper>
  )
}

export const SignText = styled.div`
  background-color: #F5F7FB;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  align-items: center;

  color: ${Colors.black};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
  }
`

export const ButtonRightWrapper = styled.div`
  color: ${Colors.white};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
    a {
      justify-content: center;
    }
  }
`


export const ProceduresWrapper = styled.div`
  padding: 0 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    padding: 0 10px;
  }
`

export const WhichDocument = styled.a`
  color: ${Colors.blue} !important;
  font-size: 14px;
  margin: 8px 0 24px 0;
`

export default Procedures
