import * as React from 'react'
import { useTranslation } from 'react-i18next'
import RoomBookingImageDesk from '@/images/room-booking-desk.png'
import GmapLink from 'src/components/shared/utilities/GmapLink'
import IAppointment from 'src/interfaces/Appointment'
import moment from 'moment'

interface IAppointmentDisplayedProps {
  appointment: IAppointment
}

const AppointmentDisplayed = (props: IAppointmentDisplayedProps) => {
  const { t } = useTranslation()
  const { appointment } = props

  return (
    <div className='p-4'>
      <div>
        <h4 className="text-xl font-bold pb-2">{t('services:bookings:room')}</h4>
        <img src={RoomBookingImageDesk} width="100px" className='my-2'/>
        <p className="text-lg font-bold pb-2">{appointment.room.name}</p>
      </div>

      <div className="pb-2">
        <h5 className="font-semibold pb-0.5">{t('services:bookings:book')}</h5>
        <p>
          {t('common:the')}{' '}
          {t('common:date', { date: moment(appointment.startAt).utc().local().format('DD MMM YYYY') })}
        </p>
        <p>
          {t('common:from')}{' '}
          {moment(appointment.startAt).utc().format('LT') }{' '}
          {t('common:to')}{' '}
          {moment(appointment.endAt).utc().format('LT') }
        </p>
      </div>

      <div className="pb-2">
        <h5 className="font-semibold pb-0.5">{t('services:bookings:location')}</h5>
        <p>
          {appointment.room.addressDetail.fullAddress}
        </p>
        { new Date(appointment.startAt) >= new Date() && (
          <GmapLink
            label="S'y rendre"
            classNames="primary-button p-1 !text-neutral-100 my-2 block section-button text-center"
            fullAddress={appointment.room.addressDetail.fullAddress}
          />
        )}
        { appointment.room.accessDetails && (
          <div className="pb-2">
            <h5 className="font-semibold pb-0.5">
              {t('services:bookings:access_details')}
            </h5>
            <p>
              {appointment.room.accessDetails}
            </p>
          </div>
        )}
      </div>

      { new Date(appointment.startAt) < new Date() && (
        <div className="pb-2">
          <h5 className="font-semibold pb-0.5">{t('services:bookings:cost')}</h5>
          <p>{t('common:currency', { value: appointment.price })} {t('common:exclTaxes')}</p>
        </div>
      )}
    </div>
  )
}

export default AppointmentDisplayed
