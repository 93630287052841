import gql from 'graphql-tag'

export const CANCELATION_PROCEDURE_CREATE_MUTATION = gql`
    mutation CancelationProcedureCreate(
        $reason: String!,
        $reasonOther: String,
        $address: String,
        $needHelp: Boolean,
        $cancelationExpectedDate: ISO8601DateTime
    ) {
        cancelationProcedureCreate(
            reason: $reason,
            reasonOther: $reasonOther,
            address: $address,
            needHelp: $needHelp,
            cancelationExpectedDate: $cancelationExpectedDate
        ) {
            id
        }
    }
`

export const CANCELATION_PROCEDURE = gql`
    {
      cancelationProcedure {
        id
        type
        reason
        reasonOther
        address
        state
        createdAt
        cancelationExpectedDate
      }
    }
`
