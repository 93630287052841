import * as React from 'react'
import ImageCasque from '@/images/casque.png'
import IHotline from '@/interfaces/Hotline'
import ImageDeleteIcon from '@/images/delete-icon.svg'
import ImageEditIcon from '@/images/edit-icon.svg'
import { MutationFunction } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { HOTLINE_DESTROY_MUTATION } from '@/graphql/Hotline'
import confirmAlert from '@/components/shared/ConfirmAlert'
import IGenericError from '@/interfaces/GenericError'
import { useNavigate } from 'react-router-dom'
import Routing from '@/CustomerArea/Routing'
import { DOMICILIATION_QUERY } from '@/graphql/Domiciliation'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@sedomicilier/pro-regular-svg-icons'
import useAlert from 'src/hooks/useAlert'
import { Divider, Dropdown } from 'antd'

interface IProps {
  hotline: IHotline
}

const Index = (props: IProps) => {
  const { t } = useTranslation()
  const { hotline } = props
  const navigate = useNavigate()
  const { setAlert } = useAlert()

  const edit = () => navigate(Routing.SERVICES_HOTLINES_FORM)

  const onDestroy = (destroy: MutationFunction) => {
    confirmAlert({
      title: t('common:areyousure'),
      message: t('services:hotlines.warningDestroy'),
      buttons: [
        {
          label: t('common:cancel'),
        },
        {
          primary: true,
          label: t('common:confirm'),
          onClick: destroy,
        },
      ],
    })
  }

  const onDestroyed = (data: { error: IGenericError }) => {
    if (data.error) {
      setAlert({ type: 'error', text: data.error.message, size: 'small' })
    } else {
      setAlert({
        type: 'success',
        text: t('services:service_stop_success'),
        size: 'small'
      })
      navigate(Routing.SERVICES_INDEX)
    }
  }

  if (!hotline || hotline.state !== 'activated') {
    return null
  }

  return (
    <div data-test='hotline-index' className='flex p-2 tablet:!p-4'>
      <div className='flex-1'>
        <img src={ImageCasque} />
        <div className='text-default mt-2'>
          {t('services:hotlines:secretariat')}
        </div>
        <div data-test='hotline-phone-number' className='font-semibold text-sm mt-2'>
          {hotline.hotlinePhoneNumber?.phoneNumber}
        </div>
        <div className='text-neutral-400 mt-2'>
          {t('services:hotlines:redirection_number')} :{' '}
          {hotline.originalPhoneNumber}
        </div>
        <p className="mt-2">
          {hotline.hotlinePlan?.name}
        </p>
      </div>
      <div className='flex-initial'>
        <Mutation
          mutation={HOTLINE_DESTROY_MUTATION}
          onCompleted={onDestroyed}
          refetchQueries={[{ query: DOMICILIATION_QUERY }]}
        >
          {(destroy) => {
            return (
              <Dropdown
                menu={{ items: [] }}
                overlayStyle={{ background: 'white', boxShadow: '0 5px 20px 0 #0000001a', borderRadius: '8px' }}
                dropdownRender={(menu) => (
                  <div>
                    {React.cloneElement(
                      menu as React.ReactElement,
                      { style: { boxShadow: 'none', background: 'none' } })}
                    <a onClick={edit} target='_blank' rel='noreferrer'
                      className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'>
                      <img
                        src={ImageEditIcon}
                        className='flex-initial'
                        width="13px"
                        height="13px"
                      />
                      <span className='flex-1 px-2 -mt-0.5'>
                        {t('common:modify')}
                      </span>
                    </a>
                    <Divider style={{ margin: 0 }} />
                    <a onClick={() => onDestroy(destroy)} target='_blank' rel='noreferrer'
                      className='flex p-3 hover:no-underline hover:bg-neutral-150
                    hover:text-neutral-600'>
                      <img
                        src={ImageDeleteIcon}
                        className='flex-initial'
                        width="13px"
                        height="13px"
                      />
                      <span className='flex-1 px-2 -mt-0.5'>
                        {t('common:resile')}
                      </span>
                    </a>
                  </div>
                )}
              >
                <button type="button"
                  className="plus-utils-button bg-neutral-0 !border !border-neutral-200">
                  <FontAwesomeIcon icon={faEllipsisH} />
                  {t('services:hotlines:more_actions')}
                </button>
              </Dropdown>
            )
          }}
        </Mutation>
      </div>
    </div>
  )
}

export default Index
