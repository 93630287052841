import React, { useContext } from 'react'
import styled from 'styled-components'
import UserMailAction from './UserMailAction'
import UserMailHistorical from './UserMailHistorical'
import UserMailPreview from './UserMailPreview'
import UserMailTags from './UserMailTags'
import BackLinkIcon from 'src/images/chevron-left-blue.svg'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { UserMailContext } from '../../contexts/UserMailContext'

const UserMailDetails = () => {
  const { width } = useWindowDimensions()
  const { setSelectedMail }: any = useContext(UserMailContext)
  return (
    <RightSideWrapper>
      {width <= 900 && <BackLink src={BackLinkIcon} alt="icon chevron gauche" onClick={() => setSelectedMail(null)} />}
      <UserMailPreview />
      <UserMailAction />
      <UserMailTags />
      <UserMailHistorical />
    </RightSideWrapper>
  )
}

export const RightSideWrapper = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
export const BackLink = styled.img`
  margin-bottom: 40px;
  &:hover {
    cursor: pointer;
  }
`

export default UserMailDetails
