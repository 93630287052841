import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { LEGAL_FORMALITIES } from 'src/graphql/Products'
import { INITIAL_LEGAL_FORMALITY } from 'src/graphql/LegalFormalities'
import { CommonLoader } from 'src/components/shared/Loader'
import LegalFormality from './Item'
import InformationModal from './InformationModal'
import Status from './Status'

const LegalFormalities = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(LEGAL_FORMALITIES)
  const [legalFormalityTokenModal, setLegalFormalityTokenModal] = useState<string | undefined>()


  const { data: dataInitialFormality, loading: loadingInitialFormality } = useQuery(INITIAL_LEGAL_FORMALITY)
  const initialLegalFormality = dataInitialFormality?.initialLegalFormality
  const tileWidth = !initialLegalFormality || initialLegalFormality?.identificationToken === 'transfert' ? 650 : 785

  const closeModal = () => (setLegalFormalityTokenModal(undefined))

  if (loading || loadingInitialFormality) {
    return <CommonLoader />
  }

  const formalitiesSalable = (data?.legalFormalities || []).length > 0
  if (!initialLegalFormality && !formalitiesSalable) {
    return false
  }

  return (
    <div className='pt-4 pb-2'>
      <h3>{t('services:legal_formalities.title')}</h3>
      { initialLegalFormality && <Status initialLegalFormality={initialLegalFormality} tileWidth={tileWidth} /> }
      {
        data?.legalFormalities?.map((legalFormality) => {
          return (
            <LegalFormality
              key={legalFormality.id}
              legalFormality={legalFormality}
              setLegalFormalityTokenModal={setLegalFormalityTokenModal}
              tileWidth={tileWidth}
            />
          )
        })
      }
      <InformationModal
        legalFormalityToken={legalFormalityTokenModal}
        closeModal={closeModal}
      />
    </div>
  )
}

export default LegalFormalities
