import React, { useContext, useState } from 'react'
import { DatePicker, Radio, RadioChangeEvent, Button, Form } from 'antd'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { useNavigate } from 'react-router'
import Routing from '@/CustomerArea/Routing'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'
import dayjs from 'dayjs'
import todayCalendarBlack from 'src/images/cancelation-procedure/today-calendar-black.svg'
import todayCalendarBlue from 'src/images/cancelation-procedure/today-calendar-blue.svg'
import upcomingCalendarBlack from 'src/images/cancelation-procedure/upcoming-calendar-black.svg'
import upcomingCalendarBlue from 'src/images/cancelation-procedure/upcoming-calendar-blue.svg'
import personalizePicture from 'src/images/cancelation-procedure/welcome-face.svg'

interface IProps {
  loading: boolean
  setValues: any
  values: any
}

const EndingDate: React.FC<IProps> = (props) => {
  const { loading, setValues, values } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const currentDate = new Date()
  const subscriptionEndingDate = moment(domiciliation.nextPaymentDate).format('DD/MM/YYYY')
  const noMoreThanYearly = domiciliation.paymentType === 'monthly' || domiciliation.paymentType === 'yearly'

  const [selectedDate, setSelectedDate] = useState<string | Date>(domiciliation.nextPaymentDate)
  const [selectedOption, setSelectedOption] = useState(1)
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState<string | undefined>(undefined)

  const onFinish = () => {
    setValues({ ...values, cancelationExpectedDate: selectedDate })
    navigate(Routing.SUBSCRIPTION_TERMINATION_CONFIRMATION)
  }

  const onGoBack = () => {
    navigate(Routing.SUBSCRIPTION_TERMINATION)
  }

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedOption(value)
    if (value === 1) {
      setSelectedDate(domiciliation.nextPaymentDate)
    }
    if (value === 2 && datePickerSelectedDate) {
      setSelectedDate(datePickerSelectedDate)
    }
  }

  const datePickerSelect = (date) => {
    const datePicked = moment(date.toString()).format()
    setDatePickerSelectedDate(datePicked)
    setSelectedDate(datePicked)
  }

  return (
    <EndingDateWrapper>
      <Form onFinish={onFinish}>
        <Radio.Group onChange={onChange} value={selectedOption} optionType="button" className="radio-group">
          <Radio value={1}>
            <img src={selectedOption === 1 ? todayCalendarBlue : todayCalendarBlack} alt="today calendar" />
            <div>Résilier maintenant</div>
          </Radio>
          <Radio value={2}>
            <img src={selectedOption === 1 ? upcomingCalendarBlack : upcomingCalendarBlue} alt="upcoming calendar" />
            <div>Choisir une date</div>
          </Radio>
        </Radio.Group>
        {selectedOption === 1 ? (
          <>
            <InformationNoticeWrapper>
              <InformationNotice>
                <img src={personalizePicture} alt="woman picture" />
                <div>
                  {noMoreThanYearly && (
                    <div>
                      {t('cancelationProcedure:flow.form.fields.date.informationCard.paymentType', {
                        paymentType: t(`cancelationProcedure:flow.form.fields.date.${domiciliation.paymentType}`),
                      })}
                    </div>
                  )}
                  <span>
                    {t('cancelationProcedure:flow.form.fields.date.informationCard.currentSubscription')}
                    <EndingDateStrong>{subscriptionEndingDate}. </EndingDateStrong>
                  </span>
                  <BlueAutomaticEnding>
                    {t('cancelationProcedure:flow.form.fields.date.informationCard.terminateNow', {
                      date: subscriptionEndingDate,
                    })}
                  </BlueAutomaticEnding>
                  <div>{t('cancelationProcedure:flow.form.fields.date.informationCard.availableUntil')}</div>
                </div>
              </InformationNotice>
            </InformationNoticeWrapper>
          </>
        ) : (
          <>
            <SelectYourDate>{t('cancelationProcedure:flow.form.fields.date.label')}</SelectYourDate>
            <Form.Item
              name="datePicker"
              rules={[{ required: true, message: t('cancelationProcedure:flow.form.fields.date.label') }]}
            >
              <DatePicker
                disabledDate={(d) => !d || d.isAfter(dayjs().add(4, 'years')) || d.isBefore(currentDate)}
                format="DD/MM/YYYY"
                onChange={(date) => datePickerSelect(date)}
                placeholder={t('cancelationProcedure:flow.form.fields.date.placeholder')}
              />
            </Form.Item>
            <InformationNoticeWrapper>
              <InformationNotice>
                <img src={personalizePicture} alt="woman picture" />
                <div>{t('cancelationProcedure:flow.form.fields.date.informationCard.selectedDate')}</div>
              </InformationNotice>
            </InformationNoticeWrapper>
          </>
        )}
        <div className="btns">
          <Button onClick={onGoBack} disabled={loading} type="default">
            {t(`cancelationProcedure:flow.form.fields.date.cancel`)}
          </Button>

          <Button disabled={loading} type="primary" htmlType="submit">
            {t(`cancelationProcedure:flow.form.fields.date.confirm`)}
          </Button>
        </div>
      </Form>
    </EndingDateWrapper>
  )
}

export const EndingDateWrapper = styled.div`
  .radio-group {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    label {
      height: 100%;
      box-shadow: none !important;
      border-radius: 8px !important;
      padding: 16px 70px;
      &:hover {
        color: #1576e1;
        border-color: #1576e1;
      }
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    margin: 16px 0;
  }
  .ant-picker {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    .ant-picker-input {
      .ant-picker-suffix {
        span {
          svg {
            fill: #1576e1;
          }
        }
      }
    }
  }
`
export const InformationNoticeWrapper = styled.div`
  font-size: 12px;
  font-family: 'Inter';
  color: #2f3437;
  background-color: #eef6ff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 32px;
`
export const InformationNotice = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  & > img {
    width: 32px;
  }
`
export const EndingDateStrong = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #171a1c;
`
export const BlueAutomaticEnding = styled.span`
  color: #1576e1;
  font-size: 14px;
  font-weight: 500;
`
export const SelectYourDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #171a1c;
`
export default EndingDate
