import AddBeneficialOwner from '@/app/LegalFlow/Capital/addBeneficialOwner'
import { CustomIcon, LegalflowSection, NoticeInformation } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  SHAREHOLDERS_QUERY,
  ShareholdersData
} from '@/graphql/queries/shareholders'
import { Spin } from 'antd'

const Capital: React.FC = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery<ShareholdersData>(SHAREHOLDERS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  return (
    <LegalflowSection title={t('capital:title')}>
      <p className='body-1 font-medium mb-2'>{t('capital:subtitle')}</p>
      <NoticeInformation
        bgColor='grey'
        icon={<CustomIcon icon={<span className={'ico-star-full text-m text-interaction'} />} />}
        className='body-2'
      >
        {t('capital:noticeInformation')}
      </NoticeInformation>
      { loading || !data ?
        <div className='flex justify-center items-center'>
          <Spin size='large' />
        </div> :
        <AddBeneficialOwner shareholders={data.shareholders} />}
    </LegalflowSection>
  )
}

export default Capital
