import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import UserAddressInformations from './shared/UserAddressInformations'
import RightPanel from './RightPanel'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { IUserMailContextValue, UserMailContext } from '../contexts/UserMailContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import ActionsMails from './ActionsMails'
import ListingMails from './ListingMails'


const UserMails: React.FC = () => {
  const { domiciliation }: any = useContext(DomiciliationContext)
  const affiliated = domiciliation.affiliated
  const { width } = useWindowDimensions()
  const [showLeftSide, setShowLeftSide] = useState(true)
  const [showRightSide, setShowRightSide] = useState(true)
  const { selectedMail } = useContext<IUserMailContextValue | Record<string, never>>(UserMailContext)

  useEffect(() => {
    if (width <= 900) {
      if (selectedMail) {
        setShowLeftSide(false)
        setShowRightSide(true)
      } else {
        setShowLeftSide(true)
        setShowRightSide(false)
      }
    } else {
      setShowLeftSide(true)
      setShowRightSide(true)
    }
  }, [width, selectedMail])

  return (
    <UserMailsWrapper>
      <LeftSide hasPadding={!affiliated} style={{ display: showLeftSide ? 'block' : 'none'}}>
        <UserAddressInformations />
        <ListingMails />
        <ActionsMails />
      </LeftSide>
      {showRightSide && (
        <RightSide>
          <RightPanel />
        </RightSide>
      )}
    </UserMailsWrapper>
  )
}

export const UserMailsWrapper = styled.div`
  display: flex;
`
export const LeftSide = styled.div`
  width: 60%;
  margin-top: -45px;
  padding: ${(props) => props.hasPadding ? '45px 40px 0 40px' : '45px 0 0 0'};
  border-right: 1px solid #e1e4e680;
  @media (max-width: 900px) {
    border: none;
    width: 100%;
    margin: 0 auto;
  }
`
export const RightSide = styled.div`
  width: 40%;
  padding: 0 45px;
  @media (max-width: 900px) {
    width: 100%;
    margin: 0 auto;
  }
`

export default UserMails
