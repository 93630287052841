class Session {
  private static readonly TOKEN_NAME = 'token'
  private static instance: Session

  private token: string
  private decodedToken: any
  private connectedAs: boolean
  private affiliate: boolean

  constructor() {
    this.token = ''
    this.decodedToken = ''
    this.connectedAs = false
    this.affiliate = false
  }

  public static get Instance() {
    return this.instance || (this.instance = new this())
  }

  public getSession(): string {
    return this.token
  }

  public getDecodedToken(): any {
    return this.decodedToken
  }

  public refreshSession(value: string): void {
    this.token = value
    try {
      this.decodedToken = this.parseJWT(value)
      if (this.connectedAs || this.affiliate) {
        sessionStorage.setItem(Session.TOKEN_NAME, value)
      } else {
        localStorage.setItem(Session.TOKEN_NAME, value)
      }
    } catch {
      window.location.href = `/`
    }
  }

  public setSession(value: string): void {
    try {
      this.decodedToken = this.parseJWT(value)
      this.token = value
      this.connectedAs = !!this.decodedToken.connected_as
      this.affiliate = !!this.decodedToken.affiliate
      if (this.connectedAs || this.affiliate) {
        sessionStorage.setItem(Session.TOKEN_NAME, value)
      } else {
        localStorage.setItem(Session.TOKEN_NAME, value)
      }
    } catch {
      window.location.href = `/`
    }
  }

  public initSession(): void {
    if (sessionStorage.getItem(Session.TOKEN_NAME)) {
      this.setSession(sessionStorage.getItem(Session.TOKEN_NAME) as string)
    } else if (localStorage.getItem(Session.TOKEN_NAME)) {
      this.setSession(localStorage.getItem(Session.TOKEN_NAME) as string)
    }
  }

  public clearSession(): void {
    if (this.connectedAs || this.affiliate) {
      sessionStorage.removeItem(Session.TOKEN_NAME)
    } else {
      localStorage.removeItem(Session.TOKEN_NAME)
    }
  }

  private parseJWT(value): any {
    if (!value) {
      return
    }
    const base64Url = value.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  }
}

export default Session.Instance
