import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommonLoader } from 'src/components/shared/Loader'
import { H3, Header, LinkBack, Logo} from './styles'
import LogoSedomicilier from 'src/images/sedomicilier_logo.svg'
import FirstStep from './FirstStep'
import { IUnloggedContactProps } from './ContactUnlogged'
import SecondStep from './SecondStep'
import NoInvoices from './NoInvoices'

interface IRegularizeFailedPaymentsUnloggedProps {
  regularizeInvoicesData?: IUnloggedContactProps['unloggedContact'],
  regularizeInvoicesError?: unknown,
  regularizeInvoicesLoading?: boolean
}

const RegularizeFailedPaymentsUnlogged: React.FC<IRegularizeFailedPaymentsUnloggedProps> = (props) => {
  const { t } = useTranslation()
  const { regularizeInvoicesData, regularizeInvoicesError, regularizeInvoicesLoading } = props

  const [step, setStep] = useState<number>(1)

  const onClose = () => {
    setStep(1)
  }

  return (
    <div className="regularize_modal">
      <div className="content">
        <Header>
          <Logo>
            <img src={LogoSedomicilier} />
          </Logo>
          {step === 2 && <LinkBack onClick={onClose}>{t('invoices:regularize.stepBack')}</LinkBack>}
        </Header>
        <div className="main">
          {step < 2 && regularizeInvoicesData &&
            <H3>
              {t('invoices:regularize.step')} {step}/2
            </H3>
          }
          {regularizeInvoicesLoading && <CommonLoader />}
          {!regularizeInvoicesLoading && !regularizeInvoicesError && !regularizeInvoicesData && (
            <NoInvoices />
          )}
          {regularizeInvoicesData && step === 1 &&
            <FirstStep regularizeInvoicesData={regularizeInvoicesData} setStep={setStep} />}
          {regularizeInvoicesData && step === 2 && (
            <SecondStep regularizeInvoicesData={regularizeInvoicesData} setStep={setStep} />
          )}
          {step === 3 &&
            <NoInvoices hasPaid={true} />
          }
        </div>
      </div>
    </div>
  )
}

export default RegularizeFailedPaymentsUnlogged
