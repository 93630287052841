import React from 'react'
import styled from 'styled-components'
import mailIcon from '@/images/icon-courrier.svg'
import packageIcon from '@/images/package-icon.svg'

interface IMailTypeIcon {
  mailType: string
}

const MailTypeIcon: React.FC<IMailTypeIcon> = ({ mailType }) => {
  return (
    <MailTypeIconWrapper>
      {mailType === 'Colis' ? (
        <img src={packageIcon} alt="icone courrier" />
      ) : (
        <img src={mailIcon} alt="icone courrier" />
      )}
    </MailTypeIconWrapper>
  )
}

export const MailTypeIconWrapper = styled.div`
  margin-right: 16px;
`

export default MailTypeIcon
