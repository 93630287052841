import { DOMICILIATION_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const UPDATE_SERVICE_INTEREST = gql`
  mutation serviceInterestUpdate($bankInterest: Boolean!, $accountantInterest: Boolean!) {
    serviceInterestUpdate(bankInterest: $bankInterest, accountantInterest: $accountantInterest) {
      domiciliation {
        ...DomiciliationFragment
      }
    }
  }
  ${DOMICILIATION_FRAGMENT}
`
