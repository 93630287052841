import { Fonts } from 'src/components/UIKit'
import styled from 'styled-components'
import constants from 'src/constants'

export const ActionsWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #F1F1F1);
  background: var(--Neutral-0, #FFF);
  padding: 16px;
  position: relative;

  .downloadZip {
    border-radius: 8px;
    background: var(--Neutral-1, #171A1C);
    height: 32px;
    color: white;
    &:hover {
      background-color: rgb(47, 52, 55) !important;
      color: white;
    }
  }
`
export const DrawerInMessage = styled.div`
  display: ${(props) => props.open ? 'block ' : 'none'};
  position: absolute;
  top: -2px;
  left: -4px;
  right: -4px;
  z-index: 1;
  height: calc(100% + 8px);


  h6 {
    color: var(--Neutral-1, #171A1C);
    font-family: ${Fonts.openSans};
    font-size: 14px;
    font-style: normal;
    line-height: 150%;
    font-size: 14px;
    font-weight: 600;
    color: rgb(23, 26, 28);
  }

  p {
    color: var(--Neutral-1, #171A1C);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 10px;
    margin-right: 20px;
    z-index: 2;
    width: 20px;
    height: 28px;
    text-align: center;
    opacity: 1;
    cursor: pointer;
  }
`
export const SuccessNotif = styled.div`
  background: var(--Primary-50, #E7F2FF);
  padding: 24px 26px 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #F1F1F1);
  height: calc(100% + 8px);
  left: -4px;
  right: -4px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    padding-top: 50px;
  }
`

export const ErrorNotif = styled.div`
  background: var(--Error-50, #FFDCDB);
  padding: 24px 26px 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-100, #F1F1F1);
  height: calc(100% + 8px);
  left: -4px;
  right: -4px;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    padding-top: 50px;
  }
`
