import { Drawer } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { RcFile } from 'antd/es/upload'
import { DrawerTitle } from './styles'
import BasicUpload from './basicUpload'

interface ProcedureDrawerProps {
  onClose: () => void
  open: boolean
  selectedFile: RcFile | null
  setSelectedFile: Dispatch<SetStateAction<RcFile | null>>
  drawerCategory: string | null
  drawerAllowDocumentTypes: string[]
  drawerTitle: string
  title: string
  onSendDocument: () => void
  children?: React.ReactNode
  setFileType: (string) => void
}

const ProcedureDrawer = ({
  selectedFile,
  setSelectedFile,
  onClose,
  open,
  drawerTitle,
  drawerAllowDocumentTypes,
  setFileType,
  title,
  onSendDocument,
  children,
  drawerCategory
}: ProcedureDrawerProps) => {
  useEffect(() => {
    setFileType(drawerAllowDocumentTypes[0]['fileType'])
  }, [drawerCategory])

  return (
    <Drawer
      title={drawerTitle}
      placement='right'
      onClose={onClose}
      open={open}
      zIndex={1000}
      width={400}
      headerStyle={{ textAlign: 'center', marginRight: '28px' }}
    >
      <DrawerTitle>{title}</DrawerTitle>
      <BasicUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} onSendDocument={onSendDocument}>
        {children}
      </BasicUpload>
    </Drawer>
  )
}

export default ProcedureDrawer
