import { Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  conform: boolean
  kind: string
  hasSigned: boolean
  form: any
}

const TerminationSubscriptionKind = (props: IProps) => {
  const { t } = useTranslation()
  const { conform, kind, hasSigned, form } = props

  const [selectOtherReason, setSelectOtherReason] = useState<boolean>(false)

  const transfertValues = [
    'external_own_personal_address',
    'external_competitor',
    'external_own_office_address',
  ]

  const unsatisfiedValues = [
    'external_service_complicated',
    'external_service_too_many_bugs',
    'external_service_high_price',
  ]

  const othersCompanies = ['Les Tricolores', 'Sofradom', 'Legalplace', 'Abcliv', 'Kandbaz', 'Autres']

  const question:string = t(`cancelationProcedure:flow.form.fields.reason.label.${kind}`, '')

  // TODO can be merged with 'kind' form item
  const buildOption = (value:string) => {
    return (
      <Select.Option value={value} key={`option-${value}`}>
        {t(`cancelationProcedure:flow.form.fields.reason.options.${value}`, '')}
      </Select.Option>
    )
  }

  const onChange = (reason) => {
    if (reason === 'external_competitor') {
      setSelectOtherReason(true)
    }
  }

  useEffect(() => {
    form.setFieldsValue( { reason: '' } )
    setSelectOtherReason(false)
  }, [kind])

  return (
    <>
      <Form.Item
        name="reason"
        data-test="subscription-resiliate-reason-field"
        label={question}
        rules={[{ required: true, message: t('cancelationProcedure:flow.form.fields.reason.errors.required_field')}]}
      >

        {/* # Transfert vers une adresse personnelle: 24 external_own_personal_address
        # Transfert vers un autre centre de domiciliation: 22 external_competitor
        # Transfert vers lieu d’exercice de l’activité: 25 external_own_office_address
        # Je n’ai jamais utilisé mon adresse de domiciliation: 20 external_project_dropped
        # Cessation d’activité: 21 external_enterprise_closed */}

        <Select
          onChange={(reason) => onChange(reason)}
        >
          {
            kind === 'transfert' && transfertValues.map( (value) => {
              return buildOption(value)
            })
          }
          {
            (kind === 'stop' && !conform) && buildOption('external_project_dropped')
          }
          {
            (kind === 'stop' && hasSigned) && buildOption('external_enterprise_closed')
          }
          {
            kind === 'unsatisfied' && unsatisfiedValues.map( (value) => {
              return buildOption(value)
            })
          }
        </Select>
      </Form.Item>
      {selectOtherReason &&
        <Form.Item
          name="reasonOther"
          data-test="subscription-resiliate-reason-other-field"
          rules={[{ required: true, message: t('cancelationProcedure:flow.form.fields.reason.errors.required_field')}]}
        >
          <Select>
            {othersCompanies.map((company) => {
              return (
                <Select.Option value={company} key={`option-${company}`}>
                  {company}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      }
    </>

  )
}

export default TerminationSubscriptionKind
