import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'
import { useMutation } from '@apollo/client'
import { ContactContext } from 'src/contexts/contactContext'
import { CurrentCartContext } from 'src/contexts/currentCartContext'
import IProduct from 'src/interfaces/Product'
import { CART_ADD_PURCHASES, CART_REMOVE_PURCHASES } from 'src/graphql/Cart'
import { openValidationPopup } from 'src/components/UIKit'
import AddProductIcon from './add-product.svg'
import AddProductHoverIcon from './add-product-hover.svg'
import RemoveProductHoverIcon from './remove-product-hover.svg'
import RemoveProductIcon from './remove-product.svg'

interface IProps {
  product: IProduct
}

const AddProduct = (props: IProps) => {
  const { product } = props
  const { t } = useTranslation()
  const currentCart = useContext(CurrentCartContext)
  const { contact }: any = useContext(ContactContext)

  const purchase = currentCart.purchases.find((e) => e.identificationToken === product.identificationToken)
  const onCart = !!purchase
  const purchasePaid = contact.purchases.find((e) => e.identificationToken === product.identificationToken)
  const formalityStep: string | undefined = purchasePaid?.legalFormality?.step
  const owned = !!purchasePaid && !(formalityStep === 'legal_authority_validation')

  const [cartAddPurchases] = useMutation(CART_ADD_PURCHASES)
  const [cartRemovePurchases] = useMutation(CART_REMOVE_PURCHASES)

  const addToCart = () => {
    cartAddPurchases({ variables: { productIds: [product.id] } }).then(() => {
      openValidationPopup(t('products:added'), product.title)
    })
  }

  const removeFromCart = () => {
    if (purchase) {
      cartRemovePurchases({ variables: { purchaseIds: [purchase.id] } })
    }
  }

  return (
    <Wrapper>
      {
        !onCart && !owned &&
        <div onClick={() => addToCart()}>
          <a href="javascript:;">Ajouter</a>
          <img className='inline default' src={AddProductIcon} />
          <img className='inline hover' src={AddProductHoverIcon} />
        </div>
      }
      {
        onCart &&
        <div onClick={() => removeFromCart()}>
          <a href="javascript:;">Retirer</a>
          <img className='inline default' src={RemoveProductIcon} />
          <img className='inline hover' src={RemoveProductHoverIcon} />
        </div>
      }
      {
        owned &&
        <span className="warn-label">
          {formalityStep ?
            `${t('common:step')}: ${t(`products:steps.${formalityStep}.title`)}` :
            t(`products:status.${purchasePaid.status}.title`)}
        </span>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;

    margin: 0 0 0 8px;
  }

  img.hover {
    display: none;
  }

  &:hover {
    a {
      color: ${Colors.darkBlue} !important;
    }

    img.hover {
      display: inline-block;
    }

    img.default {
      display: none;
    }
  }

  .warn-label {
    font-size: 12px;
    color: ${Colors.warning};
    background: ${Colors.warningBackground};
    padding: 6px 12px;
    border-radius: 1280px;
    cursor: default;
  }
`

export default AddProduct
