import React, { createContext, useContext } from 'react'
import { createContextualCan } from '@casl/react'
import { AbilityBuilder, Ability } from '@casl/ability'
import { CurrentUserContext } from 'src/contexts/currentUserContext'
import IPermission from 'src/interfaces/Permission'

export const AbilityContext = createContext({} as any)
export const Can = createContextualCan(AbilityContext.Consumer)

interface IProps {
  children: React.ReactNode
}

const AbilityContextProvider = (props: IProps) => {
  const currentUser = useContext(CurrentUserContext)
  const { can, rules } = new AbilityBuilder(Ability)

  if (currentUser) {
    currentUser.permissions.forEach((permission: IPermission) => {
      can(permission.action, permission.model)
    })
  }

  const ability = new Ability(rules)
  return <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
}

export default AbilityContextProvider
