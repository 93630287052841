import ContactUsDropdown from '../ContactUsDropdown'
import MobileMenu from './MobileMenu'
import AdministratorAccess from './AdministratorAccess'
import styled from 'styled-components'
import CartLink from 'src/components/shared/CartLink'
import DropdownContact from '../DropdownContact'
import { useLocation } from 'react-router-dom'

const Topbar = () => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <TopbarWrapper className='topbar top-right-navlinks'>
      <DropdownContact />
      <TopbarRightPart>
        <AdministratorAccess />
        <ContactUsDropdown />
        {
          !pathname.includes('legal-flow') &&
          <CartLink />
        }
        <MobileMenu />
      </TopbarRightPart>
    </TopbarWrapper>
  )
}

export const TopbarWrapper = styled.div`
  padding: 16px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 450px) {
    padding: 16px;
  }
`
export const TopbarContent = styled.div``
export const TopbarRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export default Topbar
