import * as React from 'react'
import ImageAr from '@/images/ar.svg'
import ImageDefaultLetter from '@/images/default-letter.svg'
import ImageDefaultLetterAr from '@/images/default-letter-ar.svg'
import ImageDefaultParcel from '@/images/default-parcel.png'
import IUserMail from '@/interfaces/UserMail'
import ITag from '@/interfaces/Tag'
import { UserMailMailType } from './Enum'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxAlt, faSearchPlus } from '@sedomicilier/pro-light-svg-icons'

export const MailType = (props: { type: string; className?: string }) => {
  return (
    <>
      {props.type === UserMailMailType.letterAr && (
        <img
          src={ImageAr}
          className={props.className || 'notification-content'}
        />
      )}
      {props.type === UserMailMailType.parcel && (
        <span className={`${props.className} notification-content parcel`}>
          <FontAwesomeIcon icon={faBoxAlt} />
        </span>
      )}
    </>
  )
}

export const Envelope = (props: { userMail: IUserMail }) => {
  if (props.userMail.scanFileThumbUrl) {
    return (
      <a href={props.userMail.scanFileUrl} target="_blank" rel="noreferrer">
        <div className="scan-container">
          <img src={props.userMail.scanFileThumbUrl} className="lettre" />
          <div className="overlay">
            <div className="see">
              <FontAwesomeIcon icon={faSearchPlus} />
            </div>
          </div>
        </div>
      </a>
    )
  } else if (props.userMail.mailType === UserMailMailType.letterAr) {
    return <img src={ImageDefaultLetterAr} className="lettre default" />
  } else if (props.userMail.mailType === UserMailMailType.parcel) {
    return <img src={ImageDefaultParcel} className="lettre default" />
  } else {
    return <img src={ImageDefaultLetter} className="lettre default" />
  }
}

export const Tag = (props: { tag?: ITag; defaultText?: string }) => {
  if (props.tag) {
    return (
      <div className="tag-name" style={{ color: props.tag.color }}>
        <label
          className="line"
          style={{
            backgroundColor: props.tag.color,
            borderColor: props.tag.color,
          }}
        />
        {props.tag.name}
      </div>
    )
  } else {
    return (
      <div className="tag-name to-define">{props.defaultText || '-'}</div>
    )
  }
}
