import React from 'react'
import IPaymentSource from 'src/interfaces/PaymentSource'
import { MutationFunction } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { useTranslation } from 'react-i18next'
import { PAYMENT_SOURCE_MARK_AS_DEFAULT_MUTATION } from 'src/graphql/PaymentSource'

const MarkAsDefaultLink = (
    props: IPaymentSource & {
    markAsDefault: (paymentSourceMarkAsDefault: MutationFunction) => void;
  },
) => {
  const { t } = useTranslation()

  if (props.currentSource) {
    return null
  } else if (props.state === 'expired') {
    return (
      <span className="margin-left no-link">
        Utiliser par défaut
      </span>
    )
  } else {
    return (
      <Mutation mutation={PAYMENT_SOURCE_MARK_AS_DEFAULT_MUTATION}>
        {(paymentSourceMarkAsDefault) => (
          <a
            href={void(0)}
            className="primary-link margin-left"
            onClick={props.markAsDefault.bind(
                props,
                paymentSourceMarkAsDefault,
            )}
          >
            {t('payments:by_default')}
          </a>
        )}
      </Mutation>
    )
  }
}

export default MarkAsDefaultLink
