import IUserMail from '@/interfaces/UserMail'
import { Divider, Form, Input, Modal, Radio } from 'antd'
import { USER_MAIL_SHARE_MUTATION } from '@/graphql/UserMail'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import mailIcon from 'src/images/courrier-grey-border.svg'
import IContactOfSharing from '@/interfaces/ContactOfSharing'
import styled from 'styled-components'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import colors from 'src/components/UIKit/colors'

interface UserMailShareProps {
  userMail: IUserMail
  onCloseModal: () => void
  setAlert: any
}

const UserMailShare: React.FC<UserMailShareProps> = ({ userMail, onCloseModal, setAlert }) => {
  const { t } = useTranslation('userMails')
  const { domiciliation }: any = useContext(DomiciliationContext)
  const contactOfSharings = domiciliation.contactOfSharings

  const [share] = useMutation(USER_MAIL_SHARE_MUTATION)

  const [radioValue, setRadioValue] = useState(undefined)

  const [disable, setDisable] = useState(false)

  const onFinish = (values) => {
    const { email, favoriteContact } = values
    setDisable(true)
    share({
      variables: {
        userMailIds: userMail.id,
        email: favoriteContact ? favoriteContact : email
      }
    }).then((res) => {
      const { data } = res
      setDisable(false)
      if (data.error) {
        setAlert({ type: 'error', text: data.error.message, size: 'large' })
      } else if (data.userMailShare && data.userMailShare.errors && data.userMailShare.errors.length) {
        setAlert({ type: 'error', text: t('userMails:userMailShare.new.errorTitle'), size: 'small' })
      } else {
        onCloseModal()
        setAlert({ type: 'success', text: t('userMails:userMailShare.new.successTitle'), size: 'small' })
      }
    })
  }
  return (
    <Modal
      open={true}
      onCancel={onCloseModal}
      footer={null}
      title={t('userMailShare.new.title', {
        id: userMail.id
      })}
      width={500}
      centered
    >
      <Divider />
      <Form name='mailShare' onFinish={onFinish} requiredMark={false} layout='vertical'>
        <Form.Item
          name='email'
          rules={[
            {
              message: t('userMailShare.new.validation.custom.required'),
              validator: (_, value) => {
                if (!value) {
                  if (radioValue) return Promise.resolve()
                  else return Promise.reject(new Error('Une erreur est survenue'))
                } else {
                  return Promise.resolve()
                }
              }
            }
          ]}
        >
          <Input
            name='email'
            prefix={<img src={mailIcon} alt='email icone' style={{ width: '18px' }} />}
            placeholder={t('userMailShare.new.emailPlaceholder')}
            style={{ height: '40px' }}
          />
        </Form.Item>
        <Divider style={{ fontSize: '12px', color: '#7b7e80', fontWeight: '400' }}>
          {t('userMailShare.new.contact.choose')}
        </Divider>

        <RadioButtons>
          <Form.Item name='favoriteContact'>
            <Radio.Group style={{ display: 'flex' }} onChange={(e) => setRadioValue(e.target.value)} value={radioValue}>
              {contactOfSharings.map((contactOfSharing: IContactOfSharing) => (
                <Radio
                  key={contactOfSharing.id}
                  value={contactOfSharing.id}
                  style={{
                    display: 'flex',
                    padding: '16px',
                    alignItems: 'center',
                    border: `1px solid ${colors.greyBox}`,
                    borderRadius: '8px',
                    height: '100%'
                  }}
                >
                  <div>
                    <p style={{ margin: '0' }}>
                      {contactOfSharing.firstname} {contactOfSharing.lastname}
                    </p>
                    <p style={{ margin: '0', color: '#7b7e80' }}>{contactOfSharing.email}</p>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </RadioButtons>

        <Button bgColor='blue' justifyContent='center' disabled={disable}>
          {t('userMailShare.new.share')}
        </Button>
      </Form>
    </Modal>
  )
}

export const RadioButtons = styled.div`
  display: flex;
  justify-content: space-between;
  input {
    left: unset !important;
  }
`

export default UserMailShare
