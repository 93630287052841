import { gql } from '@apollo/client'
import { CONTACT_FRAGMENT } from '../Fragments'

export const UPSERT_LEGAL_REPRESENTATIVE_PHYSICAL = gql`
  mutation upsertLegalRepresentativePhysical(
    $lastname: String!
    $firstname: String!
    $nationality: String!
    $leaderPosition: String!
    $phoneNumber: String!
    $email: String!
    $personallyExposed: Boolean
    $functionPerformed: String
    $cessationDate: ISO8601DateTime
    $closeRelationExposed: Boolean 
    $closeRelationFunctionPerformed: String
    $closeRelationType: String
  ) {
    upsertLegalRepresentativePhysical(
      lastname: $lastname
      firstname: $firstname
      nationality: $nationality
      leaderPosition: $leaderPosition
      phoneNumber: $phoneNumber
      email: $email
      personallyExposed: $personallyExposed
      functionPerformed: $functionPerformed
      cessationDate: $cessationDate
      closeRelationExposed: $closeRelationExposed
      closeRelationFunctionPerformed: $closeRelationFunctionPerformed
      closeRelationType: $closeRelationType
    ) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
