import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { CURRENT_CART_QUERY } from 'src/graphql/Cart'
import { CommonLoader } from 'src/components/shared/Loader'

interface IProps {
  children: React.ReactNode
}

export const CurrentCartContext = createContext({} as any)

const CurrentCartContextProvider = (props: IProps) => {
  const { data, loading } = useQuery(CURRENT_CART_QUERY, { fetchPolicy: 'cache-and-network' })
  const currentCart = data?.currentCart

  if (loading) {
    return <CommonLoader size={48} />
  }

  return (
    <CurrentCartContext.Provider value={currentCart}>
      {props.children}
    </CurrentCartContext.Provider>
  )
}

export default CurrentCartContextProvider
