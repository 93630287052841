import gql from 'graphql-tag'
import { SHAREHOLDER_FRAGMENT } from './Fragments/shareholder'

export const SHAREHOLDER_MUTATION = gql`
  mutation shareholderUpdate(
    $id: ID!
    $legalType: Kind!
    $lastname: String
    $firstname: String
    $name: String
    $nationality: String
    $capitalHeld: Int
  ) {
    shareholderUpdate(
      id: $id
      legalType: $legalType
      lastname: $lastname
      firstname: $firstname
      name: $name
      nationality: $nationality
      capitalHeld: $capitalHeld
    ) {
      shareholder {
        ...ShareholderFragment
      }
    }
  }
  ${SHAREHOLDER_FRAGMENT}
`
