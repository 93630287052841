import React, { useState, useEffect } from 'react'
import { default as Sidebar } from 'react-sidebar'
import SettingsNavigationLinks from './SettingsNavigationLinks'
import SettingsRouting from './SettingsRoutes'
import { Outlet, useLocation } from 'react-router-dom'

const mql = window.matchMedia('(min-width: 901px)')

const MainSettingsContentWithSidebar = () => {
  const location = useLocation()
  const [state, setState] = useState({
    sidebarDocked: mql.matches,
    sidebarOpen: location.pathname === '/settings'
  })

  const onSetSidebarState = (open: boolean): void => {
    setState({ ...state, sidebarOpen: open })
  }

  const mediaQueryChanged = () => {
    setState({ sidebarDocked: mql.matches, sidebarOpen: false })
  }

  useEffect(() => {
    mql.addListener(mediaQueryChanged)
    return () => mql.removeListener(mediaQueryChanged)
  }, [])

  return (
    <Sidebar
      sidebar={<SettingsNavigationLinks onSetSidebarState={onSetSidebarState} />}
      open={state.sidebarOpen}
      docked={state.sidebarDocked}
      onSetOpen={onSetSidebarState}
      contentId="overflowContent"
      sidebarClassName="sidebar-customize"
      rootClassName="root-sidebar-customize"
      shadow={false}
    >
      <Outlet />
      <SettingsRouting onSetSidebarState={onSetSidebarState} />
    </Sidebar>
  )
}

export default MainSettingsContentWithSidebar
