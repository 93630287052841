import * as React from 'react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Iframe from 'react-iframe'
import { Modal } from 'antd'

import styled from 'styled-components'

import Button from '@/components/UIKit/components/Button/buttonWithBorder'
import { ContactContext } from '@/contexts/contactContext'
import { CurrentCgvAcceptanceContext } from '@/contexts/currentCgvAcceptanceContext'
import { USER_CGV_ACCEPTANCE_RECORD_MUTATION } from '@/graphql/CgvAcceptance'
import { USER_NOTIFICATION_RECORD_MUTATION } from '@/graphql/UserNotification'

const CgvAcceptanceModal = () => {
  const { t } = useTranslation()
  const [userCgvAcceptanceRecord] = useMutation(USER_CGV_ACCEPTANCE_RECORD_MUTATION)
  const [userNotificationRecord] = useMutation(USER_NOTIFICATION_RECORD_MUTATION)
  const { contact }: any = useContext(ContactContext)
  const currentCgvAcceptance = useContext(CurrentCgvAcceptanceContext)
  const needDisplayModal = !contact?.cgvAlreadyAccepted
  const [openModal, setOpenModal] = useState(true)

  const closeModalWithoutSave = () => {
    saveAcceptanceNotification()
    closeModal()
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const saveAcceptance = () => {
    userCgvAcceptanceRecord({
      variables: {
        cgvAcceptanceUuid: currentCgvAcceptance?.uuid
      }
    }).then(() => {
      closeModal()
    })
  }

  const saveAcceptanceNotification = () => {
    userNotificationRecord({
      variables: {
        notifiableType: 'CgvAcceptance',
        notifiableId: currentCgvAcceptance?.id
      }
    })
  }

  if (!currentCgvAcceptance) {
    return false
  }

  if (!needDisplayModal) {
    return false
  }

  const footer = () => {
    return (
      <CgvModalFooterWrapper>
        {/* TODO FIX S3 FILE CREATED WITH CONTENT-TYPE 'application/pdf' see https://stackoverflow.com/questions/14150854/aws-s3-display-file-inline-instead-of-force-download */}
        {/* <a*/}
        {/*  href={currentCgvAcceptance?.fileUrl}*/}
        {/*  download*/}
        {/*  className="section-button white-button"*/}
        {/* >*/}
        {/*  {t('common:download')}*/}
        {/* </a>*/}
        <Button
          bgColor='orange'
          height='48px'
          width='95px'
          onClick={saveAcceptance}
          className='section-button white-button'
          justifyContent={'end'}
        >
          {t('common:agree')}
        </Button>
      </CgvModalFooterWrapper>
    )
  }

  return (
    <Modal
      data-id='cgv-acceptance-modal'
      open={openModal}
      onCancel={closeModalWithoutSave}
      maskClosable={false}
      footer={footer}
      title={t('cgvus:homeModal:title', { name: currentCgvAcceptance?.name })}
      className='modal-form modal-dialog-centered'
      width={'85%'}
    >
      <p>{t('cgvus:homeModal:textl1')}</p>
      <p>{t('cgvus:homeModal:textl2')}</p>
      <Iframe
        url={currentCgvAcceptance?.plainUrl}
        width='100%'
        height='600px'
        display='block'
        frameBorder={0}
        allowFullScreen={true}
        styles={{ left: 0, top: 0, zIndex: 9999 }}
        position='relative'
      />
    </Modal>
  )
}

export default CgvAcceptanceModal

export const CgvModalFooterWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: end;
`
