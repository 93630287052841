import React from 'react'
import IPaymentSource from 'src/interfaces/PaymentSource'
import { MutationFunction } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { PAYMENT_SOURCE_DESTROY_MUTATION } from 'src/graphql/PaymentSource'
import { useTranslation } from 'react-i18next'

const RemoveLink = (
    props: IPaymentSource & { remove: (paymentSourceDestroy: MutationFunction) => void },
) => {
  const { t } = useTranslation()

  if (props.currentSource) {
    return null
  } else {
    return (
      <Mutation mutation={PAYMENT_SOURCE_DESTROY_MUTATION}>
        {(paymentSourceDestroy) => (
          <a
            href={void(0)}
            className="primary-link"
            onClick={props.remove.bind(props, paymentSourceDestroy)}
          >
            {t('common:remove')}
          </a>
        )}
      </Mutation>
    )
  }
}

export default RemoveLink
