import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import Routing from '@/CustomerArea/Routing'

interface IProps {
  createProcedure: any
  loading: boolean
}

const TerminationWithdrawConfirm = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loading, createProcedure } = props
  const [form] = Form.useForm<{ reasonOther: string }>()
  const withdrawParams = {
    reason: 'external_project_dropped',
    finalized: false
  }

  const cancelWithdraw = () => {
    navigate(Routing.PROFILE)
  }

  const onFinish = (formValues: any) => {
    createProcedure({ ...withdrawParams, ...formValues })
  }

  return (
    <>
      <Form form={form} name='basic' onFinish={onFinish} layout='vertical' autoComplete='off'>
        <Form.Item
          label={t('cancelationProcedure:flow.form.fields.reasonOther.label')}
          name='reasonOther'
          data-test='subscription-resiliate-reasonOther-field'
        >
          <Input />
        </Form.Item>
        <div className='btns'>
          <Button onClick={cancelWithdraw} type='default'>
            {t(`cancelationProcedure:flow.form.withdraw.buttons.abort`)}
          </Button>

          <Button disabled={loading} type='primary' htmlType='submit'>
            {t(`cancelationProcedure:flow.form.withdraw.buttons.confirm`)}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default TerminationWithdrawConfirm
