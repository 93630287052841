import gql from 'graphql-tag'
import { PAYMENT_INTENT_FRAGMENT } from './Fragments'

export const PAYMENT_INTENT_PROCESSING = gql`
    mutation PaymentIntentProcessing($paymentIntentId: ID!) {
        paymentIntentProcessing(paymentIntentId: $paymentIntentId) {
            paymentIntent {
                ...PaymentIntentFragment
            }
        }
    }
    ${PAYMENT_INTENT_FRAGMENT}
`
