import React from 'react'

const CountryContext = React.createContext({countryCode: 'FR'})
export const Provider = CountryContext.Provider
export const Consumer = CountryContext.Consumer

let computedCountryCode = ''

if (process.env.REACT_APP_HOSTS_FR!.split(',').includes(window.location.host)) {
  computedCountryCode = 'FR'
} else if (process.env.REACT_APP_HOSTS_BE!.split(',').includes(window.location.host)) {
  computedCountryCode = 'BE'
}

interface IProps {
  children: React.ReactNode
}

export const CountryContextProvider = (props: IProps) => {
  return (
    <Provider value={{countryCode: computedCountryCode}}>
      {props.children}
    </Provider>
  )
}

export default CountryContext
