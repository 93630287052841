// @ts-nocheck
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { useMutation, useQuery } from '@apollo/client'
import { REGULARIZE_INVOICES_QUERY } from 'src/graphql/Invoices'
import { CommonLoader } from 'src/components/shared/Loader'
import { Alert } from 'antd'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { PAYMENT_SOURCE_CREATE_MUTATION } from 'src/graphql/PaymentSource'
import {
  Button,
  ClearBoth,
  CvcImg,
  ErrorTag,
  H2,
  H3,
  H4,
  Header,
  ImgCards,
  Label,
  LeftCheckbox,
  Light,
  LinkBack,
  Logo,
  RightLabel,
} from './styles'
import LogoSedomicilier from 'src/images/sedomicilier_logo.svg'
import ImgCreditCards from 'src/images/cards.svg'
import ImgCVC from 'src/images/cvc.svg'

interface IProps {
  close: () => void
  success: () => void
}

interface ICardInformation {
  cardNumber: boolean
  cardExpiry: boolean
  cardCvc: boolean
}

const RegularizeFailedPaymentsModal = (props: IProps) => {
  const { t } = useTranslation()
  const { close, success } = props
  const [paymentSourceCreate] = useMutation(PAYMENT_SOURCE_CREATE_MUTATION)

  const [step, setStep] = useState<number>(1)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<string>('')

  const cardInformation: ICardInformation = {} as ICardInformation

  const {
    data: regularizeInvoicesData,
    loading: regularizeInvoicesLoading,
    error: regularizeInvoicesError,
  } = useQuery(REGULARIZE_INVOICES_QUERY)

  const stripe: any = useStripe()
  const elements: any = useElements()

  const pay = (values: any) => {
    setFormLoading(true)
    const cardNumberElement = elements.getElement(CardNumberElement)
    stripe
        .createToken(cardNumberElement)
        .then(({ token, error }) => {
          if (error) {
            throw error.message
          }

          return stripe.confirmCardPayment(regularizeInvoicesData.paymentIntent.stripeClientSecret, {
            payment_method: {
              card: { token: token.id },
              billing_details: {
                name: values.name,
              },
            },
            setup_future_usage: values.keepCard ? 'off_session' : 'on_session',
          })
        })
        .then((data) => {
          if (data?.error?.message) {
            throw data.error.message
          }

          return paymentSourceCreate({
            variables: { token: data.paymentIntent.payment_method, forceDefault: values.keepCard },
          })
        })
        .then(() => {
          success()
          setStripeError('')
        })
        .catch((errorMsg) => {
          setStripeError(errorMsg)
        })
        .finally(() => {
          setFormLoading(false)
        })
  }

  const cardOnChange = (values: any) => (item: any) => {
    cardInformation[item.elementType] = item.complete && !item.error
    values[item.elementType] = item.complete && !item.error
  }

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(t('invoices:regularize.form.errors.required')),
    cardNumber: Yup.boolean().oneOf([true], t('invoices:regularize.form.errors.required')),
    cardExpiry: Yup.boolean().oneOf([true], t('invoices:regularize.form.errors.required')),
    cardCvc: Yup.boolean().oneOf([true], t('invoices:regularize.form.errors.required')),
  })

  return (
    <>
      <div className="regularize_modal">
        <div className="content">
          <Header>
            <Logo>
              <img src={LogoSedomicilier} />
            </Logo>
            <LinkBack onClick={close}>{t('invoices:regularize.back')}</LinkBack>
          </Header>
          <div className="main">
            <H3>
              {t('invoices:regularize.step')} {step}/2
            </H3>
            {regularizeInvoicesLoading && <CommonLoader />}
            {!regularizeInvoicesLoading && !regularizeInvoicesError && !regularizeInvoicesData && (
              <div>
                <span>{t('common:error')}</span>
              </div>
            )}
            {!regularizeInvoicesLoading && regularizeInvoicesData && !regularizeInvoicesError && step === 1 && (
              <div>
                <H2>Récapitulatif</H2>
                <div className="progress">
                  <div className="progress-bar" style={{ width: '50%' }} />
                </div>

                <div className="boxes">
                  <div className="box box-left">
                    <H4>{t('invoices:regularize.InvoicesToRegularize')}</H4>

                    <table className="table">
                      <tbody>
                        {regularizeInvoicesData.failedInvoices.map((invoice) => {
                          return (
                            <tr key={invoice.invoiceNumber} className="invoice-container unpaid">
                              <td>
                                <b>{invoice.invoiceNumber}</b>
                              </td>
                              <td>{invoice.formattedName}</td>
                              <td>
                                <Light>{t('common:date', { date: new Date(invoice.issueDate) })}</Light>
                              </td>
                              <td>
                                <b className="price-setting">{t('common:currency', { value: invoice.total })}</b>
                                <br />
                                <ErrorTag>{t('invoices:status.failure')}</ErrorTag>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="box box-right">
                    <H4>
                      {t('invoices:regularize.totalToPay')}&nbsp;
                      {t('common:currency', { value: regularizeInvoicesData.total })}
                    </H4>
                    {regularizeInvoicesData.failedInvoices.length}&nbsp;
                    {regularizeInvoicesData.failedInvoices.length > 1 ?
                      t('invoices:regularize.invoice') :
                      t('invoices:regularize.invoice')}
                    <br />
                    <a className="continue-button" onClick={() => setStep(2)}>
                      {t('invoices:regularize.continue')}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!regularizeInvoicesLoading && regularizeInvoicesData && !regularizeInvoicesError && step === 2 && (
              <div>
                <h4>{t('invoices:regularize.payment')}</h4>

                <div className="progress">
                  <div className="progress-bar" style={{ width: '100%' }} />
                </div>

                <Formik
                  initialValues={{
                    name: '',
                    cardNumber: false,
                    cardExpiry: false,
                    cardCvc: false,
                    keepCard: true,
                  }}
                  validationSchema={FormSchema}
                  onSubmit={(values) => {
                    pay(values)
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form>
                      <div className="boxes">
                        <div className="box box-left">
                          <h4>
                            {t('invoices:regularize.cbInformation')} <ImgCards src={ImgCreditCards} />
                          </h4>
                          <Label>
                            {t('invoices:regularize.form.name')}
                            <br />
                            <Field className="stripe-element" name="name" />
                            {errors.name && touched.name ? <div className="error-input">{errors.name}</div> : null}
                          </Label>
                          <Label>
                            {t('invoices:regularize.form.number')}
                            <br />
                            <CardNumberElement className="stripe-element" onChange={cardOnChange(values)} />
                            {errors.cardNumber && touched.cardNumber ? (
                              <div className="error-input">{errors.cardNumber}</div>
                            ) : null}
                          </Label>
                          <Label>
                            {t('invoices:regularize.form.date')}
                            <br />
                            <CardExpiryElement className="stripe-element-small" onChange={cardOnChange(values)} />
                            {errors.cardExpiry && touched.cardExpiry ? (
                              <div className="error-input">{errors.cardExpiry}</div>
                            ) : null}
                          </Label>
                          <Label>
                            {t('invoices:regularize.form.crypto')}
                            <br />
                            <CvcImg src={ImgCVC} />
                            <CardCvcElement className="stripe-element-small" onChange={cardOnChange(values)} />
                            {errors.cardCvc && touched.cardCvc ? (
                              <div className="error-input">{errors.cardCvc}</div>
                            ) : null}
                          </Label>
                          <Label>
                            <LeftCheckbox>
                              <Field type="checkbox" name="keepCard" />
                            </LeftCheckbox>
                            <RightLabel>{t('invoices:regularize.form.default')}</RightLabel>
                            <ClearBoth />
                          </Label>
                        </div>

                        <div className="box box-right">
                          <H4>
                            {t('invoices:regularize.totalToPay')}&nbsp;
                            {t('common:currency', { value: regularizeInvoicesData.total })}
                          </H4>
                          {regularizeInvoicesData.failedInvoices.length}&nbsp;
                          {regularizeInvoicesData.failedInvoices.length > 1 ?
                            t('invoices:regularize.invoice') :
                            t('invoices:regularize.invoice')}
                          {stripeError && <Alert message={stripeError} type="error" />}
                          <br />
                          <Button type="submit" disabled={formLoading}>
                            {t('invoices:regularize.form.submit')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RegularizeFailedPaymentsModal
