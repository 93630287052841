import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useStripe } from '@stripe/react-stripe-js'
import { useLocation, useNavigate } from 'react-router-dom'
import { CommonLoader } from '@/components/shared/Loader'
import { PAYMENT_SOURCE_CREATE_MUTATION } from '@/graphql/PaymentSource'
import { CART_PAYMENT_CONFIRM, CART_PAYMENT_ABORT, CURRENT_CART_QUERY } from '@/graphql/Cart'
import { Centred, Title, SubText, CircleInfoWrapper } from './styles'

const PaymentProcessing = (props: any) => {
  const {
    paymentIntentSecret,
    paymentMode,
    paymentMethod,
  } = props

  const stripe: any = useStripe()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const [paymentProcessing, setPaymentProcessing] = useState(true)
  const [error, setError] = useState<any>()

  const [cartPaymentConfirm] = useMutation(CART_PAYMENT_CONFIRM)
  const [cartPaymentAbort] = useMutation(CART_PAYMENT_ABORT)
  const [paymentSourceCreate] = useMutation(PAYMENT_SOURCE_CREATE_MUTATION)

  const url = location.pathname

  const confirmPaymentIntent = async () => {
    const confirmationRequest =
      paymentMode === 'card' ?
        stripe.confirmCardPayment(paymentIntentSecret, paymentMethod) :
        stripe.confirmSepaDebitPayment(paymentIntentSecret, paymentMethod)

    return confirmationRequest
  }

  const endProcessing = () => {
    setPaymentProcessing(false)
  }

  const handlePaymentResult = async (result) => {
    if (!result.error) {
      return (
        cartPaymentConfirm({ refetchQueries: [{ query: CURRENT_CART_QUERY }] }).then(() => {
          if (paymentMethod.setup_future_usage === 'off_session') {
            return (
              paymentSourceCreate({
                variables: { token: result.paymentIntent.payment_method, forceDefault: false },
                fetchPolicy: 'no-cache',
              })
            )
          } else {
            return null
          }
        })
      )
    } else {
      setError(result.error)
      return cartPaymentAbort({ refetchQueries: [{ query: CURRENT_CART_QUERY }] })
    }
  }

  useEffect(() => {
    confirmPaymentIntent().then((result) => {
      handlePaymentResult(result).then(endProcessing).catch(endProcessing)
    })
  }, [])

  useEffect(() => {
    if (!paymentProcessing) {
      if (!error) {
        navigate(`${url}/success`)
      } else {
        navigate(`${url}/failure`, {state: { data: error }})
      }
    }
  }, [paymentProcessing, error])

  return (
    <Centred>
      <CircleInfoWrapper>
        <CommonLoader size={60} />
      </CircleInfoWrapper>
      <Title>{t('payments:processing_payment')}</Title>
      <SubText>{t('payments:please_wait')}</SubText>
    </Centred>
  )
}

export default PaymentProcessing
