import React, { createContext, Dispatch, SetStateAction } from 'react'
import IUserMail from 'src/interfaces/UserMail'

export const UserMailContext = createContext<IUserMailContextValue | Record<string, never>>({})

export const UserMailContextProvider = UserMailContext.Provider

export interface IUserMailContextValue {
  selectedMail: IUserMail | undefined
  setSelectedMail: Dispatch<SetStateAction<IUserMail | undefined>>
}

export interface IUserMailContextProps {
  children: React.ReactNode
  value: IUserMailContextValue
}

const UserMailProvider = (props: IUserMailContextProps) => {
  return <UserMailContextProvider value={props.value}>{props.children}</UserMailContextProvider>
}

export default UserMailProvider
