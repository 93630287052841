import React, { useState } from 'react'
import { ActionsWrapper, DrawerInMessage, ErrorNotif, SuccessNotif } from './style'
import DownloadZip from './DownloadZip'
import CloseCross from 'src/images/modal/close-cross.svg'
import { useTranslation } from 'react-i18next'

const ActionsMails: React.FC = () => {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false)
  const [drawerError, setDrawerError] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const drawerContent = (errorMessage: string | undefined) => {
    if (errorMessage !== undefined) {
      return (
        <ErrorNotif>
          <h6>{t('userMails:downloadZip.errorTitle')}</h6>
          <div className="close" onClick={() => setDrawerOpened(false)}>
            <img src={CloseCross} />
          </div>
          <p>{errorMessage}</p>
        </ErrorNotif>
      )
    } else {
      return (
        <SuccessNotif>
          <h6>{t('userMails:downloadZip.confirmationTitle')}</h6>
          <div className="close" onClick={() => setDrawerOpened(false)}>
            <img src={CloseCross} />
          </div>
          <p>{t('userMails:downloadZip.confirmationText')}</p>
        </SuccessNotif>
      )
    }
  }

  return (
    <ActionsWrapper className="mt-6">
      <DownloadZip setDrawerOpened={setDrawerOpened} setDrawerError={setDrawerError} />
      <DrawerInMessage open={drawerOpened}>
        {drawerContent(drawerError)}
      </DrawerInMessage>
    </ActionsWrapper>
  )
}

export default ActionsMails
