import React, { SetStateAction } from 'react'
import { PhoneInput } from 'src/components/phoneInput'
import { Form, FormInstance, Select } from 'antd'
import Input from 'rc-input'
import { useTranslation } from 'react-i18next'
import { CountryCode } from 'libphonenumber-js'
import CountrySelect from '@/components/countries/countrySelect'
import { useQuery } from '@apollo/client'
import { LEADER_ROLES_QUERY, LeaderRolesData } from '@/graphql/queries/leaderRoles'

interface IdentityInformationsProps {
  form: FormInstance
  alpha2CountryCode: CountryCode
  setAlpha2CountryCode: (value: SetStateAction<CountryCode>) => void
}

const IdentityInformations: React.FC<IdentityInformationsProps> = ({
  form,
  alpha2CountryCode,
  setAlpha2CountryCode
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  const { data } = useQuery<LeaderRolesData>(LEADER_ROLES_QUERY)
  const leaderPositions = data?.leaderRoles

  const options = [
    {
      name: 'lastname',
      element: (
        <Input
          type='lastname'
          placeholder={t('common:placeholder.lastname')}
          className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
        />
      ),
      notice: t('legalRepresentative:identityInformations.notice.lastname')
    },
    {
      name: 'firstname',
      element: (
        <Input
          type='firstname'
          placeholder={t('common:placeholder.firstname')}
          className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
        />
      ),
      notice: t('legalRepresentative:identityInformations.notice.firstname')
    },
    {
      name: 'nationality',
      element: (
        <CountrySelect
          defaultValue={form.getFieldValue('nationality')}
          onSelect={(value) => form.setFieldsValue({ nationality: value })}
          className='h-5'
        />
      )
    },
    {
      name: 'leaderPosition',
      element: (
        <Select data-testid='leader-position-select' placeholder={t('common:select')} className='h-5'>
          {leaderPositions?.map((position, index) => (
            <Option key={position} value={position} data-testid={`position-${index}`}>
              {t(`legalRepresentative:leaderPositions.${position}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: 'phone',
      element: (
        <PhoneInput
          phoneTestid='phone-input'
          countryCodeTestid='country-code-select'
          form={form}
          defaultPhoneValue={form.getFieldValue('phone')}
          locale='FR'
          placeholder={t('common:placeholder.phone')}
          countryDefaultValue={alpha2CountryCode}
          setAlpha2CountryCode={setAlpha2CountryCode}
        />
      ),
      notice: t('legalRepresentative:identityInformations.notice.phone')
    },
    {
      name: 'email',
      element: (
        <Input
          placeholder={t('common:placeholder.email')}
          className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
        />
      )
    }
  ]

  return (
    <>
      {options.map((option) => (
        <div key={option.name} className='w-[calc(50%_-_8px)]'>
          <Form.Item
            key={option.name}
            name={option.name}
            label={t(`common:label.${option.name}`)}
            rules={[
              {
                required: true,
                message: t(`common:validation.${option.name}`)
              }
            ]}
            style={{ margin: 0 }}
          >
            {option.element}
          </Form.Item>
          {option.notice && (
            <div className='flex items-center gap-1 mt-1 text-neutral-500 text-6'>
              <span className='ico-info' />
              {option.notice}
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default IdentityInformations
