import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const CivilInformation = ({ personalInformation }) => {
  const { t } = useTranslation()
  const forceUTC = (date: Date) => {
    const computedDate = date.getTime() + date.getTimezoneOffset() * 60 * 1000
    return new Date(date.setTime(computedDate))
  }

  return (
    <CivilInformationsWrapper>
      <CivilInformationCell>
        <CivilInformationTitle>{t('profile:nationality')}</CivilInformationTitle>
        <CivilInfo>{personalInformation.leaderContactDetail.nationalityName}</CivilInfo>
      </CivilInformationCell>

      <CivilInformationCell border={true}>
        <CivilInformationTitle>{t('profile:birthday')}</CivilInformationTitle>
        <CivilInfo>
          {t('common:date', { date: forceUTC(new Date(personalInformation.leaderContactDetail.birthday)) })}
        </CivilInfo>
      </CivilInformationCell>

      <CivilInformationCell>
        <CivilInformationTitle>{t('profile:address')}</CivilInformationTitle>
        <CivilInfo>{personalInformation.leaderAddressDetail.fullAddress}</CivilInfo>
      </CivilInformationCell>
    </CivilInformationsWrapper>
  )
}

export const CivilInformationsWrapper = styled.div`
  border: 1px solid #e3e6e8;
  margin-bottom: 40px;
`
export const CivilInformationCell = styled.div`
  font-size: 14px;
  padding: 16px;
  ${(props) =>
    props.border &&
    `
    border-top: 1px solid #e3e6e8;
    border-bottom: 1px solid #e3e6e8;
  `}
`
export const CivilInformationTitle = styled.div`
  font-weight: 400;
  color: #6c767f;
  line-height: 150%;
`
export const CivilInfo = styled.div`
  font-weight: 500;
`

export default CivilInformation
