import * as React from 'react'
import { useContext } from 'react'
import { Centred } from 'src/components/UIKit'
import CardSection from './CardSection'
import SepaSection from './SepaSection'
import { IProps } from './Interfaces'
import { useTranslation } from 'react-i18next'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { Tabs } from 'antd'

const Form = (props: IProps) => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)

  if (domiciliation.availablePaymentMethods.length === 0) {
    return (
      <Centred>
        <span>Aucun type de paiement disponible pour votre compte. Merci de contacter le service client.</span>
      </Centred>
    )
  }

  const items: any[] = []
  if (domiciliation.availablePaymentMethods.includes('credit_card')) {
    items.push({
      label: t('payments:card'),
      key: '1',
      children: <CardSection {...props} />
    })
  }

  if (domiciliation.availablePaymentMethods.includes('sepa')) {
    items.push({
      label: t('payments:sepa'),
      key: '2',
      children: <SepaSection {...props} />
    })
  }

  return (
    <div>
      <Tabs items={items}>
      </Tabs>
    </div>
  )
}

export default Form
