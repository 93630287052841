export const Countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albanie' },
  { value: 'DZ', label: 'Algérie' },
  { value: 'AS', label: 'Samoa américaine' },
  { value: 'AD', label: 'Andorre' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctique' },
  { value: 'AG', label: 'Antigua et Barbuda' },
  { value: 'AR', label: 'Argentine' },
  { value: 'AM', label: 'Arménie' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australie' },
  { value: 'AT', label: 'Autriche' },
  { value: 'AZ', label: 'Azerbaidjan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbade' },
  { value: 'BY', label: 'Bielorussie' },
  { value: 'BE', label: 'Belgique' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Bénin' },
  { value: 'BM', label: 'Bermudes' },
  { value: 'BT', label: 'Bhoutan' },
  { value: 'BO', label: 'Bolivie' },
  { value: 'BA', label: 'Bosnie-Herzégovine' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Île Bouvet' },
  { value: 'BR', label: 'Brésil' },
  { value: 'IO', label: 'Océan Indien Britannique' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgarie' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodge' },
  { value: 'CM', label: 'Cameroun' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cap-Vert' },
  { value: 'KY', label: 'Caïmanes' },
  { value: 'CF', label: 'Centrafricaine, République' },
  { value: 'TD', label: 'Tchad' },
  { value: 'CL', label: 'Chili' },
  { value: 'CN', label: 'Chine' },
  { value: 'CX', label: 'Île Christmas' },
  { value: 'CC', label: 'Cocos' },
  { value: 'CO', label: 'Colombie' },
  { value: 'KM', label: 'Comores' },
  { value: 'CG', label: 'Congo, République populaire' },
  { value: 'CD', label: 'Congo, République démocratique' },
  { value: 'CK', label: 'Îles Cook' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte-d\'Ivoire' },
  { value: 'HR', label: 'Croatie' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Chypre' },
  { value: 'CZ', label: 'Tchéquie' },
  { value: 'DK', label: 'Danemark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominique' },
  { value: 'DO', label: 'République Dominicaine' },
  { value: 'EC', label: 'Équateur' },
  { value: 'EG', label: 'Égypte' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Guinée équatoriale' },
  { value: 'ER', label: 'Érythrée' },
  { value: 'EE', label: 'Estonie' },
  { value: 'ET', label: 'Éthiopie' },
  { value: 'FK', label: 'Îles Malouines' },
  { value: 'FO', label: 'Îles Féroé' },
  { value: 'FJ', label: 'Fidji' },
  { value: 'FI', label: 'Finlande' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'Guyane française' },
  { value: 'PF', label: 'Polynésie française' },
  { value: 'TF', label: 'Terres australes françaises' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambie' },
  { value: 'GE', label: 'Géorgie' },
  { value: 'DE', label: 'Allemagne' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Grèce' },
  { value: 'GL', label: 'Groenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GN', label: 'Guinée' },
  { value: 'GW', label: 'Guinée-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haïti' },
  { value: 'HM', label: 'Îles Heard-et-MacDonald' },
  { value: 'VA', label: 'Saint-Siège' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hongrie' },
  { value: 'IS', label: 'Islande' },
  { value: 'IN', label: 'Inde' },
  { value: 'ID', label: 'Indonésie' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IE', label: 'Irlande' },
  { value: 'IL', label: 'Israël' },
  { value: 'IT', label: 'Italie' },
  { value: 'JM', label: 'Jamaïque' },
  { value: 'JP', label: 'Japon' },
  { value: 'JO', label: 'Jordanie' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Corée du Nord, République populaire démocratique' },
  { value: 'KR', label: 'Corée du Sud, République' },
  { value: 'KW', label: 'Koweit' },
  { value: 'KG', label: 'Kirghistan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Lettonie' },
  { value: 'LB', label: 'Liban' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Libéria' },
  { value: 'LY', label: 'Libye' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lituanie' },
  { value: 'LU', label: 'Luxembourg, Grand-Duché' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macédoine, Ex-République yougoslave' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaisie' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malte' },
  { value: 'MH', label: 'Îles Marshall' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritanie' },
  { value: 'MU', label: 'Maurice' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexique' },
  { value: 'FM', label: 'Micronésie' },
  { value: 'MD', label: 'Moldavie' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolie' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Maroc' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibie' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Népal' },
  { value: 'NL', label: 'Pays-Bas' },
  { value: 'NC', label: 'Nouvelle-Calédonie' },
  { value: 'NZ', label: 'Nouvelle-Zélande' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigéria' },
  { value: 'NU', label: 'Niué' },
  { value: 'NF', label: 'Île Norfolk' },
  { value: 'MP', label: 'Mariannes du Nord' },
  { value: 'NO', label: 'Norvège' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Pérou' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Pologne' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Porto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Roumanie' },
  { value: 'RU', label: 'Russie' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'SH', label: 'Sainte-Hélène' },
  { value: 'KN', label: 'Saint-Christophe-et-Niévès' },
  { value: 'LC', label: 'Sainte-Lucie' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint-Vincent et les Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'Saint-Marin' },
  { value: 'ST', label: 'São Tomé et Principe' },
  { value: 'SA', label: 'Arabie Saoudite' },
  { value: 'SN', label: 'Sénégal' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapour' },
  { value: 'SK', label: 'Slovaquie' },
  { value: 'SI', label: 'Slovénie' },
  { value: 'SB', label: 'Salomon' },
  { value: 'SO', label: 'Somalie' },
  { value: 'ZA', label: 'Afrique du Sud' },
  { value: 'GS', label: 'Géorgie du Sud-et-les Îles Sandwich du Sud' },
  { value: 'ES', label: 'Espagne' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Soudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard et Île Jan Mayen' },
  { value: 'SZ', label: 'Ngwane, Royaume du Swaziland' },
  { value: 'SE', label: 'Suède' },
  { value: 'CH', label: 'Suisse' },
  { value: 'SY', label: 'Syrie' },
  { value: 'TW', label: 'Taïwan' },
  { value: 'TJ', label: 'Tadjikistan' },
  { value: 'TZ', label: 'Tanzanie, République unie' },
  { value: 'TH', label: 'Thaïlande' },
  { value: 'TL', label: 'Timor Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad et Tobago' },
  { value: 'TN', label: 'Tunisie' },
  { value: 'TR', label: 'Turquie' },
  { value: 'TM', label: 'Turkménistan' },
  { value: 'TC', label: 'Îles Turques-et-Caïques' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Ouganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'Émirats Arabes Unis' },
  { value: 'GB', label: 'Royaume-Uni' },
  { value: 'US', label: 'États-Unis d\'Amérique' },
  { value: 'UM', label: 'Îles mineures éloignées des États-Unis' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Ouzbékistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Îles vierges britanniques' },
  { value: 'VI', label: 'Îles vierges américaines' },
  { value: 'WF', label: 'Wallis et Futuna' },
  { value: 'EH', label: 'Sahara occidental' },
  { value: 'YE', label: 'Yémen' },
  { value: 'ZM', label: 'Zambie' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'AX', label: 'Åland' },
  { value: 'BQ', label: 'Bonaire, Saint-Eustache et Saba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'GG', label: 'Guernesey' },
  { value: 'IM', label: 'Île de Man' },
  { value: 'JE', label: 'Jersey' },
  { value: 'ME', label: 'Monténégro' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'MF', label: 'Saint-Martin (partie française)' },
  { value: 'RS', label: 'Serbie' },
  { value: 'SX', label: 'Saint-Martin (partie néerlandaise)' },
  { value: 'SS', label: 'Sud-Soudan' },
  { value: 'XK', label: 'Kosovo' },
]

export const getCountryObjectFromCode = (isoCode: string) => {
  return Countries.find((country) => country.value === isoCode)
}
