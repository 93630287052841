import React, { useContext } from 'react'
import TerminationSummaryBloc from './TerminationSummaryBloc'
import { TerminationSummaryBlocs, TerminationSummaryWrapper } from '../styles'
import { Alert } from 'antd'
import { CancelationProcedureContext } from '@/contexts/CancelationProcedureContext'
import { useTranslation } from 'react-i18next'

const TerminationSummary = () => {
  const { t } = useTranslation('cancelationProcedure')

  const { dataCancelationProcedure }:any = useContext(CancelationProcedureContext)
  const cancelationProcedure = dataCancelationProcedure?.cancelationProcedure

  return (
    <TerminationSummaryWrapper>
      {
        ( !cancelationProcedure) && <>
          <Alert
            className='full-width'
            message="Error"
            description={t('errors.missing')}
            type="error"
            showIcon={true}
          />
        </>
      }

      <h4>{t('summary.terminationBloc.title')}</h4>

      { cancelationProcedure && <>
        {
          (cancelationProcedure.needHelp === true) &&
            <Alert className={'full-width'} message={t('messages.create_completed')} type="info" showIcon={true} />
        }
      </>
      }

      <TerminationSummaryBlocs>
        <TerminationSummaryBloc/>
      </TerminationSummaryBlocs>

    </TerminationSummaryWrapper>
  )
}

export default TerminationSummary
