// @ts-nocheck
import React from 'react'
import { SubmitLoader } from '@/components/shared/Loader'
import PasswordStrength from '@/components/shared/PasswordStrength'
import { FormikProps, Form as FormikForm, Field } from 'formik'
import { useTranslation } from 'react-i18next'

interface IProps {
  onCloseModal: () => void;
}

interface IFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const Content = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation()
  const { touched, errors } = props

  return (
    <FormikForm>
      <div className="mb-2">
        <Field
          type="password"
          name="oldPassword"
          className={
            `border border-neutral-200 focus:outline-none focus:border-primary
            rounded-md w-full py-2 px-3 text-neutral-700
            ${props.touched.oldPassword && props.errors.oldPassword ? 'border-red-500' : ''
    }`}
          placeholder={t('security:old_password')}
        />
        {touched.oldPassword && errors.oldPassword && (
          <div className="text-error-700 text-sm mt-1">{errors.oldPassword}</div>
        )}
      </div>

      <div className="mb-2">
        <Field
          type="password"
          name="newPassword"
          className={
            `border border-neutral-200 focus:outline-none focus:border-primary
            rounded-md w-full py-2 px-3 text-neutral-700
            ${props.touched.newPassword && props.errors.newPassword ? 'border-red-500' : ''
    }`}
          placeholder={t('security:new_password')}
        />
        {touched.newPassword && errors.newPassword && (
          <div className="text-error-700 text-sm mt-1">{errors.newPassword}</div>
        )}
        <PasswordStrength password={props.values.newPassword} />
      </div>

      <div className="mb-2">
        <Field
          type="password"
          name="confirmPassword"
          className={
            `border border-neutral-200 focus:outline-none focus:border-primary
            rounded-md w-full py-2 px-3 text-neutral-700
            ${props.touched.confirmPassword && props.errors.confirmPassword ? 'border-red-500' : ''
    }`}
          placeholder={t('security:confirm_password')}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <div className="text-error-700 text-sm mt-1">{errors.confirmPassword}</div>
        )}
      </div>

      <div className="flex justify-center mb-2">
        <button
          onClick={props.onCloseModal}
          className="section-button white-button mr-4" type="button">
          {t('common:cancel')}
        </button>
        <button
          type="submit"
          className="submit section-button"
          disabled={props.isSubmitting}>
          <SubmitLoader isSubmitting={props.isSubmitting} />
          <span>{t('security:update_password')}</span>
        </button>
      </div>
    </FormikForm>

  )
}

export default Content
