import styled from 'styled-components'

const AnimatedChevron = ({ open }) => {
  return (
    <AnimatedChevronWrapper>
      <i className={`indicator glyphicon glyphicon-chevron-${open ? 'down' : 'up'}-custom  pull-right`}>
        <span className='sp-1'></span>
        <span className='sp-2'></span>
      </i>
    </AnimatedChevronWrapper>
  )
}

export const AnimatedChevronWrapper = styled.div`
  .glyphicon-chevron-down-custom span,
  .glyphicon-chevron-up-custom span {
    width: 8px;
    height: 5px;
    background-color: rgb(99, 99, 99);
    display: inline-block;
    transition: all 0.1s linear;
  }
  .glyphicon-chevron-down-custom .sp-1,
  .glyphicon-chevron-up-custom .sp-2 {
    transform: skewY(-40deg);
  }
  .glyphicon-chevron-up-custom .sp-1,
  .glyphicon-chevron-down-custom .sp-2 {
    transform: skewY(40deg);
  }
  .glyphicon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default AnimatedChevron
