import styled from 'styled-components'
import Colors from './colors'
import Fonts from './fonts'

// TODO: Remove precedence boost (&&) when globalStyle will be on
export const Highlighted = styled.span`
  && {
    display: inline-block;
    font-family: ${Fonts.openSansSemiBold};
    color: ${Colors.greyHeading};
    font-size: 14px;
    line-height: 24px;
  }
`

