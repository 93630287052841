import { useTranslation } from 'react-i18next'
import ExtraServiceCard from '@/app/LegalFlow/Partner/extraServiceCard'
import creditCardIcon from 'src/images/credit-card/credit-card.svg'
import accountantIcon from 'src/images/documents/accountant-documents.svg'

interface ExtraServicesProps {}

const ExtraServices: React.FC<ExtraServicesProps> = () => {
  const { t } = useTranslation()

  const options = [
    {
      id: 'bank',
      icon: creditCardIcon,
      tagLabel: t('partner:bank.tag')
    },
    {
      id: 'accountant',
      icon: accountantIcon
    }
  ]

  return (
    <div className='flex flex-col gap-4'>
      {options.map((option) => (
        <ExtraServiceCard key={option.id} id={option.id} tagLabel={option.tagLabel} icon={option.icon} />
      ))}
    </div>
  )
}

export default ExtraServices
