import mailIcon from 'src/images/mails/mails-in-folder.svg'
import { DashboardSectionTitle } from '../styles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import { useNavigate } from 'react-router'
import constants from 'src/constants'
import Routing from '@/CustomerArea/Routing'
import { useContext } from 'react'
import { NotificationContext } from 'src/contexts/notificationContext'

const UserMails = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { kyc, refetch: refetchNotification }: any = useContext(NotificationContext)
  refetchNotification()
  const mailCount = kyc?.userMails ?? 0


  const getRightTranslation = () => {
    switch (mailCount) {
      case 0:
        return t('dashboard:mails.noNewMail')
      case 1:
        return t('dashboard:mails.unreadMail', {
          nb: mailCount
        })
      default:
        return t('dashboard:mails.unreadMails', {
          nb: mailCount
        })
    }
  }

  const gotoMailsIndex = () => navigate(Routing.USER_MAILS_INDEX)
  // TODO MAJ auto quand viewed mail change, peut etre due au manque d'id dans la query
  return (
    <UserMailsWrapper>
      <DashboardSectionTitle>{t('dashboard:mails.title')}</DashboardSectionTitle>
      <UserMailsCountWrapper>
        <UserMailIcon src={mailIcon} alt='mail icone' />
        <UserMailText>
          {mailCount > 0 && t('dashboard:mails.youHave')} <UserMailNumber>{getRightTranslation()}</UserMailNumber>
        </UserMailText>
        <UserMailsActions>
          <Button bgColor='orange' onClick={gotoMailsIndex} height='48px' href='/courriers'>
            {t('dashboard:mails.readMails')}
          </Button>
        </UserMailsActions>
      </UserMailsCountWrapper>
    </UserMailsWrapper>
  )
}

export const UserMailsWrapper = styled.div``
export const UserMailsCountWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d3d7da;
  padding: 24px 32px;
  border-radius: 16px;
  flex-shrink: 1;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-flow: row wrap;
    justify-content: unset;
  }
`
export const UserMailsActions = styled.div`
  margin-left: auto;
  button {
    width: unset;
  }
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    margin-top: 12px;
    width: 100%;
    button {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
`
export const UserMailIcon = styled.img`
  margin: 0;
  margin-right: 16px;
`
export const UserMailText = styled.div`
  font-size: 16px;
`
export const UserMailNumber = styled.span`
  color: ${Colors.orange};
  font-weight: 600;
`

export default UserMails
