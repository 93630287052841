import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardSelect from './components/CardSelect'
import Routing from '@/CustomerArea/Routing'
import Cart from './components/Cart'
import Breadcrumb from 'src/components/shared/Breadcrumb'
import { Page } from 'src/components/UIKit'
import { ContentRow } from './styles'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ContactContext } from 'src/contexts/contactContext'
import { useMutation } from '@apollo/client'
import { CONTACT_UPDATE_ADDRESS_MUTATION } from 'src/graphql/Contact'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

interface IProps {
  setPaymentMethod: (paymentMethod: any) => void
  isSubmitting: boolean
  pay: any
}

const Billing = (props: IProps) => {
  const { setPaymentMethod, pay, isSubmitting } = props
  const { t } = useTranslation()
  const [form] = useForm()

  const { domiciliation }: any = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)
  const leaderAddress = contact?.personalInformation?.leaderAddressDetail

  const [disabledPaymentButton, setDisabledPaymentButton] = useState(false)

  const [createBillingInformation] = useMutation(CONTACT_UPDATE_ADDRESS_MUTATION)

  const breadcrumb = [{ text: t('common:back'), route: Routing.CART }]

  const onFinish = (values) => {
    // either full address exist or domiciliation, if no full address we let the server handle the missing leader full address
    if (leaderAddress?.fullAddress || domiciliation) {
      pay()
    } else {
      // We pass here if there is no domiciliation, mainly for solo formality
      createBillingInformation({
        variables: values,
        fetchPolicy: 'no-cache'
      }).then(() => {
        pay()
      })
    }
  }

  const initialValues = {
    streetAddress: leaderAddress.streetAddress || '',
    city: leaderAddress.city || '',
    postalCode: leaderAddress.postalCode || '',
    country: 'France'
  }

  return (
    <Page>
      <Breadcrumb elements={breadcrumb} />
      <Form
        name='basic'
        onFinish={onFinish}
        form={form}
        layout='vertical'
        requiredMark={false}
        initialValues={initialValues}
      >
        <ContentRow>
          {/* left side */}
          <CardSelect setPaymentMethod={setPaymentMethod} setDisabledPaymentButton={setDisabledPaymentButton} />

          {/* right side */}
          <Cart
            disabledButton={disabledPaymentButton || isSubmitting}
            paymentButtonText={t('payments:validate_payment')}
            displayButtonSpinner={isSubmitting}
            // confirmOnClick={pay}
            form={form}
          />
        </ContentRow>
      </Form>
    </Page>
  )
}

export default Billing
