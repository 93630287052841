import gql from 'graphql-tag'

export const INVITE_REFEREE_QUERY = gql`
    mutation ReferrerInviteReferee($email: String!) {
        referrerInviteReferee(email: $email) {
            referee {
              id
              status
              bonus
              refereeEmail
            }
        }
    }
`

export const REFERRAL_INFORMATIONS_QUERY = gql`
    query ReferralInformations {
        referralInformations {
          referrals {
            id
            status
            bonus
            refereeEmail
          }
          referrerCode
          confirmedCount
          balance
          referrerCurrentReduction
          referrerLowReduction
          referrerHighReduction
          refereeReduction
          totalReductionAcquired
        }
    }
`
