// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal, Input, Button, Checkbox } from 'antd'
import { useMutation } from '@apollo/client'
import {
  CONTACT_OF_SHARING_UPDATE_MUTATION,
  CONTACT_OF_SHARING_CREATE_MUTATION,
  CONTACT_OF_SHARING_QUERY,
} from 'src/graphql/ContactOfSharing'
import { openValidationPopup, openErrorPopup } from 'src/components/UIKit'

interface IProps {
  openModal: boolean
  setOpenModal: any
  contact?: any
}

const UpsertContactModal = (props: IProps) => {
  const { t } = useTranslation()
  const { contact } = props
  const [validationError, setValidationError] = useState<boolean>(false)

  const action = contact ? 'update' : 'create'

  const [
    updateContactOfSharing,
    { loading: updateLoading },
  ] = useMutation(CONTACT_OF_SHARING_UPDATE_MUTATION)

  const [
    createContactOfSharing,
    { loading: createLoading },
  ] = useMutation(CONTACT_OF_SHARING_CREATE_MUTATION, {
    refetchQueries: [
      { query: CONTACT_OF_SHARING_QUERY },
    ],
  })

  const mutate = action === 'create' ? createContactOfSharing : updateContactOfSharing

  const formik = useFormik({
    initialValues: {
      email: contact?.email,
      firstname: contact?.firstname,
      lastname: contact?.lastname,
      category: contact?.category,
      withProcuration: contact?.withProcuration,
    },
    validateOnChange: validationError,
    validateOnBlur: validationError,
    validationSchema: () => {
      return Yup.object().shape({
        email: Yup.string().email(t('common:detailed_errors.invalid', { field: t('common:emailAddress') })).required(
            t('team:upsert_contact.validation_contact_email'),
        ),
        firstname: Yup.string().required(
            t('team:upsert_contact.validation_contact_firstname'),
        ),
        lastname: Yup.string().required(
            t('team:upsert_contact.validation_contact_lastname'),
        ),
      })
    },
    onSubmit: (values, { resetForm }) => {
      mutate({
        variables: { id: contact?.id, ...values },
      })
          .then(() => {
            resetForm()
            openValidationPopup(
                t('team:upsert_contact.popup_success_title'),
                t('team:upsert_contact.popup_success_description', { email: values.email }),
            )
            props.setOpenModal(false)
          })
          .catch((e) => openErrorPopup(t('team:upsert_contact.popup_error_title'), e.message))
    },
  })

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setValidationError(true)
    }
  }, [formik.errors])

  return (
    <Modal
      centered={true}
      open={props.openModal}
      onCancel={() => props.setOpenModal(false)}
      wrapClassName="team-modal"
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formik.handleSubmit()}
          disabled={createLoading || updateLoading}
          loading={createLoading || updateLoading}
          style={{ background: '#1576E1', borderRadius: '4px' }}
        >
          {t('team:upsert_contact.button')}
        </Button>,
      ]}
    >
      <Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <h4>{t(`team:upsert_contact.titles.${action}`)}</h4>
          <div className="team-label">{t('team:upsert_contact.email_input.label')}</div>
          <Input
            id="email"
            name="email"
            placeholder={t('team:upsert_contact.email_input.placeholder')}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && (
            <div className="error-input">{formik.errors.email}</div>
          )}
          <div className="team-label">{t('team:upsert_contact.firstname_input.label')}</div>
          <Input
            id="firstname"
            name="firstname"
            placeholder={t('team:upsert_contact.firstname_input.placeholder')}
            onChange={formik.handleChange}
            value={formik.values.firstname}
          />
          {formik.errors.firstname && (
            <div className="error-input">{formik.errors.firstname}</div>
          )}
          <div className="team-label">{t('team:upsert_contact.lastname_input.label')}</div>
          <Input
            id="lastname"
            name="lastname"
            placeholder={t('team:upsert_contact.lastname_input.placeholder')}
            onChange={formik.handleChange}
            value={formik.values.lastname}
          />
          {formik.errors.lastname && (
            <div className="error-input">{formik.errors.lastname}</div>
          )}
          <div className="team-label">{t('team:upsert_contact.category_input.label')}</div>
          <Input
            id="category"
            name="category"
            placeholder={t('team:upsert_contact.category_input.placeholder')}
            onChange={formik.handleChange}
            value={formik.values.category}
          />
          <Checkbox
            id="withProcuration"
            name="withProcuration"
            checked={formik.values.withProcuration}
            onChange={formik.handleChange}
          >
            {t('team:upsert_contact.with_procuration')}
          </Checkbox>
        </form>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 25px;

  .ant-checkbox-wrapper {
    height: fit-content;
  }
`

export default UpsertContactModal

