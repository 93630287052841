import React, { useContext } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'antd'
import { useMutation } from '@apollo/client'
import { USER_UPDATE_ROLE_MUTATION } from 'src/graphql/User'
import { openValidationPopup, openErrorPopup } from 'src/components/UIKit'
import RoleRadioList from './RoleRadioList'
import { AbilityContext } from 'src/contexts/abilityContext'

interface IProps {
  openModal: boolean
  setOpenModal: any
  refetch: any
  userId: string
  roleId: number
  email: string
}

const UpdateRoleModal = (props: IProps) => {
  const { t } = useTranslation()
  const { userId, roleId, email } = props

  const [
    userUpdateRole,
    { loading },
  ] = useMutation(USER_UPDATE_ROLE_MUTATION)

  const ability: any = useContext(AbilityContext)
  const disabled = ability.cannot('customer_manage', 'User')

  const formik = useFormik({
    initialValues: {
      userId,
      email,
      roleId,
    },
    onSubmit: (values) => {
      userUpdateRole({
        variables: { ...values },
      })
          .then(() => {
            openValidationPopup(
                t('team:update_user_role.popup_success_title'),
                t('team:update_user_role.popup_success_description', { email: values.email }),
            )
            props.refetch()
            props.setOpenModal(false)
          })
          .catch((e) => openErrorPopup(t('team:update_user_role.popup_error_title'), e.message))
    },
  })

  return (
    <Modal
      centered={true}
      open={props.openModal}
      onCancel={() => props.setOpenModal(false)}
      wrapClassName="team-modal"
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formik.handleSubmit()}
          disabled={disabled || loading}
          loading={loading}
          style={{ background: '#1576E1', borderRadius: '4px' }}
        >
          {t('team:update_user_role.button')}
        </Button>,
      ]}
    >
      <Wrapper>
        <h4>{t('team:update_user_role.title')}</h4>
        <RoleRadioList formik={formik} disabled={disabled} />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 25px;
`

export default UpdateRoleModal

