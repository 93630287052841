import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { Radio, Space } from 'antd'
import { useQuery } from '@apollo/client'
import { ROLES_QUERY } from 'src/graphql/Role'
import { CommonLoader } from 'src/components/shared/Loader'

interface IProps {
  formik: FormikProps<any>
  disabled: boolean
}

const RoleRadioList = (props: IProps) => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(ROLES_QUERY)
  const { formik, disabled } = props

  return (
    <Wrapper>
      <div className="team-label">{t('team:invitation_modal.permission_block.label')}</div>
      <RadioBlock>
        <Radio.Group
          id="roleId"
          name="roleId"
          onChange={formik.handleChange}
          value={formik.values.roleId}
          disabled={disabled}
        >
          <Space direction="vertical">
            {
              loading ? (
                <div>
                  <CommonLoader />
                </div>
              ) : (
                data.roles.map((role: any) => {
                  return (
                    <Radio value={role.id} key={role.id}>
                      <RoleName>{t(`team:roles.${role.name}.name`)}</RoleName>
                      <br />
                      <RoleDescription>{t(`team:roles.${role.name}.choice_description`)}</RoleDescription>
                    </Radio>
                  )
                })
              )
            }
          </Space>
        </Radio.Group>
      </RadioBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-radio-wrapper {
    font-family: 'Open Sans';
    height: fit-content;
  }

  .ant-radio {
    top: 4px;
  }

  font-family: 'Open Sans';
`

const RadioBlock = styled.div`
  margin-top: 5px;
`

const RoleName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const RoleDescription = styled.span`
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #7B7E80;
`

export default RoleRadioList
