// @ts-nocheck
import { RcFile } from 'antd/es/upload'
import { Dispatch, SetStateAction, useState } from 'react'
import { Drawer } from 'antd'
import ArrowLeftBlack from 'src/images/arrow/arrow-left-black-reactnode'
import { Choice } from '../identityDrawers'
import IdentitySelector from './identitySelector'

export interface FileStates {
  selectedFile: RcFile | null
  setSelectedFile: Dispatch<SetStateAction<RcFile | null>>
  setFileType: (string) => void
  fileVerso: RcFile | null
  setFileVerso: Dispatch<SetStateAction<RcFile | null>>
}
interface IdentityProofDrawerProps {
  states: FileStates
  onClose: () => void
  open: boolean
  drawerCategory: string | null
  drawerAllowDocumentTypes: string[]
  drawerTitle: string
  onSendDocument: () => void
  selected: Choice
  setSelected: Dispatch<SetStateAction<Choice>>
  choices: Choice[]
  showFirstStep: boolean
  children?: React.ReactNode
}

const IdentityProofDrawer = ({
  states,
  onClose,
  open,
  drawerTitle,
  onSendDocument,
  selected,
  setSelected,
  choices,
  showFirstStep
}: IdentityProofDrawerProps) => {
  const { selectedFile } = states

  const [confirm, setConfirm] = useState(false)

  return (
    <Drawer
      title={drawerTitle}
      placement='right'
      onClose={confirm && !selectedFile ? () => setConfirm(false) : onClose}
      open={open}
      zIndex={1000}
      width={400}
      headerStyle={{ textAlign: 'center', marginRight: '28px' }}
      closeIcon={<ArrowLeftBlack />}
    >
      <IdentitySelector
        showFirstStep={showFirstStep}
        choices={choices}
        selected={selected}
        setSelected={setSelected}
        states={states}
        onSendDocument={onSendDocument}
        confirm={confirm}
        setConfirm={setConfirm}
      />
    </Drawer>
  )
}

export default IdentityProofDrawer
