import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface StatusCategoryProps {
  status: string | undefined
  setStatus: Dispatch<SetStateAction<string | undefined>>
}

const StatusCategory: React.FC<StatusCategoryProps> = ({ status, setStatus }) => {
  const { t } = useTranslation()
  const options = [
    {
      id: 'physical',
      label: t('legalRepresentative:type.physical'),
      icon: <span className='ico-d-silhouette' />
    },
    {
      id: 'moral',
      label: t('legalRepresentative:type.morale'),
      icon: <span className='ico-d-building' />
    }
  ]
  return (
    <div className='flex gap-2 mb-4'>
      {options.map((item) => {
        const selected = item.id === status

        const bord = selected ? '!border-2 !border-interaction' : 'border p-3 border-neutral-200'

        return (
          <div
            key={item.id}
            className={`flex flex-1 gap-2 items-center justify-center h-fit p-3 rounded-medium cursor-pointer ${bord}`}
            onClick={() => setStatus(item.id)}
          >
            {item.icon}
            <span>{item.label}</span>
          </div>
        )
      })}
    </div>
  )
}

export default StatusCategory
