/* eslint-disable camelcase */
import IPaymentSource from '@/interfaces/PaymentSource'
import IFailedPayment from '@/interfaces/FailedPayment'

export enum InvoiceOrderColumn {
  issue_date = 'issue_date'
}

export enum InvoiceOrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export interface IOrder {
  column: InvoiceOrderColumn
  direction: InvoiceOrderDirection
}

export interface IRetryPaymentProps {
  paymentSources: IPaymentSource[],
  unpaidInvoice: IFailedPayment
  onCloseModal: () => void
  onSuccess: () => void
  alertFailedPayment: () => void,
}
