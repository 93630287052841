import * as React from 'react'
import { useState } from 'react'
import { Drawer, Form, Input } from 'antd'
import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'
import useAlert from 'src/hooks/useAlert'
import { SubmitLoader } from 'src/components/shared/Loader'

interface BelgiumPPDrawer {
  onClose: () => void
  open: boolean
  updateConformity: any
}
const BelgiumPPDrawer: React.FC<BelgiumPPDrawer> = ({ onClose, open, updateConformity }) => {
  const { setAlert } = useAlert()
  const [submitting, setSubmitting] = useState(false)

  const onCompleted = () => {
    setSubmitting(false)
    setAlert({ type: 'success', size: 'small', text: 'Mise à jour réussie' })
    onClose()
  }

  const onFinish = (values) => {
    setSubmitting(true)
    updateConformity({
      variables: {
        siren: values.siren,
        vatNumber: values.vat
      }
    }).then(async (res) => {
      await res
      onCompleted()
    })
  }
  return (
    <Drawer title='Votre procuration postale' placement='right' onClose={onClose} open={open} zIndex={1000} width={400}>
      <Form layout='vertical' hideRequiredMark onFinish={onFinish}>
        <Form.Item
          name='siren'
          label='N°BCE'
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner votre N°BCE'
            }
          ]}
        >
          <Input placeholder='0XXX XXX XXX' />
        </Form.Item>
        <Form.Item
          name='vat'
          label='N°TVA intra-communautaire'
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner votre N°TVA'
            }
          ]}
        >
          <Input placeholder='Exemple BE 0123.456.789' />
        </Form.Item>
        <PPButton disabled={submitting} className='section-button primary-button'>
          <SubmitLoader isSubmitting={submitting} />
          {submitting ? 'Veuillez patienter' : 'Valider'}
        </PPButton>
      </Form>
    </Drawer>
  )
}

export const DrawerTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.black};
  margin-bottom: 32px;
`
export const PPButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export default BelgiumPPDrawer
