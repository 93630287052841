import React, { Dispatch, SetStateAction } from 'react'
import { ErrorTag, H2, H4, Light } from './styles'
import { IUnloggedContactProps } from './ContactUnlogged'
import { useTranslation } from 'react-i18next'

interface IFirstStepProps {
  regularizeInvoicesData: IUnloggedContactProps['unloggedContact']
  setStep: Dispatch<SetStateAction<number>>
}

const FirstStep: React.FC<IFirstStepProps> = ({ regularizeInvoicesData, setStep }) => {
  const { t } = useTranslation()
  return (
    <div>
      <H2>Récapitulatif</H2>
      <div className="progress">
        <div className="progress-bar" style={{ width: '50%' }} />
      </div>

      <div className="boxes">
        <div className="box box-left">
          <H4>{t('invoices:regularize.InvoicesToRegularize')}</H4>

          <table className="table">
            <tbody>
              {regularizeInvoicesData.publicFailedPayments.map((invoice) => {
                return (
                  <tr key={invoice.invoiceNumber} className="invoice-container unpaid">
                    <td>
                      <b>{invoice.invoiceNumber}</b>
                    </td>
                    <td>{invoice.formattedName}</td>
                    <td>
                      <Light>{t('common:date', { date: new Date(invoice.issueDate) })}</Light>
                    </td>
                    <td>
                      <b className="price-setting">{t('common:currency', { value: invoice.total })}</b>
                      <br />
                      <ErrorTag>{t('invoices:status.failure')}</ErrorTag>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="box box-right">
          <H4>
            {t('invoices:regularize.totalToPay')}&nbsp;
            {t('common:currency', { value: regularizeInvoicesData.regularizationInformations.total })}
          </H4>
          {regularizeInvoicesData.publicFailedPayments.length}&nbsp;
          {regularizeInvoicesData.publicFailedPayments.length > 1 ?
            t('invoices:regularize.invoice') :
            t('invoices:regularize.invoice')}
          <br />
          <a className="continue-button" onClick={() => setStep(2)}>
            {t('invoices:regularize.continue')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default FirstStep
