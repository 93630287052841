import gql from 'graphql-tag'
import { CONTACT_FRAGMENT, SOCIETY_FRAGMENT } from './Fragments'


export const CONTACTS_QUERY = gql`
  {
    contacts {
      id
      domiciliationStatus
      society {
        ...SocietyFragment
      }
    }
  }
  ${SOCIETY_FRAGMENT}
`

export const CONTACT_QUERY = gql`
  {
    contact {
      ...ContactFragment
    }
  }
  ${CONTACT_FRAGMENT}
`

export const CONTACT_SELECT_MUTATION = gql`
  mutation ContactSelect($contactId: ID!) {
    contactSelect(contactId: $contactId) {
      token
    }
  }
`

export const CONTACT_UPDATE_ADDRESS_MUTATION = gql`
  mutation contactUpdateAddress($streetAddress: String!, $postalCode: String, $city: String!, $country: String!) {
    contactUpdateAddress(streetAddress: $streetAddress, postalCode: $postalCode, city: $city, country: $country) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
