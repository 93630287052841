import { INVOICES, PAYMENT_INFORMATIONS, ROOT } from '@/CustomerArea/settings/SettingsRoutes'
import Routing from '@/CustomerArea/Routing'

const sectionMatches = {
  dashboard: Routing.ROOT,
  payment: PAYMENT_INFORMATIONS,
  profile: Routing.PROFILE,
  user_mails: Routing.USER_MAILS_INDEX,
  settings: ROOT,
  invoices: INVOICES,
  profile_legal_files: Routing.PROFILE_LEGAL_FILES,
  profile_contract: Routing.PROFILE_ATTESTATIONS,
  verification: Routing.VERIFICATION,
  subscription_termination: Routing.SUBSCRIPTION_TERMINATION
}
export default sectionMatches
