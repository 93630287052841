import { useContext } from 'react'
import Routing from '@/CustomerArea/Routing'
import { useTranslation } from 'react-i18next'
import { AbilityContext } from 'src/contexts/abilityContext'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { NotificationContext } from 'src/contexts/notificationContext'
import SideMenuSection from './SideMenuSection'
import styled from 'styled-components'
import constants from 'src/constants'
import navigationSections from 'src/components/layouts/SideMenu/NavigationSections'

const SideMenu = () => {
  const { t } = useTranslation()
  const ability: any = useContext(AbilityContext)
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { kyc }: any = useContext(NotificationContext)

  const sideMenuSections = navigationSections({ domiciliation, Routing, kyc, ability, t })

  return (
    <SideMenuWrapper>
      {sideMenuSections.map((section) => {
        return (
          <SideMenuSection
            key={section.dataSpecId}
            display={section.display}
            route={section.route}
            authorized={section.authorized}
            dataSpecId={section.dataSpecId}
            icon={section.icon}
            logo={section.logo}
            affiliated={section.affiliated}
            notification={section.notification}
            sectionTitle={section.sectionTitle}
          />
        )
      })}
    </SideMenuWrapper>
  )
}

export const SideMenuWrapper = styled.div`
  width: 232px;
  min-width: 232px;
  background-color: #000f46;

  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    display: none;
  }
`

export default SideMenu
