import React from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { compact } from '@sedomicilier/material-sd'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ALL_LEGAL_STATUSES_QUERY, AllLegalStatusesDataType } from '@/graphql/queries/legalStatus'
import AddressFormItems from '@/components/UIKit/components/AddressFormItems'

interface FormContenProps {
  form: FormInstance
  baseField?: number | string
  listName?: number | string
}

const SocietySearchManual: React.FC<FormContenProps> = ({ form, baseField, listName }) => {
  const { t } = useTranslation()

  const { data } = useQuery<AllLegalStatusesDataType>(ALL_LEGAL_STATUSES_QUERY)

  const SelectOptions = data?.allLegalStatuses?.map((item) => ({
    label: item.name,
    value: item.name
  }))

  const onSelectLegalStatus = (value: string | number) =>
    form.setFieldValue(compact([listName, baseField, 'legalStatusName']), value)

  return (
    <>
      <h3 className='title-2 mb-2'>{t('legalRepresentative:morale.manual.identity')}</h3>
      <div className='flex'>
        <Form.Item
          label={t('legalRepresentative:morale.manual.societyName')}
          name={compact([baseField, 'societyName'])}
          className='grow mr-1'
          required
          rules={[{ required: true, message: t('common:validation.societyName') }]}
        >
          <Input autoComplete='off' data-testid='input-society-name' />
        </Form.Item>
        {SelectOptions && (
          <Form.Item
            label={t('legalRepresentative:morale.manual.legalStatus')}
            name={compact([baseField, 'legalStatusName'])}
            className='grow ml-1'
            required
            rules={[{ required: true, message: t('common:validation.legalStatus') }]}
          >
            <Select
              options={SelectOptions}
              onChange={onSelectLegalStatus}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              data-testid='select-society-legal-status'
            />
          </Form.Item>
        )}
      </div>
      <AddressFormItems
        form={form}
        addressLabel={t('common:label.societyAddress')}
        addressValidation={t('common:validation.commonAddress')}
        initialAddress={form.getFieldValue(compact([listName, baseField, 'address']))}
        baseField={baseField}
        listName={listName}
      />
      <h3 className='title-2 mb-2'>{t('legalRepresentative:morale.manual.leaderInformations')}</h3>
      <div className='flex'>
        <Form.Item
          label={t('common:label.lastname')}
          name={compact([baseField, 'leaderLastname'])}
          className='grow mr-1'
          required
          rules={[{ required: true, message: t('common:validation.lastname') }]}
        >
          <Input data-testid='input-society-lastname' />
        </Form.Item>
        <Form.Item
          label={t('common:label.firstname')}
          name={compact([baseField, 'leaderFirstname'])}
          className='grow ml-1'
          required
          rules={[{ required: true, message: t('common:validation.firstname') }]}
        >
          <Input data-testid='input-society-firstname' />
        </Form.Item>
      </div>
    </>
  )
}

export default SocietySearchManual
