import { Spinner } from '@/components/spinner'
import { COMPANY_ACTIVITIES_QUERY, CompanyActivityDataType } from '@/graphql/queries/companyActivities'
import { CompanyActivityType } from '@/interfaces/CompanyActivity'
import ISociety from '@/interfaces/Society'
import { useQuery } from '@apollo/client'
import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ActivityAreaProps {
  society: ISociety
}

const ActivityArea: React.FC<ActivityAreaProps> = ({ society }) => {
  const { t } = useTranslation()
  const Option = Select.Option

  const [activitiesOptions, setActivitiesOptions] = useState<undefined | CompanyActivityType>(undefined)

  const { data, loading } = useQuery<CompanyActivityDataType>(COMPANY_ACTIVITIES_QUERY)
  const categories = data?.companyActivities.map((activity) => activity.category)

  const getActivities = (_key, value) => {
    const activities = data?.companyActivities.find((activity) => activity.category === value.value)
    setActivitiesOptions(activities)
    return activities
  }

  useEffect(() => {
    getActivities('0', { value: society.apeCategory })
  }, [data?.companyActivities])

  return (
    <Spinner loading={loading}>
      <Form.Item
        name='activityCategory'
        label={t('company:form.activityCategory')}
        rules={[
          {
            required: true,
            message: t('common:validation.activityCategory')
          }
        ]}
      >
        <Select
          data-testid='activity-category-select'
          className='w-full h-5'
          showSearch
          placeholder={t('common:enterText')}
          onSelect={getActivities}
        >
          {categories?.map((category, index) => (
            <Option data-testid={`activity-category-${index}`} key={index} value={category}>
              {category}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name='activity'
        label={t('company:form.activity')}
        rules={[
          {
            required: true,
            message: t('common:validation.activity')
          }
        ]}
        className='mb-4'
      >
        <Select
          data-testid='activity-select'
          className='w-full h-5'
          showSearch
          placeholder={t('common:enterText')}
          disabled={!activitiesOptions}
        >
          {activitiesOptions?.activities?.map((activity, index) => (
            <Option data-testid={`activity-${index}`} key={`${activity.ape}-${index}`} value={activity.ape}>
              {activity.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Spinner>
  )
}

export default ActivityArea
