import gql from 'graphql-tag'
import { INFORMATION_CHANGE_FRAGMENT } from './Fragments'

export const INFORMATION_CHANGES_QUERY = gql`
  query ContactInformationChanges($contactId: Int!) {
    informationChanges(contactId: $contactId) {
      ...InformationChangeFragment
    }
  }
  ${INFORMATION_CHANGE_FRAGMENT}
`

export const PHONE_UPDATE_MUTATION = gql`
  mutation PhoneUpdate($phoneNumber: String!) {
    phoneUpdate(phoneNumber: $phoneNumber) {
      informationChange {
        oldValue
        newValue
        sentTo
        informationableType
      }
    }
  }
`

export const EMAIL_UPDATE_MUTATION = gql`
  mutation EmailUpdate($email: String!) {
    emailUpdate(email: $email) {
      informationChange {
        oldValue
        newValue
        sentTo
        informationableType
      }
    }
  }
`

