import gql from 'graphql-tag'
import { LEGAL_FORMALITY_FRAGMENT } from './Fragments'

export const INITIAL_LEGAL_FORMALITY = gql`
  {
    initialLegalFormality{
      ...LegalFormalityFragment
    }
  }
  ${LEGAL_FORMALITY_FRAGMENT}
`
