import gql from 'graphql-tag'
import { ROLE_FRAGMENT } from './Fragments'

export const ROLES_QUERY = gql`
{
  roles {
    ...RoleFragment
  }
}

${ROLE_FRAGMENT}
`
