import { useEffect } from 'react'
import PoliticallyExposed from '@/components/UIKit/components/PoliticallyExposed'
import { Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface PhysicalFormProps {
  form: FormInstance
  id: number
}

const PhysicalForm: React.FC<PhysicalFormProps> = ({ form, id }) => {
  const { t } = useTranslation()

  useEffect(() => {
    form.setFieldValue(['shareholders', id, 'shareholderType'], 'physical')
  }, [])

  return (
    <>
      <Form.Item name={[id, 'shareholderType']} initialValue='physical' hidden={true} />
      <div className='flex gap-2 [&>div]:flex-1'>
        <Form.Item
          name={[id, 'lastname']}
          label={t('common:label.lastname')}
          rules={[
            {
              required: true,
              message: t('common:validation.lastname')
            }
          ]}
          className='m-0'
        >
          <Input name='lastname' placeholder={t('common:placeholder.lastname')} className='h-5' />
        </Form.Item>

        <Form.Item
          name={[id, 'firstname']}
          label={t('common:label.firstname')}
          rules={[
            {
              required: true,
              message: t('common:validation.firstname')
            }
          ]}
          className='m-0'
        >
          <Input name='firstname' placeholder={t('common:placeholder.firstname')} className='h-5' />
        </Form.Item>
      </div>
      <PoliticallyExposed
        form={form}
        title={t('capital:politicallyExposed')}
        baseField={id}
        listName='shareholders'
      />
    </>
  )
}

export default PhysicalForm
