import { useTranslation } from 'react-i18next'
import UserAddressInformations from './UserMails/shared/UserAddressInformations'
import styled from 'styled-components'

const NoUserMails = () => {
  const { t } = useTranslation()
  return (
    <NoUserMailsWrapper>
      <UserAddressInformations />
      <NoLastMailWrapper>
        <NoLastMailTitle>{t('userMails:global.lastMails')}</NoLastMailTitle>
        <NoLastMailContent>{t('userMails:global.noUserMails')}</NoLastMailContent>
      </NoLastMailWrapper>
    </NoUserMailsWrapper>
  )
}

export const NoUserMailsWrapper = styled.div`
  width: 60%;
  max-width: 735px;
  margin-top: -45px;
  padding: 45px 40px;
  padding-bottom: 0px;
  @media (max-width: 900px) {
    width: 100%;
    margin: 0 auto;
  }
`
export const NoLastMailWrapper = styled.div`
  margin-top: 40px;
`
export const NoLastMailTitle = styled.h3`
  color: #171a1c;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`
export const NoLastMailContent = styled.p`
  color: #b9c4cd;
  font-size: 14px;
`

export default NoUserMails
