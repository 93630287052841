import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'
import { Colors } from 'src/components/UIKit'
import lamp from 'src/images/light/light-lamp.svg'
import doc from 'src/images/documents/document-paper.svg'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import styled from 'styled-components'
import { Dispatch, SetStateAction, useState } from 'react'
import { RcFile } from 'antd/es/upload'
import ArrowLeftBlack from 'src/images/arrow/arrow-left-black-reactnode'
import DocumentTypeNotice from './documentTypeNotice'
import BasicUpload from '../ProcedureDrawer/basicUpload'

interface AddressProofDrawerProps {
  onClose: () => void
  open: boolean
  selectedFile: RcFile | null
  setSelectedFile: Dispatch<SetStateAction<RcFile | null>>
  drawerCategory: string | null
  drawerAllowDocumentTypes: string[]
  drawerTitle: string
  onSendDocument: () => void
  children?: React.ReactNode
  setFileType: (string) => void
}

const AddressProofDrawer = ({
  selectedFile,
  setSelectedFile,
  onClose,
  open,
  setFileType,
  drawerTitle,
  onSendDocument,
  children,
}: AddressProofDrawerProps) => {
  const { t } = useTranslation()

  const choices = [
    {
      name: 'bill',
      icon: lamp,
      title: t('procedure:hostInformation.drawer.addressProof.bill'),
      subtitle: t('procedure:hostInformation.drawer.addressProof.billAcceptance'),
      warning: t('procedure:less3months'),
      fileType: 'legalDocument_kyc_bill'
    },
    {
      name: 'certificate',
      icon: doc,
      title: t('procedure:hostInformation.drawer.addressProof.certificate'),
      subtitle: t('procedure:hostInformation.drawer.addressProof.certificateAcceptance'),
      warning: t('procedure:less3months'),
      fileType: 'legalDocument_kyc_houseAttestation'
    }
  ]

  const [selected, setSelected] = useState(choices[0])
  const [confirm, setConfirm] = useState(false)

  const onConfirm = () => {
    if (selected) {
      setFileType(selected.fileType)
      return setConfirm(true)
    }
    return
  }

  return (
    <Drawer
      title={drawerTitle}
      placement='right'
      onClose={confirm && !selectedFile ? () => setConfirm(false) : onClose}
      open={open}
      zIndex={1000}
      width={400}
      headerStyle={{ textAlign: 'center', marginRight: '28px' }}
      closeIcon={<ArrowLeftBlack />}
    >
      {!confirm ? (
        <>
          <DrawerTitle>{t('procedure:hostInformation.drawer.addressProof.selectDocument.title')}</DrawerTitle>

          <AddressProofDrawerChoice>
            {choices.map((item) => {
              return (
                <ChoiceWrapper key={item.name} selected={selected.name === item.name} onClick={() => setSelected(item)}>
                  <ChoiceIcon src={item.icon} alt={`${item.icon} icone`} />
                  <ChoiceTitle>{item.title}</ChoiceTitle>
                  {item.subtitle && <ChoiceSubtitle>{item.subtitle}</ChoiceSubtitle>}
                  <ChoiceWarning>{item.warning}</ChoiceWarning>
                </ChoiceWrapper>
              )
            })}
          </AddressProofDrawerChoice>

          <Button bgColor='blue' height='48px' justifyContent='center' onClick={onConfirm}>
            {t('common:document.send')}
          </Button>
        </>
      ) : (
        <>
          <DrawerTitle>{t(`procedure:hostInformation.drawer.addressProof.upload.${selected.name}`)}</DrawerTitle>
          {!selectedFile && <DocumentTypeNotice selected={selected.name} />}
          <UploadWrapper>
            <BasicUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              onSendDocument={onSendDocument}
              buttonText={t(`procedure:noticeInformationDrawer.address_proof.${selected.name}.add`)}
            >
              {children}
            </BasicUpload>
          </UploadWrapper>
        </>
      )}
    </Drawer>
  )
}

export const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${Colors.black};
  margin-bottom: 32px;
`
export const AddressProofDrawerChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`
export const ChoiceWrapper = styled.div`
  height: 140px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid ${(props) => (props.selected ? `${Colors.blue}` : '#e3e6e8')};
  border-radius: 14px;
  &:hover {
    border: 1px solid ${Colors.blue};
    cursor: pointer;
  }
`
export const ChoiceIcon = styled.img``
export const ChoiceTitle = styled.div`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: 600;
`
export const ChoiceSubtitle = styled.div`
  color: ${Colors.grey};
  font-size: 14px;
`
export const ChoiceWarning = styled.div`
  color: ${Colors.orange};
  font-size: 14px;
  font-weight: 600;
`
export const NoticeText = styled.span``
export const NoticeStrongText = styled.span`
  font-weight: bold;
`
export const UploadWrapper = styled.div`
  margin-top: 32px;
`

export default AddressProofDrawer
