import { Form, Input } from 'antd'
import CountrySelect from 'src/components/countries/countrySelect'
import BasicUpload from '../ProcedureDrawer/basicUpload'
import { useTranslation } from 'react-i18next'
import { DrawerTitle } from '../ProcedureDrawer/styles'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import { useState } from 'react'
import styled from 'styled-components'

const BOMoral = ({
  states,
  onSubmit,
  contactDetail,
  setConfirmDenomination
}) => {
  const { t } = useTranslation()
  const { selectedFile, setSelectedFile } = states
  const [form] = Form.useForm()

  const [disableButton, setDisableButton] = useState(false)

  const onFinish = (values) => {
    if (selectedFile) {
      setDisableButton(true)
      onSubmit({
        societyName: values.societyName,
        nationality: values.nationality,
        firstname: values.firstname,
        lastname: values.lastname
      })
    }
  }

  const initialValues = {
    firstname: contactDetail.firstname || '',
    lastname: contactDetail.lastname || '',
    societyName: '',
    nationality: contactDetail.nationality || 'FR'
  }

  const handleCancel = () => {
    setDisableButton(false)
    setSelectedFile(null)
    return setConfirmDenomination(false)
  }

  const style = {
    height: '48px',
    fontFamily: 'Inter'
  }

  return (
    <Form layout='vertical' requiredMark={false} form={form} onFinish={onFinish} initialValues={initialValues}>
      <DrawerTitle>{t('procedure:beneficialOwner.drawer.form.moral.title')}</DrawerTitle>
      <Form.Item
        name='societyName'
        label={
          <div style={{ fontFamily: 'Inter' }}>{t('procedure:beneficialOwner.drawer.form.moral.societyName')}</div>
        }
        rules={[
          {
            required: true,
            message: t('procedure:beneficialOwner.drawer.form.moral.missing.societyName')
          }
        ]}
      >
        <Input style={style} placeholder={t('procedure:beneficialOwner.drawer.form.moral.placeholder.societyName')} />
      </Form.Item>
      <Form.Item
        name='lastname'
        label={<div style={{ fontFamily: 'Inter' }}>{t('procedure:beneficialOwner.drawer.form.moral.lastname')}</div>}
        rules={[
          {
            required: true,
            message: t('procedure:rules.beneficialOwner.lastname')
          }
        ]}
      >
        <Input style={style} placeholder='Saisissez le nom' />
      </Form.Item>

      <Form.Item
        name='firstname'
        label={<div style={{ fontFamily: 'Inter' }}>{t('procedure:beneficialOwner.drawer.form.moral.firstname')}</div>}
        rules={[
          {
            required: true,
            message: t('procedure:rules.beneficialOwner.firstname')
          }
        ]}
      >
        <Input style={style} placeholder='Saisissez le prénom' />
      </Form.Item>
      <Form.Item
        name='nationality'
        label={t('procedure:beneficialOwner.drawer.form.moral.headOfficeCountry')}
        rules={[
          {
            required: true,
            message: t('procedure:rules.beneficialOwner.nationality')
          }
        ]}
      >
        <CountrySelect
          defaultValue={'FR'}
          onSelect={(value) => form.setFieldsValue({ nationality: value })}
        />
      </Form.Item>
      <BasicUpload
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onSendDocument={onSubmit}
        buttonText='Ajouter le kbis'
        withoutButton
      />
      {selectedFile && (
        <ButtonWrap>
          <Form.Item style={{ marginBottom: '8px' }}>
            <Button bgColor='blue' height='48px' justifyContent='center' disabled={disableButton}>
              {t('common:document.send')}
            </Button>
          </Form.Item>
          <Button bgColor='white' height='48px' justifyContent='center' onClick={handleCancel}>
            {t('common:cancel')}
          </Button>
        </ButtonWrap>
      )}
    </Form>
  )
}

export const ButtonWrap = styled.div`
  margin-top: 16px;
`

export default BOMoral
