import gql from 'graphql-tag'
import { PAYMENT_SOURCE_FRAGMENT, CONTACT_FRAGMENT, PUBLIC_FAILED_PAYMENT_FRAGMENT } from '@/graphql/Fragments'

export const CURRENT_PAYMENT_SOURCE = gql`
  {
    currentPaymentSource {
      ...PaymentSourceFragment
    }
  }
  ${PAYMENT_SOURCE_FRAGMENT}
`

export const PUBLIC_PAYMENT_SOURCE_CREATE_MUTATION = gql`
  mutation PublicPaymentSourceCreate($token: String!, $forceDefault: Boolean, $securityToken: String!) {
    publicPaymentSourceCreate(token: $token, forceDefault: $forceDefault, securityToken: $securityToken) {
      contact {
        id
        stripeConnectedAccount
        publicFailedPayments {
          ...PublicFailedPaymentFragment
        }
      }
    }
  }
  ${PUBLIC_FAILED_PAYMENT_FRAGMENT}
`

export const PAYMENT_SOURCE_CREATE_MUTATION = gql`
  mutation PaymentSourceCreate($token: String!, $forceDefault: Boolean) {
    paymentSourceCreate(token: $token, forceDefault: $forceDefault) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`

export const PAYMENT_SOURCE_DESTROY_MUTATION = gql`
  mutation PaymentSourceDestroy($id: ID!) {
    paymentSourceDestroy(id: $id) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`

export const PAYMENT_SOURCE_MARK_AS_DEFAULT_MUTATION = gql`
  mutation PaymentSourceMarkAsDefault($id: ID!) {
    paymentSourceMarkAsDefault(id: $id) {
      contact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`
