import { Tags } from '@sedomicilier/material-sd'
import { Form, Switch } from 'antd'
import { t } from 'i18next'

interface ExtraServiceCardProps {
  id: string
  icon: string
  tagLabel?: string
}

const ExtraServiceCard: React.FC<ExtraServiceCardProps> = ({ id, icon, tagLabel }) => {
  return (
    <div className='p-3 border rounded-medium border-neutral-200 body-1'>
      <div className='flex justify-between [&>img]:m-0'>
        <img src={icon} alt={`icone ${id}`} />
        <div className='flex flex-col items-end gap-1 [&_.ant-switch]:bg-neutral-200 [&_.ant-switch-checked]:!bg-interaction'>
          {tagLabel && <Tags bgColor='green' label={tagLabel} />}
          <Form.Item name={id}>
            <Switch />
          </Form.Item>
        </div>
      </div>
      <h3 className='title-2 text-3 font-bold mb-1'>{t(`partner:${id}.title`)}</h3>
      <ul className='[&>li]:text-neutral-600 body-2 list-inside list-disc pl-1'>
        <li>{t(`partner:${id}.args.1`)}</li>
        <li>{t(`partner:${id}.args.2`)}</li>
        <li>{t(`partner:${id}.args.3`)}</li>
      </ul>
    </div>
  )
}

export default ExtraServiceCard
