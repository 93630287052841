import React from 'react'
import Step from './Step'
import { IItem } from './interfaces'
import { Wrapper, StepsWrapper, Note } from './styles'

interface IProps {
  items: IItem[]
  current: number
}

const ProgressBar = (props: IProps) => {
  const { items, current } = props

  const currentItem = items[current]
  return (
    <Wrapper className="uikit-progress-bar">
      <StepsWrapper className="uikit-progress-bar-steps">
        {
          items.map((item: IItem, index: number) => <Step item={item} done={index <= current} key={index} />)
        }
      </StepsWrapper>
      <Note>{currentItem.note}</Note>
    </Wrapper>
  )
}

export default ProgressBar
