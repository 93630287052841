import { useContext } from 'react'
import { CommonLoader } from 'src/components/shared/Loader'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const DomiciliationCheck = ({ children }) => {
  const { loading: loadingDomiciliation, error: errorDomiciliation } = useContext(DomiciliationContext)

  if (loadingDomiciliation) {
    return <CommonLoader />
  } else if (errorDomiciliation) {
    errorDomiciliation.graphQLErrors.forEach((el: any) => {
      if (el.statusCode === 401 && el.message === 'Unauthorized') {
        window.location.href = el.extraInfo.redirectUrl
      }
    })
    return <CommonLoader />
  } else {
    return children
  }
}

export default DomiciliationCheck
