import styled from 'styled-components'
import { Colors } from 'src/components/UIKit'
import constants from 'src/components/UIKit/constants'

export const ProcedureStepWrapper = styled.div`
  border: 1px solid #e3e6e8;
  border-radius: 16px;
`
export const ProcedureTopPart = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const ProcedureLeftPart = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;;
  }
`
export const ProcedureStepNumber = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${Colors.blue};
  border-radius: 50%;
  font-size: 13px;
  font-family: 'Inter';
  font-weight: 700;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};
`
export const ProcedureTitle = styled.div`
  color: ${Colors.black};
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter';
  line-height: 150%;
`
export const ProcedureContent = styled.div`
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ProcedureRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const ProcedureState = styled.div``
