import React from 'react'
import IUserMail from 'src/interfaces/UserMail'
import styled from 'styled-components'
import blueDownloadIcon from 'src/images/download/blue-download-icon.svg'

interface IMailCardDownload {
  mail: IUserMail
}

const MailCardDownload: React.FC<IMailCardDownload> = ({ mail }) => {
  const handleDownload = (event) => {
    window.open(mail.scanFileUrl, '_blank')?.focus()
    event.stopPropagation()
  }
  return (
    <>
      {mail.markAsScanned && mail.scanFileUrl && (
        <DownloadIcon src={blueDownloadIcon} alt='icone de téléchargement' onClick={handleDownload} />
      )}
    </>
  )
}

export const DownloadIcon = styled.img`
  background-color: #fff;
  border-radius: 8px;
  padding: 4px;
  &:hover {
    background-color: rgb(231, 242, 255);
  }
`

export default MailCardDownload
