import styled from 'styled-components'
import Letter from '@/images/courrier-grey-border.svg'
import { useTranslation } from 'react-i18next'

const NoUserMailSelected = () => {
  const { t } = useTranslation()
  return (
    <NoUserMailSelectedWrapper>
      <img src={Letter} alt="lettre fermée" />
      <NoSelectedMail>{t('userMails:global.noUserMailSelected')}</NoSelectedMail>
    </NoUserMailSelectedWrapper>
  )
}

export const NoUserMailSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`

export const NoSelectedMail = styled.div`
  color: #b9c4cd;
  font-size: 14px;
  margin-top: 8px;
`

export default NoUserMailSelected
