import { useContext, useState } from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import BelgiumPPDrawer from './belgiumPPDrawer'
import BelgiumPPActivation from './belgiumPPActivation'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CONFORMITY_UPDATE_MUTATION,
  POSTAL_PROCURATION_CONFIRMATION_MUTATION
} from 'src/graphql/PostalProcurationConfirmation'
import CenterValidationStatus from './CenterValidationStatus'
import iconError from 'src/images/error/dark-orange-exclamation.svg'
import iconCheck from 'src/images/check/green-simple-check.svg'
import styled from 'styled-components'
import LegalDocumentDownload from '../legalDocumentDownload'

const BelgiumPostalProcuration = () => {
  const { t } = useTranslation()
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { postalProcurationFlow } = domiciliation
  const [open, setOpen] = useState(false)

  const [confirmPostalProcuration] = useMutation(POSTAL_PROCURATION_CONFIRMATION_MUTATION)
  const [updateConformity] = useMutation(CONFORMITY_UPDATE_MUTATION)

  return (
    <div>
      <BelgiumPPDrawer open={open} onClose={() => setOpen(false)} updateConformity={updateConformity} />

      {postalProcurationFlow.status === 'waiting_informations' && (
        <WaitingInformations>
          <LegalDocumentDownload
            title={t('profile:documents.postalProcuration.title')}
            subtitle={t('profile:documents.postalProcuration.subtitle')}
            withoutButton
          />
          <button className='section-button primary-button' onClick={() => setOpen(true)}>
            Compléter le document
          </button>
        </WaitingInformations>
      )}

      {postalProcurationFlow.status === 'waiting_download' && (
        <WaitingDownload>
          <LegalDocumentDownload
            title={t('profile:documents.postalProcuration.title')}
            subtitle={t('profile:documents.postalProcuration.subtitle')}
            withoutButton
          />
          <BelgiumPPActivation
            postalProcuration={postalProcurationFlow.link}
            confirmPostalProcuration={confirmPostalProcuration}
          />
        </WaitingDownload>
      )}
      {postalProcurationFlow.status === 'waiting_official_pass' && (
        <NoticeSection>
          <LegalDocumentDownload
            title={t('profile:documents.postalProcuration.title')}
            subtitle={t('profile:documents.postalProcuration.subtitle')}
            cta={t('common:download')}
            href={postalProcurationFlow.link}
          />
          <CenterValidationStatus
            text={t('profile:belgium_postal_procuration.waitingValidation')}
            icon={iconError}
            alt='icone erreur'
            type='waiting'
          />
        </NoticeSection>
      )}
      {postalProcurationFlow.status === 'validated' && (
        <NoticeSection>
          <LegalDocumentDownload
            title={t('profile:documents.postalProcuration.title')}
            subtitle={t('profile:documents.postalProcuration.subtitle')}
            cta={t('common:download')}
            href={postalProcurationFlow.link}
          />
          <CenterValidationStatus
            text={t('profile:belgium_postal_procuration.validated')}
            icon={iconCheck}
            alt='icone success'
            type='success'
          />
        </NoticeSection>
      )}
    </div>
  )
}

export const WaitingInformations = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WaitingDownload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const NoticeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ButtonIcon = styled.img`
  margin-left: 8px;
`

export default BelgiumPostalProcuration
