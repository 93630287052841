export enum CommunicationRestriction {
  paymentFailure = 'payment_failure',
  verificationFailure = 'verification_failure',
  verificationWaiting = 'verification_waiting'
}

export enum CommunicationStrengthRestriction {
  notification = 'notification',
  soft = 'soft',
  hard = 'hard',
  extrem = 'extrem'
}

export enum CommunicationSection {
  dashboard = 'dashboard',
  payment = 'payment',
  profile = 'profile',
  userMails = 'user_mails'
}
