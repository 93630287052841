import { useContext, useState } from 'react'
import { updateInformations } from '@/reducers/societyInformations'
import { Form } from 'antd'
import { nextPage } from '@/reducers/workflow'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@sedomicilier/material-sd'
import { useMutation } from '@apollo/client'
import { UPSERT_LEGAL_REPRESENTATIVE_MORAL } from '@/graphql/mutations/upsertLegalRepresentativeMoral'
import ISearchSociety from 'src/interfaces/SearchSociety'
import SearchSelect from '@/components/UIKit/components/SearchSociety/Select'
import SocietySearchManual from '@/components/UIKit/components/SearchSociety/Manual'
import { ContactContext } from '@/contexts/contactContext'
import { ContactContextProps } from '@/interfaces/Contact'

const SocietySearch: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { contact } = useContext<ContactContextProps>(ContactContext)

  const initialManualSearch =
    contact.personalInformation.legalType === 'moral' && !!contact.personalInformation.leaderAddressDetail.fullAddress

  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [manualSearch, setManualSearch] = useState<boolean>(initialManualSearch)

  const [upsertLegalRepresentative] = useMutation(UPSERT_LEGAL_REPRESENTATIVE_MORAL)

  const onChange = (society: ISearchSociety) => {
    dispatch(updateInformations({ ...society }))
    form.setFields([
      {
        name: 'societyName',
        value: society.name
      },
      {
        name: 'legalStatusName',
        value: society.legalStatusName
      },
      {
        name: 'address',
        value: {
          streetAddress: society.streetAddress,
          postalCode: society.postalCode,
          country: society.country,
          countryCode: society.countryCode,
          city: society.city
        }
      },
      {
        name: 'leaderLastname',
        value: society.leaderLastname
      },
      {
        name: 'leaderFirstname',
        value: society.leaderFirstname
      }
    ])
    setManualSearch(true)
  }

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      upsertLegalRepresentative({
        variables: {
          name: values.societyName,
          lastname: values.leaderLastname,
          firstname: values.leaderFirstname,
          legalStatusName: values.legalStatusName,
          complement: values.complement,
          streetAddress: values.address.streetAddress,
          postalCode: values.address.postalCode,
          city: values.address.city,
          country: values.address.countryCode || values.address.country
        }
      }).then(() => dispatch(nextPage()))
    }
  }

  const initialValues = {
    societyName: contact.society.name,
    legalStatusName: contact.society.legalStatus.name,
    address: contact.personalInformation.leaderAddressDetail.fullAddress,
    leaderLastname: contact.personalInformation.leaderContactDetail.lastname,
    leaderFirstname: contact.personalInformation.leaderContactDetail.firstname
  }

  return (
    <div className='mb-4'>
      <SearchSelect
        loading={loadingSearch}
        setLoading={setLoadingSearch}
        onChange={onChange}
        setManualSearch={setManualSearch}
        placeholder={t('legalRepresentative:morale.search.placeholder')}
      />
      <Form layout='vertical' requiredMark={false} form={form} onFinish={onFinish} initialValues={initialValues}>
        {manualSearch && <SocietySearchManual form={form} />}
        <BasicButton
          testid='legal-representative-next-button'
          label={t('common:validateInfo')}
          loading={loading}
          bgColor='blue'
          className='w-full body-1 justify-center font-semibold'
        />
      </Form>
    </div>
  )
}

export default SocietySearch
