import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { PlusOutlined } from '@ant-design/icons'
import { DOMICILIATION_USERS_QUERY } from 'src/graphql/User'
import { CommonLoader } from 'src/components/shared/Loader'
import InvitationModal from './InvitationModal'
import User from './User'
import { Button } from './style'

const UserList = () => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(DOMICILIATION_USERS_QUERY)
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <InvitationModal openModal={openModal} setOpenModal={setOpenModal} refetch={refetch} />
      <div className="team-section-title">
        <h2>{t('team:access_management')}</h2>
        <Button href={void(0)} onClick={() => setOpenModal(true)}>
          {t('common:invite')} <PlusOutlined />
        </Button>
      </div>
      <div className="team-row first">
        <div className="team-column team-name">{t('common:name')}</div>
        <div className="team-column team-role">{t('team:role')}</div>
        <div className="team-column team-permission">{t('team:permission')}</div>
        <div className="team-column team-action" />
      </div>
      {
        loading ? (
          <div className="team-row first">
            <CommonLoader />
          </div>
        ) : (
          data.domiciliationUsers.map((user: any) => {
            return (
              <User user={user} refetchList={refetch} key={user.id} />
            )
          })
        )
      }
    </>
  )
}

export default UserList
