import { NavLink } from 'react-router-dom'
import Notification from '../Notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
  to: string
  authorized: boolean
  children?: React.ReactNode
  onClick?: any
  icon: any
  setCollapsed: any
  label: string
  notification?: number
  className?: string
  target?: string
}

const MobileNavItem = (props: IProps) => {
  const closeNav = () => {
    document.body.className = document.body.className.replace(' menumodal', '')
    props.setCollapsed(true)
  }

  if (props.authorized) {
    return (
      <NavLink to={props.to} onClick={closeNav}
        className={'flex p-3 hover:no-underline items-center ' + props.className} target={props.target}>
        <Content {...props} />
      </NavLink>
    )
  }

  return <span className={'flex p-3 ' + props.className
  }>
    <Content {...props}/>
  </span>
}

const Content = (props: IProps) => {
  return <>
    <div className='mr-3'>
      {
        typeof (props.icon) === 'string' && <img src={props.icon} className='shape' />
      }
      {
        typeof (props.icon) !== 'string' &&
        <FontAwesomeIcon icon={props.icon} />
      }
    </div>
    {props.label}
    {
      !!props.notification &&
      <span className='ml-4'>
        <Notification number={props.notification} />
      </span>
    }
  </>
}

export default MobileNavItem
