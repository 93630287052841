import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { NOTIFICATIONS_QUERY } from 'src/graphql/Notification'

export const NotificationContext = createContext({})
export const NotificationContextProvider = NotificationContext.Provider

interface IProps {
  children: React.ReactNode
}

const NotificationProvider = (props: IProps) => {
  const { data, loading, error, refetch } = useQuery(NOTIFICATIONS_QUERY)

  return (
    <NotificationContextProvider value={{ kyc: data?.notifications, loading, error, refetch }}>
      {props.children}
    </NotificationContextProvider>
  )
}

export default NotificationProvider
