import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import PaymentProcessing from './PaymentProcessing'
import Summary from './Summary'
import Billing from './Billing'
import Routing from '@/CustomerArea/Routing'
import { CART_PAYMENT_VALIDATING } from '@/graphql/Cart'
import Success from './Success'
import Failure from './Failure'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'

const Payment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { domiciliation }: any = useContext(DomiciliationContext)

  const [paymentMethod, setPaymentMethod] = useState<any>()
  const [paymentIntentSecret, setPaymentIntentSecret] = useState<string | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [cartPaymentValidating] = useMutation(CART_PAYMENT_VALIDATING)

  const redirectSuccessRoute = domiciliation ? Routing.USER_MAILS_INDEX : Routing.SERVICES_INDEX
  const redirectSuccessText = t('payments:consult_my_purchases')
  const redirectFailureRoute = Routing.CART
  const redirectFailureText = t('payments:back_to_cart')

  const pay = () => {
    setIsSubmitting(true)

    cartPaymentValidating().then(({ data }) => {
      if (data.cartPaymentValidating.cart.status === 'paid') {
        setIsSubmitting(false)
        navigate(`${Routing.CART_PROCESSING}/success`)
      } else {
        setPaymentIntentSecret(data.cartPaymentValidating.cart.paymentIntent.paymentIntentSecret)
      }
    })
  }

  useEffect(() => {
    if (paymentIntentSecret) {
      setIsSubmitting(false)
      navigate(Routing.CART_PROCESSING)
    }
  }, [paymentIntentSecret])

  return (
    <Routes>
      <Route path="/" element={<Summary />} />
      <Route
        path="payment"
        element={<Billing setPaymentMethod={setPaymentMethod} isSubmitting={isSubmitting} pay={pay} />}
      />
      <Route
        path="processing"
        element={
          <PaymentProcessing
            paymentMode="card"
            paymentMethod={paymentMethod}
            paymentIntentSecret={paymentIntentSecret}
          />
        }
      />
      <Route
        path="processing/success"
        element={<Success redirectRoute={redirectSuccessRoute} redirectText={redirectSuccessText} />}
      />
      <Route
        path="processing/failure"
        element={<Failure redirectRoute={redirectFailureRoute} redirectText={redirectFailureText} />}
      />
    </Routes>
  )
}

export default Payment
