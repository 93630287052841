import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import styled from 'styled-components'
import rightArrow from 'src/images/dashboard/arrow-right-white.svg'
import blueCheck from 'src/images/check/check-bg-blue.svg'
import constants from 'src/constants'
import { useTranslation } from 'react-i18next'

export interface SubscriptionStepProps {
  title: string
  description: string
  buttonText: string
  buttonRedirection: () => void
  step: 1 | 2 | 3
  status: 'locked' | 'todo' | 'validated' | 'completed'
  border?: boolean
}

const SubscriptionStep = (
    {
      title,
      description,
      buttonText,
      buttonRedirection,
      step,
      border,
      status
    }: SubscriptionStepProps
) => {
  const { t } = useTranslation()

  const getActiveStep = () => {
    if (status === 'todo') {
      return require(`src/images/steps/${step}-bg-orange.svg`)
    } else if (status === 'validated') {
      return blueCheck
    } else {
      return require(`src/images/steps/${step}-bg-light-grey.svg`)
    }
  }

  const stepIcon = getActiveStep()
  return (
    <SubscriptionWrapper border={border}>
      <SubscriptionStepNumber src={stepIcon} alt='salyt' />
      <SubscriptionContent>
        <SubscriptionText>
          <SubscriptionTitle>{title}</SubscriptionTitle>
          <SubscriptionDescription>{description}</SubscriptionDescription>
        </SubscriptionText>

        {status === 'todo' && (
          <SubscriptionButton>
            <Button bgColor='orange' height='48px' onClick={buttonRedirection}>
              {buttonText}
              <ArrowIcon src={rightArrow} alt='arrow to right' />
            </Button>
          </SubscriptionButton>
        )}

        {status === 'completed' && (
          <WaitingButton>
            <Button bgColor='orange' height='48px' disabled={true}>
              {t('common:processing')}
            </Button>
          </WaitingButton>
        )}
      </SubscriptionContent>
    </SubscriptionWrapper>
  )
}

export const SubscriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  ${(props) =>
    props.border &&
    `
    border-top: 1px solid #D3D7DA;
    border-bottom: 1px solid #D3D7DA;
    padding: 32px 0;
  `}
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    flex-direction: column;
  }
`
export const SubscriptionStepNumber = styled.img`
  margin: 0;
`
export const SubscriptionText = styled.div`
  max-width: 740px;
`
export const SubscriptionContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
export const SubscriptionButton = styled.div`
  min-width: fit-content;
  button {
    white-space: nowrap;
  }
  @media (max-width: 1024px) {
    margin-top: 24px;
    max-width: fit-content;
  }
`
export const WaitingButton = styled.div`
  min-width: fit-content;
  button {
    white-space: nowrap;
    background-color: #ffe1cc;
    color: #f26600;
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
      background-color: #ffe1cc;
      color: #f26600;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 24px;
    max-width: fit-content;
  }
`
export const SubscriptionTitle = styled.h4`
  font-size: 16px;
`
export const SubscriptionDescription = styled.div`
  font-size: 16px;
  color: #6c767f;
`
export const ArrowIcon = styled.img`
  margin-left: 8px;
`

export default SubscriptionStep
