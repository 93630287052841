import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import GreenConfirmImage from '@/images/green-confirm.svg'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { Centred, Title, SubText, PrettyLink, CircleInfoWrapper } from './styles'
import { ContactContext } from 'src/contexts/contactContext'

interface IProps {
  redirectRoute?: string;
  redirectText?: string;
}

const Success = (props: IProps) => {
  const { refetch: refetchDomiciliation }: any = useContext(DomiciliationContext)
  const { refetch: refetchContact }: any = useContext(ContactContext)
  const { redirectRoute, redirectText } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectInFiveSeconds = () => {
    if (redirectRoute) {
      setTimeout(() => {
        navigate(redirectRoute)
      }, 5000)
    }
  }

  useEffect(() => {
    refetchContact()
    refetchDomiciliation()
    redirectInFiveSeconds()
  }, [])

  return (
    <Centred>
      <CircleInfoWrapper>
        <img src={GreenConfirmImage} />
      </CircleInfoWrapper>
      <Title>{t('payments:success_title')}</Title>
      <SubText>{t('payments:auto_redirecting_in_a_few_seconds')}</SubText>

      {redirectRoute !== undefined && (
        <PrettyLink onClick={() => navigate(redirectRoute)}>{redirectText}</PrettyLink>
      )}
    </Centred>
  )
}

export default Success
