import React from 'react'
import { Collapse } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import HelpersLight from '@/images/helpers-light.svg'
import UserList from './UserList'
import ContactList from './ContactList'

const Team = () => {
  const { Panel } = Collapse
  const { t } = useTranslation()

  return (
    <div className="page-team">
      <div className="page-title">
        <h1 className="title">{t('nav:team')}</h1>
      </div>
      <div className="team-section-access-management">
        <UserList />
        <BeautifullCollapse expandIconPosition="right">
          <Panel
            header={
              <>
                <InlineImg src={HelpersLight} />
                <span>
                  {t('team:helpers.users.title')}
                </span>
              </>
            }
            key="1"
          >
            <p>{t('team:helpers.users.text')}</p>
          </Panel>
        </BeautifullCollapse>
      </div>
      <div className="team-section-contact">
        <ContactList />
        <BeautifullCollapse expandIconPosition="right">
          <Panel
            header={
              <>
                <InlineImg src={HelpersLight} />
                <span>
                  {t('team:helpers.contacts.title')}
                </span>
              </>
            }
            key="1"
          >
            <p>{t('team:helpers.contacts.text')}</p>
          </Panel>
        </BeautifullCollapse>
      </div>
    </div>
  )
}

const BeautifullCollapse = styled(Collapse)`
  margin-top: 20px;
  border: none;
  background-color: #F9F9F9;

  svg {
    vertical-align: baseline;
    color: #1576E1;
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content {
    border: none;
    background-color: #F9F9F9;
    color: #7B7E80;
  }

  .ant-collapse-content-box {
    margin-left: 42px;
  }
`

const InlineImg = styled.img`
  display: inline-block;
  margin: 0px 20px 0px 0px;
`

export default Team
