import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import rightArrow from 'src/images/dashboard/arrow-right-white.svg'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import constants from 'src/constants'

interface ServiceProps {
  service: {
    id: string
    title: string
    description: string
    icon: string
    alt: string
    onClick?: any
    href?: string
  }
}

const Service: React.FC<ServiceProps> = ({ service }) => {
  const { t } = useTranslation('dashboard')
  return (
    <ServiceWrapper>
      <ServiceIcon src={service.icon} alt={service.alt} />
      <ServiceTitle>{service.title}</ServiceTitle>
      <ServiceDescription>{service.description}</ServiceDescription>
      <ServiceButton>
        {service.href ? (
          <Button type='a' buttonStyle bgColor='blue' href={service.href} target='_blank'>
            {t('dailyServices.cta')}
            <ButtonArrow src={rightArrow} alt='right arrow' />
          </Button>
        ) : (
          <Button bgColor='blue' onClick={service.onClick}>
            {t('dailyServices.cta')}
            <ButtonArrow src={rightArrow} alt='right arrow' />
          </Button>
        )}
      </ServiceButton>
    </ServiceWrapper>
  )
}

export const ServiceWrapper = styled.div`
  width: calc(100% / 3);
  background-color: #f5f7fb;
  border-radius: 16px;
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    width: 100%;
  }
`
export const ServiceIcon = styled.img`
  margin: 0;
  margin-bottom: 16px;
  width: 40px;
`
export const ServiceTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`
export const ServiceDescription = styled.div`
  color: #6c767f;
  font-size: 14px;
  margin-bottom: 16px;
`
export const ButtonArrow = styled.img`
  margin: 0;
`
export const ServiceButton = styled.div`
  @media (max-width: ${constants.breakpoints['mobile-breakpoint-i']}) {
    max-width: 270px;
  }
`

export default Service
