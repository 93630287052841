import gql from 'graphql-tag'

import { CGV_ACCEPTANCE_FRAGMENT, USER_CGV_ACCEPTANCE_FRAGMENT } from '@/graphql/Fragments'

import ICgvAcceptance from '@/interfaces/CgvAcceptance'
import IUserCgvAcceptance from '@/interfaces/UserCgvAcceptance'

export interface CurrentCgvAcceptanceData {
  currentCgvAcceptance: ICgvAcceptance
}

export interface UserCgvAcceptanceData {
  UserCgvAcceptance: IUserCgvAcceptance
}

export const CURRENT_CGV_QUERY = gql`
    query currentCvgAcceptance($cgvableId: Int, $cgvableType: String) {
        currentCgvAcceptance(cgvableId: $cgvableId, cgvableType: $cgvableType) {
            ...CgvAcceptanceFragment
        }
    }
    ${CGV_ACCEPTANCE_FRAGMENT}
`

export const USER_CGV_ACCEPTANCE_RECORD_MUTATION = gql`
    mutation UserCgvAcceptanceRecord($cgvAcceptanceUuid: String!) {
        userCgvAcceptanceRecord(cgvAcceptanceUuid: $cgvAcceptanceUuid) {
            userCgvAcceptance {
                ...UserCgvAcceptanceFragment
            }
        }
    }
    ${USER_CGV_ACCEPTANCE_FRAGMENT}
`
